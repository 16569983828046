import _ from 'lodash'
import React from 'react'
import { Ts, ButtonRow, Icon, Spacer } from '../ui'
import { Formik, Form } from 'formik'
import { InputField, Persist } from '../form'
import { Button } from 'react-bootstrap'
import { Group } from '../../store'
import PermissionEditor from './PermissionEditor'


export function GroupForm({ group, formRef, onSubmit }) {
    const newGroup = !group || !group.id
    return <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={group}
        onSubmit={onSubmit}
        validationSchema={Group.schema}
    >
        <Form style={{ maxWidth: '600px' }}>
            {/* {newGroup && <Persist name="new-group" />} */}
            <InputField name="name" label="Name" />
            <PermissionEditor name="access" />
            <Spacer />
            <ButtonRow>
                <Button variant="primary" type="submit">
                    <Icon name="check" />
                    <Ts>Save</Ts>
                </Button>
                <Button variant="secondary" type="reset">
                    <Icon name="times" />
                    <Ts>Reset</Ts>
                </Button>
            </ButtonRow>
        </Form>
    </Formik>
}

export default GroupForm