import _ from 'lodash'

export const Brands = [
    { id: 'ivt', name: 'IVT', },
    { id: 'nibe', name: 'NIBE', },
    { id: 'bosch', name: 'Bosch', },
    { id: 'ctc', name: 'CTC', },
    { id: 'panasonic', name: 'Panasonic', },
    { id: 'metrotherm', name: 'Metro Therm', },
    { id: 'mitsubishi', name: 'Mitsubishi Electric', },
    { id: 'daikin', name: 'Daikin', },
    { id: 'thermia', name: 'Thermia', },
    { id: 'danfoss', name: 'Danfoss', },
    { id: 'volund', name: 'Vølund', },
    { id: 'autoterm', name: 'Autoterm', },
    { id: 'elektrostandard', name: 'Elektrostandard', },
    { id: 'sverigepumpen', name: 'Sverigepumpen', },
    { id: 'qvantum', name: 'Qvantum', },
    { id: 'cetetherm', name: 'Cetetherm', },
    { id: 'toshiba', name: 'Toshiba', },
    { id: 'zibro', name: 'Zibro', },
    { id: 'qlima', name: 'Qlima', },
    { id: 'fujitsu', name: 'Fujitsu', },
    { id: 'bentone', name: 'Bentone', },
    { id: 'samsung', name: 'Samsung', },
    { id: 'lg', name: 'LG', },
    { id: 'viessman', name: 'Viessmann', },
    { id: 'comfortzone', name: 'ComfortZone', },
    { id: 'etk', name: 'ETK', },
    { id: 'mecaterm', name: 'Mecaterm', },
    { id: 'euronom', name: 'Euronom', },
    { id: 'mitsubishi_heavy', name: 'Mitsubishi Heavy Industries', },
    { id: 'alpha_innotec', name: 'Alpha Innotec', },
    { id: 'invest_living', name: 'Invest Living', },
    { id: 'vaillant', name: 'Vaillant', },
    { id: 'altherma', name: 'Altherma', },
    { id: 'carrier', name: 'Carrier', },
    { id: 'altech', name: 'Altech', },
    { id: 'hitachi', name: 'Hitachi', },
    { id: 'gree', name: 'Gree', },
    { id: 'innova', name: 'Innova', },
    { id: 'pioneer', name: 'Pioneer', },
    { id: 'sanyo', name: 'Sanyo', },
    { id: 'sharp', name: 'Sharp', },
    { id: 'kasai', name: 'Kasai', },
]

export function GetBrandById(id) {
    return _.find(Brands, brand => brand.id === id)
}

export default Brands