import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { Form, Button } from 'react-bootstrap'
import { RowColumn, Row, Column, Icon, Spinner, Ts } from '../../ui'
import CardNumber from './CardNumber'
import CardExpiry from './CardExpiry'
import CardCVC from './CardCVC'

function Field({ label, children }) {
    return <div>
        <p>{label}</p>
        {children}
    </div>
}

class CreditCardForm extends React.Component {
    constructor() {
        super()
        this.onSubmit = this.onSubmit.bind(this)
        this.state = {
            error: false,
            loading: false,
        }
    }

    // perhaps this should be moved to the InvoicePayment container...
    async onSubmit(e) {
        const { stripe, elements, request, submit } = this.props
        e.preventDefault()

        this.setState({ loading: true })

        const cardElement = elements.getElement('cardNumber')

        const { paymentIntent, error } = await stripe.confirmCardPayment(
            request.token,
            { payment_method: { card: cardElement } }
        )

        if (error) {
            // Display error.message in your UI.
            this.setState({ error: error.message, loading: false })
        } else {
            // The payment has succeeded
            // Display a success message
            this.setState({ error: false })
            submit()
        }
    }

    render() {
        const { error, loading } = this.state
        return <React.Fragment>
            {loading && <Spinner />}
            <form onSubmit={this.onSubmit} style={{ display: loading ? 'none' : 'block'}}>
            <RowColumn>
                <Field label="Credit card number">
                    <CardNumber />
                </Field>
            </RowColumn>
            <Row collapse="medium">
                <Column size={6}>
                    <Field label="Expires">
                        <CardExpiry />
                    </Field>
                </Column>
                <Column size={6}>
                    <Field label="Security code (CVC)">
                        <CardCVC />
                    </Field>
                </Column>
            </Row>

            {error && <RowColumn>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </RowColumn>}
            
            <RowColumn>
                <Button variant="primary" icon="credit-card" type="submit">
                    <Icon name="credit-card" />
                    <Ts>Pay now</Ts>
                </Button>
            </RowColumn>
            </form>
        </React.Fragment>
    }
}

export default injectStripe(CreditCardForm)
