import styled from 'styled-components/macro'

const MenuItem = styled.li`
	display: inline-block;
    user-select: none;

    @media screen and (max-width: 1000px) {
        display: ${props => props.expanded ? 'block' : 'none'};
    }
`

export default MenuItem
