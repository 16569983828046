import React from 'react'
import { useParams } from 'react-router'
import { Customer, useAction, useSelector, useFetching, useDispatch } from '../../store'
import { Layout, LoadingLayout, View, Breadcrumb, Spacer, Ts } from '../ui' 
import CustomerBreadcrumbs from './CustomerBreadcrumbs'
import CustomerForm from './CustomerForm'
import AddressEditor from './AddressEditor'

function CustomerEditView({ }) {
    const { id } = useParams()

    const dispatch = useDispatch()
    const hasCustomer = useAction(Customer.get(id), [id])
    const loading = useFetching(Customer, hasCustomer)
    const customer = useSelector(Customer.select.id(id))

    if (loading || !customer) {
        return <LoadingLayout />
    }

    const editUrl = `/customer/${customer.id}/edit`

    const saveCustomer = customer => {
        dispatch(Customer.update(Customer.sanitize(customer)))
    }

    return <Layout title={customer.name}>
        <CustomerBreadcrumbs customer_id={id}>
            <Breadcrumb url={editUrl}><Ts>Edit</Ts></Breadcrumb>
        </CustomerBreadcrumbs>
        <View name="customer-edit">
            <CustomerForm customer={customer} onSubmit={saveCustomer} />
            <Spacer height={2} />
            <AddressEditor customer_id={id} selected={customer.address_id} />
            <Spacer height={2} />
        </View>
    </Layout>
}

export default CustomerEditView