import React from 'react'
import { Customer, useSelector } from '../../store'
import { Breadcrumbs, Breadcrumb, Ts } from '../ui'


export function CustomerBreadcrumbs({ customer_id, children }) {
    const customer = useSelector(Customer.select.id(customer_id))
	const name = !customer ? 'Loading...' : (customer.name || 'N/A')
    const url = `/customer/${customer_id}`

    return <Breadcrumbs>
        <Breadcrumb url='/customers'>
            <Ts>Customers</Ts>
        </Breadcrumb>
        <Breadcrumb url={url}>{name}</Breadcrumb>
        {children}
    </Breadcrumbs>
}

export default CustomerBreadcrumbs