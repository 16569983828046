import styled from 'styled-components/macro'

export const MobileSearchBar = styled.div`
    display: none;
    padding: 0.25em 0;

    @media screen and (max-width: 32rem) {
        display: block;
    }
`

export default MobileSearchBar