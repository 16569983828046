import _ from "lodash";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
    Layout,
    LoadingLayout,
    View,
    Breadcrumbs,
    Breadcrumb,
    BreadcrumbButton,
    BreadcrumbSpacer,
    Fence,
    Icon,
    Anchor,
    Button,
} from "../ui";
import {
    Sale,
    Comment,
    useAction,
    useFetching,
    useSelector,
    useDispatch,
} from "../../store";
import Feed from "./feed/Feed";
import NewPostBreadcrumb from "./feed/NewPostBreadcrumb";
import LeadDisplay from "./lead/LeadDisplay";
import LeadCardHeader from "./lead/LeadCardHeader";
import { Permission, STATE_LOST } from "../../const";
import StatusBreadcrumb from "./StatusBreadcrumb";
import DriveLinks from "./lead/DriveLinks";
import TextInputModal from "../ui/modal/TextInputModal";

export function SaleView({}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { sale_id } = useParams();
    const hasSale = useAction(Sale.get(sale_id));
    const sale = useSelector(Sale.select.id(sale_id));
    const [showLostDialog, setShowApologyDialog] = useState(false);
    const loading = useFetching(Sale, hasSale);
    if (loading) {
        return <LoadingLayout />;
    }

    const submitUpdate = (update) => {
        if (update.state == STATE_LOST) {
            setShowApologyDialog(true);
            return;
        }
        dispatch(
            Sale.update(
                Sale.sanitize({
                    ...sale,
                    ...update,
                }),
            ),
        );
    };

    const onSubmitApology = (apology) => {
        dispatch(
            Comment.create(
                Comment.sanitize({
                    sale_id: sale.id,
                    text: `**Förlorad:**\n\n${apology}`,
                }),
                () => {
                    setShowApologyDialog(false);
                    dispatch(
                        Sale.update(
                            Sale.sanitize({
                                ...sale,
                                state: STATE_LOST,
                            }),
                        ),
                    );
                },
            ),
        );
    };
    const onCloseApology = () => setShowApologyDialog(false);

    const { name, state } = sale;
    const editUrl = `/sales/${sale_id}/edit`;
    const newPostItem = () => history.push(`/sales/${sale_id}/post`);

    const editable = state !== STATE_LOST;

    return (
        <Layout
            headerOkIcon="ellipsis-h"
            onHeaderOkClick={newPostItem}
            title={name}
            permission={Permission.Sale.Edit}
            backUrl="/sales"
        >
            <Breadcrumbs>
                <Breadcrumb url="/sales">Sales</Breadcrumb>
                <Breadcrumb>{name}</Breadcrumb>
                <BreadcrumbSpacer />
                <Fence permission={Permission.Sale.Edit}>
                    {editable && (
                        <BreadcrumbButton
                            icon="edit"
                            color="primary"
                            href={editUrl}
                            title="Edit"
                        />
                    )}
                    {editable && (
                        <StatusBreadcrumb
                            state={state}
                            onSelect={(state) => submitUpdate({ state })}
                        />
                    )}
                    <NewPostBreadcrumb sale={sale} editable={editable} />
                </Fence>
            </Breadcrumbs>
            <View name="sale" background="#f4f4f4">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={6}>
                            <Card style={{ marginBottom: "2rem" }}>
                                <LeadCardHeader {...sale} />
                                <LeadDisplay sale={sale} />
                            </Card>
                        </Col>
                        <Col md={12} lg={6}>
                            <DriveLinks sale={sale} />
                            <Feed sale={sale} editable={editable} />
                        </Col>
                    </Row>
                </Container>
                <TextInputModal
                    title="Förlorad Affär"
                    message="Vad är anledningen till att affären förlorades?"
                    show={showLostDialog}
                    onSubmit={onSubmitApology}
                    onClose={onCloseApology}
                />
            </View>
        </Layout>
    );
}

export default SaleView;
