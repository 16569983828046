import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import * as fastClick from 'fastclick'

import { AppRouter } from './js/Router'
import RootReducer from './js/store/root'
import { connectSocket } from './js/store/socket'

import './css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { RootErrorBoundary } from './js/components/ui'

const loggerMiddleware = createLogger({ })

// redux store
let store = createStore(
    RootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
)

// connect ws
connectSocket(store.dispatch)



ReactDOM.render(
    <Provider store={store}>
        <RootErrorBoundary>
            <AppRouter store={store} />
        </RootErrorBoundary>
    </Provider>,
    document.getElementById('root')
)

// fastclick fix
fastClick.attach(document.body)
