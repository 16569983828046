import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import theme from '../theme'

const Header = styled.h1`
	margin-top: 0.5em;
	margin-bottom: 0.3em;
	line-height: 1em;
	font-size: ${props => props.theme.sizes[props.options.size]}px;
    font-weight: bold;
`

Header.defaultProps = { theme }

Header.propTypes = {
	options: PropTypes.shape({
		size: PropTypes.string.isRequired
	}).isRequired,
	theme: PropTypes.shape({
		sizes: PropTypes.objectOf(PropTypes.number).isRequired,
	}).isRequired
}

export default Header
