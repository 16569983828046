import _ from 'lodash'
import React from 'react'
import PriorityBadge from './PriorityBadge'
import SaleOwner from './SaleOwner'
import SaleLocation from './SaleLocation'
import TypeBadge from './TypeBadge'
import ActivityInfo from './ActivityInfo'
import { StyledCard, CardRow, CardID, CardLink } from './Card'


export function TodoCard({ sale }) {
    const { id, name, desired_type, priority } = sale
    const link = `/sales/${id}`
    const highlight = sale.source === 'ikea'

    return <StyledCard highlight={highlight}>
        <CardRow>
            <CardID>${id}</CardID>
            <TypeBadge code={desired_type} />
        </CardRow>

        <CardRow>
            <CardLink to={link}>{name}</CardLink>
            <PriorityBadge level={priority} />
        </CardRow>

        <CardRow>
            <SaleLocation sale={sale} />
            <SaleOwner sale={sale} />
        </CardRow>

        {_.map(sale.activities, activity => <ActivityInfo key={activity.id} activity={activity} />)}
    </StyledCard>
}

export default TodoCard
