import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from '../ui'


export const PreviousButton = ({ page, setPage }) => {
    if (page <= 1) {
        return null
    }
    return <Button variant="secondary" onClick={() => setPage(page-1)}>
        <Icon name="chevron-left" />
    </Button>
}

export default PreviousButton