import styled from 'styled-components/macro'

export const DogeContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    transform: scale(0.75);

    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    transition: 1s ease-in-out;

    &.show {
        bottom: 470px;
        transform: scale(1);
    }
`

export const DogeWrapper = styled.div`
    position: relative;
`

export const Doge = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`

export const DogeGlasses = styled.img`
    position: absolute;
    top: 60px;
    left: -400px;
    width: 354px;

    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    transition: 1s ease-in-out;

    &.on {
        left: 62px;
    }
`
