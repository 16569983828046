import React from "react";
import { Formik, Form } from "formik";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Sale, useFetching } from "../../../store";
import { Spacer, ButtonRow, Ts, Icon, Drawers, SubmitButton } from "../../ui";
import { InputField, SelectField, MarkdownField, DateField } from "../../form";
import { Offers } from "../../../const/heatingTypes";
import { SourceTypes, AreaTypes } from "../../../const";
import UserSelectField from "../../user/UserSelectField";
import CrmFields from "./CrmFields";

function LeadForm({ sale, users, formRef, onSubmit, onDelete }) {
    const loading = useFetching(Sale);

    return (
        <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={sale}
            validationSchema={Sale.schema}
            onSubmit={onSubmit}
            onDelete={onDelete}
        >
            <Form>
                {/* {newLead && <Persist name="new-crm-lead" />} */}
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={3}>
                            <UserSelectField
                                name="owner_id"
                                label="Tilldelad"
                                users={users}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <SelectField
                                name="area"
                                label="Region"
                                options={AreaTypes}
                            >
                                <option value="">Ej vald</option>
                            </SelectField>
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <SelectField
                                name="source"
                                label="Source"
                                options={SourceTypes}
                            >
                                <option value="">Ej vald</option>
                            </SelectField>
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <DateField name="created_at" label="Skapad" />
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <SelectField
                                name="desired_type"
                                label="Intresserad av"
                                options={Offers}
                                optionKey="type"
                                optionValue="title"
                            >
                                <option value="">Okänt</option>
                            </SelectField>
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <SelectField
                                name="Install.Type"
                                label="Typ av Installation"
                                options={{ new: "Ny", replace: "Utbyte" }}
                            />
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <InputField
                                name="project_id"
                                label="Projektnummer"
                            />
                        </Col>
                        <Col sm={6} md={6} lg={3}>
                            <SelectField name="priority" label="Priority">
                                <option value="0">Ej vald</option>
                                <option value="1">1</option>
                            </SelectField>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} md={6}>
                            <InputField
                                name="found_us"
                                label="Hur hittade ni oss?"
                            />
                        </Col>
                    </Row>

                    <Spacer height={2} />
                    <h3>Kunduppgifter</h3>
                    <Row>
                        <Col sm={12} md={6}>
                            <InputField name="name" label="Name" />
                            <InputField name="email" label="Email" />
                            <InputField name="phone" label="Phone" />
                            <InputField name="company" label="Företag" />
                        </Col>
                        <Col sm={12} md={6}>
                            <InputField name="street" label="Adress" />
                            <InputField name="postcode" label="Postnummer" />
                            <InputField name="city" label="Ort" />
                        </Col>
                    </Row>

                    <Spacer height={2} />
                    <h3>Detaljer</h3>

                    <Row>
                        <Col sm={12} md={6}>
                            <InputField name="pno" label="Personnummer" />
                        </Col>
                        <Col sm={12} md={6}>
                            <InputField
                                name="property_id"
                                label="Fastighetsbeteckning"
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <InputField
                                name="current_heatpump"
                                label="Befintlig värmepump"
                            />
                        </Col>
                        <Col sm={12} md={12}>
                            <InputField
                                name="value"
                                label="Affärsvärde exkl. moms"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MarkdownField name="other" label="Anteckningar" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Drawers>
                                <CrmFields />
                            </Drawers>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Spacer height={2} />
                            <ButtonRow>
                                <SubmitButton submitting={loading} />
                                <Button type="reset" variant="secondary">
                                    <Icon name="times" />
                                    <Ts>Reset</Ts>
                                </Button>
                                {onDelete && (
                                    <Button
                                        type="button"
                                        variant="danger"
                                        onClick={onDelete}
                                    >
                                        <Icon name="trash" />
                                        <Ts>Delete</Ts>
                                    </Button>
                                )}
                            </ButtonRow>
                            <Spacer height={2} />
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Formik>
    );
}

LeadForm.defaultProps = {
    editMode: false,
    onSubmit: () => {},
    onDelete: null,
};

export default LeadForm;
