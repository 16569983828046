import styled from 'styled-components/macro'

const CommentImage = styled.img`
    ${props => !props.expanded ? `
        width: 350px;
    ` : ''}
    max-width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 3px;
`

export default CommentImage
