import { getLocalUser } from '../../util/jwt'


export function Fence({ children, permission }) {
    const user = getLocalUser()
    if (permission && !user.access[permission]) {
        return null
    }
    return children
}

export default Fence
