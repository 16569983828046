import React from 'react'

import StyledHeader from './styled/Header'

function Header({
	size,
	...other
}) {
	const options = {
		size
	}

    return <StyledHeader {...{options, ...other}} />
}

Header.defaultProps = {
	size: 'default'
}

export default Header
