import styled from 'styled-components/macro'

export const FooterButtonTitle = styled.label`
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: 0;
`

export default FooterButtonTitle