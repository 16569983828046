import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { logout } from '../../util/jwt'


export function LogoutView() {
    useEffect(() => {
        logout()
    })
    return <Redirect to="/login" />
}

export default LogoutView