import styled from 'styled-components/macro'

const NameLabel = styled.label`
    font-size: 0.9rem;
    display: block;
    margin: 0;
    margin-top: 0.5rem;
    cursor: pointer;
    color: #fff;
`

export default NameLabel
