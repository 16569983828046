import styled from 'styled-components/macro'

export const SalesView = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
`

export default SalesView
