import React from 'react'
import Errors from '../errors/Errors'
import { Layout as StyledLayout, GlobalStyle } from './styled/Layout'
import Main from './styled/Main'
import { SiteHeader, FooterMenu } from '../'

export function Layout({ children, onHeaderOkClick, headerOkIcon, backUrl, title, permission }) {
    return <StyledLayout>
        <GlobalStyle />
        <SiteHeader onOK={onHeaderOkClick} okIcon={headerOkIcon} title={title} backUrl={backUrl} permission={permission} />
        <Main>
            {children}
        </Main>
        <Errors />
        <FooterMenu />
    </StyledLayout>
}

export default Layout