import styled from 'styled-components/macro'

const MapContainer = styled.div`
    background-color: #eee;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export default MapContainer
