import React, { useState } from 'react'
import fetch from 'isomorphic-fetch'
import queryString from 'query-string'
import getUrl from '../../util/url'
import { login, getLocalUser } from '../../util/jwt'
import { PageWrapper, LoginContainer, Logo } from './styled'
import LoginForm from './LoginForm'
import logoImg from '../../../img/jsenergi.svg'


export function LoginView({ }) {
    const [error, setError] = useState(false)

    const query = queryString.parse(location.search)
    const redirect = query.p || '/home'

    async function submitLogin(form) {
        setError(false)
        if (form.email.indexOf('@') < 0) {
            form.email += "@jsenergi.com"
        }

        const response = await fetch(getUrl('login/'), {
            method: 'POST',
            body: JSON.stringify(form),
        })

        if (response.status !== 200) {
            setError('Login failed')
        }
        else {
            const { token, user } = await response.json()
            login(token, user)
            window.location.replace(redirect)
        }
    }

    const user = getLocalUser()
    if (user && user.id > 0) {
        window.location.replace(redirect)
    }

    return <PageWrapper>
        <LoginContainer>
            <Logo src={logoImg} />
            <LoginForm onSubmit={submitLogin} error={error} />
        </LoginContainer>
    </PageWrapper>
}

export default LoginView