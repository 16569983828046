import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import { Layout, View, Breadcrumbs, Breadcrumb, BreadcrumbSpacer, BreadcrumbButton } from '../ui'
import { Group, useDispatch } from '../../store'
import GroupForm from './GroupForm'

const DefaultGroupValues = { }


export function NewGroupView({ }) {
    const form = useRef()
    const dispatch = useDispatch()
    const history = useHistory()

    const submitForm = () => form.current.submitForm()
    const submit = group => {
        dispatch(Group.create(Group.sanitize(group), group => {
            history.push(`/groups/${group.id}`)
        }))
    }

    return <Layout title="New Group" headerOkIcon="check" onHeaderOkClick={submitForm}>
        <Breadcrumbs>
            <Breadcrumb url="/groups">Groups</Breadcrumb>
            <Breadcrumb url={`/groups/new`}>New Group</Breadcrumb>
            <BreadcrumbSpacer />
            <BreadcrumbButton title="Save" icon="check" variant="success" onClick={submitForm} />
        </Breadcrumbs>
        <View name="new-group">
            <GroupForm group={DefaultGroupValues} onSubmit={submit} formRef={form} />
        </View>
    </Layout>
}

export default NewGroupView
