import _ from 'lodash'
import React from 'react'
import * as yup from 'yup'
import { Ts, ButtonRow, Icon } from '../ui'
import { Formik, Form } from 'formik'
import { InputField, Persist } from '../form'
import { Button } from 'react-bootstrap'
import { User } from '../../store'

const NewUserSchema = User.schema.shape({
    password: yup.string()
        .min(8, 'Too short')
        .required('Required')
})

export function UserForm({ user, formRef, onSubmit }) {
    const newUser = !user || !user.id

    return <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={user}
        onSubmit={onSubmit}
        validationSchema={newUser ? NewUserSchema : User.schema}
    >
        <Form style={{ maxWidth: '600px' }}>
            {/* {newUser && <Persist name="new-user" ignore={['password']} />} */}
            <InputField name="name" label="Name" />
            <InputField name="email" label="Email" />
            <InputField name="phone" label="Phone" />
            <InputField name="slack_id" label="Slack ID" />
            <InputField name="password" label="Password" type="password" />
            <ButtonRow>
                <Button variant="primary" type="submit">
                    <Icon name="check" />
                    <Ts>Save</Ts>
                </Button>
                <Button variant="secondary" type="reset">
                    <Icon name="times" />
                    <Ts>Reset</Ts>
                </Button>
            </ButtonRow>
        </Form>
    </Formik>
}

export default UserForm