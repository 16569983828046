import React from 'react'
import PropTypes from 'prop-types'
import { ts } from '..'


// Field span
export function FSpan({ value, valueMap, multiline }) {
    if (multiline) {
        return <React.Fragment>
            {value.split('\n').map((item, key) => {
                if (valueMap) {
                    return <span key={key}>{ts(valueMap[item])}</span>
                }
                return <span key={key}>{ts(item)}<br/></span>
            })}
        </React.Fragment>
    }
    if (valueMap) {
        return <span>{ts(valueMap[value])}</span>
    }
    return <span>{ts(value)}</span>
}

FSpan.propTypes = {
    initial: PropTypes.string,
    multiline: PropTypes.bool,
}

FSpan.defaultProps = {
    initial:   '',
    multiline: false,
    valueMap:  false,
}

export default FSpan
