import React from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatFullDate } from '../../util/format'

import SalesTable from './SalesTable'
import WeeklyChart from './WeekChart'
import MonthlyChart from './MonthChart'
import Carousel from './Carousel'

import { Layout } from '../ui/layout'
import { View } from '../ui'

const ActivityLabel = styled.label`
    display: block;
    text-align: center;
    padding: 4px;
    font-size: 10px;
`

function AppDashboardView({ lastUpdate }) {
    const history = useHistory()
    const gotoEdit = () => history.push('/stats')
    return <Layout title="Stats" headerOkIcon="pencil" onHeaderOkClick={gotoEdit}>
        <View name="dashboard">
        <SalesTable small />

        <Carousel interval={30}>
            <MonthlyChart
                title="Månadsförsäljning Reservdelar"
                statDisplay="sales"
                scaleFactor={0.001}
                color="rgba(149, 29, 34, 1)"
                small
            />
            <WeeklyChart
                title="Veckoförsäljning Reservdelar"
                statDisplay="sales"
                emaAlpha={0.88}
                scaleFactor={0.001}
                color="rgba(149, 29, 34, 1)"
                background="rgba(0, 29, 34, 0.05)"
                small
            />

            <MonthlyChart
                title="Månadsförsäljning Pumpar"
                statDisplay="pumps"
                color="rgba(29, 34, 149, 1)"
                small
            />
            <WeeklyChart
                title="Veckoförsäljning Pumpar"
                statDisplay="pumps" 
                emaAlpha={0.95} 
                color="rgba(29, 34, 149, 1)"
                background="rgba(29, 80, 149, 0.05)"
                small
            />
        </Carousel>

        <ActivityLabel>{formatFullDate(lastUpdate)}</ActivityLabel>
        </View>
    </Layout>
}

const mapStateToProps = state => ({
    lastUpdate: state.stats.lastActive,
})
export default connect(mapStateToProps)(AppDashboardView)