import _ from 'lodash'
import React, { useState } from 'react'
import { useField } from 'formik'
import { Form } from 'react-bootstrap'
import { Spinner, Ts } from '../ui'
import { uploadFile } from './uploader/uploads'
import Uploader from './uploader'
import PreviewGallery from './uploader/PreviewGallery'

const IDLE      = 'idle'
const ERROR     = 'error'
const UPLOADED  = 'uploaded'
const UPLOADING = 'uploading'

const InitialState = {
    status: IDLE,
    error:  '',
}

export function UploadField({ name, label, path, ...props }) {
    const [field, meta, helpers] = useField(name);
    const [state, setState] = useState({
        ...InitialState,
        status: meta.value && meta.value.length ? UPLOADED : IDLE,
    })

    const remove = idx => {
        const urls = [...meta.value.slice(0, idx), ...meta.value.slice(idx + 1)]
        helpers.setValue(urls)
    }

    const upload = async files => {
        setState({ 
            status: UPLOADING,
            error: '',
        })

        try {
            let results = []
            for(let file of files) {
                results.push(await uploadFile(file, path))
            }
            setState({
                status: UPLOADED,
            })
            helpers.setValue(results)
        } catch (err) {
            setState({
                status: ERROR,
                error: err,
            })
            helpers.setValue([])
        }
    }

    if (state.status === UPLOADING) {
        return <Spinner />
    }
    else if (state.status === UPLOADED) {
        return <Form.Group controlId={name}>
            {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
            <PreviewGallery urls={_.map(meta.value, v => v.url)} onRemove={remove} />
        </Form.Group>
    } 
    else {
        return <Form.Group controlId={name}>
            {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
            <Uploader onUpload={upload} />
            {state.error && <Form.Control.Feedback type="invalid">
                <Ts>{state.error.toString()}</Ts>
            </Form.Control.Feedback>}
        </Form.Group>
    }
}

export default UploadField