import styled from 'styled-components/macro'

export const Table = styled.table`
	text-align: left;
	border-radius: 0.3rem;
	width: 100%;

	td, th {
		padding: 12px;

		&:first-child { padding-left: 24px; }
		&:last-child  { padding-right: 24px; }

		@media screen and (max-width: 48em) {
			font-size: 0.9em;
			padding: 12px 9px;

			&:first-child { padding-left: 18px; }
			&:last-child  { padding-right: 18px; }
		}

		@media screen and (max-width: 32em) {
			font-size: 0.8em;
			padding: 14px 6px;

			&:first-child { padding-left: 12px; }
			&:last-child  { padding-right: 12px; }
		}
	}

	th {
		font-weight: 700;
		// position: sticky; // not working properly within overflows
		color: #000;
		top: 2rem;
		background-color: #ebebeb;
		font-size: 0.9rem;
		height: 2rem;
		line-height: 1rem;
		z-index: 999;
	}

	thead {
		&:first-child tr td,
		&:first-child tr th{
			background-color: #2E1760;
			color: #fff;
			top: 0;
		}
	}

	tbody {
		tr td,
		tr th{
			background-color: #f3f3f3;
		}

		tr:nth-child(odd) td,
		tr:nth-child(odd) th{
			background-color: #eaeaea;
		}
	}
`

export const Td = styled.td`
	${props => props.right ? 'text-align: right;' : ''}
`

export const Th = styled.td`
	${props => props.right ? 'text-align: right;' : ''}
`

export const Tr = styled.tr``
export const TBody = styled.tbody``
export const THead = styled.thead``
export const TFoot = styled.tfoot``

export default Table
