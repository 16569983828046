import React from 'react'
import styled from 'styled-components/macro'
import ReactDatepicker from 'react-datepicker'

// css styles :(
import 'react-datepicker/dist/react-datepicker.css'

// localization
import { registerLocale } from  "react-datepicker"
import sv from 'date-fns/locale/sv'
registerLocale('sv', sv)

const DateInputWrapper = styled.div`
    .react-datepicker {
        font-family: inherit;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }

    .react-datepicker__current-month { color: #353535; }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name { 
        color: #495057; 
        width: 2.2rem;
        line-height: 2.2rem;
    }

    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
        background-color: #f6f6f6;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__day--weekend {
        color: #951D22;
    }

    .react-datepicker__day--selected { 
        color: #fff; 
        background-color: #2E1760;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__header {
        background-color: #f6f6f6;
        border-bottom: 1px solid #ced4da;
    }

    .react-datepicker__triangle {
        border-bottom-color: #f6f6f6 !important;
    }

    .react-datepicker__input-container input {
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
`;


export function DateInput({ value, showTime, timeIntervals, dateFormat, timeFormat, ...props }) {
    // default date value: current time
    let dvalue = new Date()
    if (!showTime) {
        dvalue.setHours(0,0,0,0);
    } else {
        dvalue.setMinutes(0, 0, 0);
    }

    // convert to date
    if (value) {
        dvalue = new Date(value)
    }

    return <DateInputWrapper>
        <ReactDatepicker
            dateFormat={showTime ? timeFormat : dateFormat}
            showTimeSelect={showTime}
            timeFormat="HH:mm"
            locale="sv"
            selected={dvalue}
            {...props}
        />
    </DateInputWrapper>
}

DateInput.defaultProps = {
    showTime: false,
    timeIntervals: 30,
    dateFormat: 'yyyy-MM-dd',
    timeFormat: 'yyyy-MM-dd HH:mm',
}

export default DateInput
