import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router'
import { User, useAction, useFetching, useSelector } from '../../store'
import { Permission } from '../../const'
import { Layout, Breadcrumbs, Breadcrumb, BreadcrumbButton, BreadcrumbInput, BreadcrumbSpacer, Fence } from '../ui'
import { SalesView, MobileSearchBar } from './styled'
import Board from './Board'
import useLocalStorage from '../../util/localstorage'
import SalesFilterBar from './SalesFilterBar'
import { STATES } from '../../const'

const DefaultQuery = {
    query: '',
    area: '',
    states: [ ...STATES ],
    user: 0,
    page: 1,
    per_page: 10000,
    from: (moment().subtract(120, 'days')).format('YYYY-MM-DD'),
    to: '',
}


export function BoardView({ }) {
    const history = useHistory()
    const [query, setQuery] = useLocalStorage('crm_filter', DefaultQuery)
    const hasUsers = useAction(User.getWithPermission(Permission.Sale.Handle))
    const users  = useSelector(User.select.ordered())
    const loading = useFetching(User, hasUsers)

    const redirectNewSale = () => history.push('/sales/new')
    const setKeywordFilter = keywords => setQuery({ ...query, query: keywords, states: [...STATES] })

    return <Layout headerOkIcon="plus" onHeaderOkClick={redirectNewSale} title="Sales" permission={Permission.Sale.New} backUrl="/menu">
        <SalesView name="sales">
            <Breadcrumbs>
                <Breadcrumb url='/sales'>Sales</Breadcrumb>
                <BreadcrumbSpacer />
                <Fence permission={Permission.Sale.New}>
                    <BreadcrumbButton variant="success" icon="plus" onClick={redirectNewSale} title="New Sale" />
                </Fence>
                <BreadcrumbInput icon="search" placeholder="Sök i CRM..." onSubmit={setKeywordFilter} value={query.query} />
            </Breadcrumbs>

            <MobileSearchBar>
                <BreadcrumbInput icon="search" placeholder="Sök i CRM..." onSubmit={setKeywordFilter} value={query.query} />
            </MobileSearchBar>

            <SalesFilterBar query={query} setQuery={setQuery} users={users} />

            <Board loading={loading} query={query} />
        </SalesView>
    </Layout>
}

export default BoardView
