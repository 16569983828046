import _ from "lodash"
import React from "react"
import { Icon, Ts, ts } from "../ui"
import { Countries } from "../../const"
import { Formik } from "formik";
import { Form, Button, Row, Col, Container } from "react-bootstrap"
import { InputField, SelectField } from "../form"


export function CustomerSearchField({ filter, setFilter }) {
    return <Formik enableReinitialize initialValues={filter} onSubmit={setFilter}>
        {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                <Container fluid>
                <Row>
                    <Col xs={12} md={9}>
                        <InputField name="query" label="Query" skipValidate />
                    </Col>
                    <Col> 
                        <SelectField
                            name="country"
                            label="Country"
                            options={Countries}
                            optionKey="code"
                            optionValue="name"
                            skipValidate
                        >
                            <option value="">{ts("All")}</option>
                        </SelectField>
                    </Col>
                    <Col xs="auto" style={{ display: 'flex', alignItems: 'end', paddingBottom: '1rem' }}>
                        <Button type="submit">
                            <Icon name="search" />
                            <Ts>Search</Ts>
                        </Button>
                    </Col>
                </Row>
                </Container>
            </Form>
        )}
    </Formik>
}

export default CustomerSearchField;
