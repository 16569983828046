import _ from 'lodash'
import React from 'react'
import { Button } from '../ui'
import StyledMapFilters from './styled/MapFilters'

const Filters = {
    'all':          'Alla',
    'nibe':         'NIBE',
    'ivt':          'IVT',
    'bosch':        'Bosch',
    'ctc':          'CTC',
    'panasonic':    'Panasonic',
    'varmebaronen': 'Värmebaronen',
    'mitsubishi':   'Mitsubishi',
}

class MapFilters extends React.Component {
    static defaultProps = {
        onChange: filter => { },
    }

    constructor() {
        super()
        this.state = { selected: 'all' }
    }

    render() {
        const { selected } = this.state
        const { onChange } = this.props

        const renderFilterButton = (title, filter) => {
            const color = filter === selected ? 'primary' : 'default'
            const clickHandler = e => {
                this.setState({ selected: filter }, () => { onChange(filter) })
            }
            return <Button 
                key={filter} 
                onClick={clickHandler} 
                color={color}
                size="medium" 
            >
                {title}
            </Button>
        }

        return <StyledMapFilters>
            {_.map(Filters, renderFilterButton)}
        </StyledMapFilters>
    }
}

export default MapFilters