import _ from 'lodash'

const empty = {
    code: '',
    name: '',
}

export const Countries = [
    {
        code: 'SE',
        name: 'Sweden',
    },
    {
        code: 'DK',
        name: 'Denmark',
    },
    {
        code: 'NO',
        name: 'Norway',
    },
    {
        code: 'UK',
        name: 'United Kingdom',
    },
    {
        code: 'IE',
        name: 'Ireland',
    },
    {
        code: 'DE',
        name: 'Germany',
    },
]

export function GetCountryByCode(code) {
    const c = _.find(Countries, country => country.code === code)
    return !!c ? c : empty
}

export default Countries
