import _ from 'lodash'
import React from 'react'
import CustomersListItem from './CustomersListItem'
import { Table, THead, TBody, Tr, Th, Ts } from '../ui'

export const CustomersList = ({ customers }) => {
    return <Table>
        <THead>
            <Tr>
                <Th><Ts>Name</Ts></Th>
                <Th><Ts>Company</Ts></Th>
                <Th><Ts>Email</Ts></Th>
                <Th><Ts>Phone</Ts></Th>
                <Th><Ts>Address</Ts></Th>
                <Th><Ts>City</Ts></Th>
                <Th><Ts>Country</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(customers, customer => <CustomersListItem key={customer.id} customer={customer} />)}
        </TBody>
    </Table>
}

export default CustomersList
