import styled from 'styled-components/macro'

export const MenuItems = styled.div`
    flex-grow: 1;
    order: ${p => p.order || 1};

    @media screen and (max-width: 32em) {
        display: none;
    }
`

export default MenuItems