import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { Icon, ts } from '../../ui'
import { formatFullDate } from '../../../util/format'
import {
    FeedCard,
    FeedAuthor,
    FeedButtons,
    FeedHeader,
    FeedTime,
} from './styled'


function FeedItem({ children, post_type, user, created_at, editable, icon, onEdit, onDelete }) {
    const timestamp = formatFullDate(created_at)
    const hasButtons = editable && (onEdit || onDelete);

	const doDelete = () => {
		if (confirm(ts('Are you sure?'))) {
			onDelete()
		}
    }

    return <Card as={FeedCard}>
        <Card.Header as={FeedHeader}>
            <Icon name={icon} /> 
            {user && <FeedAuthor to={`/users/${user.id}`}>{user.name}</FeedAuthor>}
            <FeedTime>{timestamp}</FeedTime>
            {hasButtons && <FeedButtons>
            {onEdit && <Button variant="secondary" onClick={onEdit}><Icon name="edit" /></Button>}
            {onDelete && <Button variant="secondary" onClick={doDelete}><Icon name="trash" /></Button>}
            </FeedButtons>}
        </Card.Header>
        <Card.Body>
            {children}
        </Card.Body>
    </Card>
}

FeedItem.defaultProps = {
    onDelete: false,
    onEdit: false,
}

export default FeedItem