import React from 'react'
import { Ts, Icon } from '../'
import { Button as BootstrapButton } from 'react-bootstrap'


export function Button({ title, icon, ...props }) {
    return <BootstrapButton {...props}>
        {icon && <Icon name={icon} />}
        {title && <Ts>{title}</Ts>}
    </BootstrapButton>
}
    
Button.defaultProps = {
    title: false,
    icon: false,
    variant: 'primary',
    onClick: () => { },
}

export default Button
