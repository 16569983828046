import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { 
    getInvoicePayment, 
    postInvoicePayment,
} from '../../store/actions/invoicePayment'
import { Order, Customer } from '../../store'
import { Spinner } from '../ui'
import { StripeProvider, Elements } from 'react-stripe-elements'
import PaymentFormPage from './form_page/PaymentFormPage'
import PaymentErrorPage from './error_page/PaymentErrorPage'
import PaymentSuccessPage from './success_page/PaymentSuccessPage'

// stripe api key depending on environment
const StripeApiKey = process.env.NODE_ENV === "development" ? 
    "pk_test_PltYbn4O88iq2H5qfb0dHvjX" :
    "pk_live_8sBLb8hjkB9XBd94m6GA37ui" 

export class InvoicePaymentView extends React.Component {
    componentWillMount() {
        this.retry()
    }

    submit = () => {
        window.location.reload()
    }

    retry = () => {
        const { getInvoicePayment, getOrderAndCustomer } = this.props
        const { order } = this.props.match.params
        getInvoicePayment(order)
        getOrderAndCustomer(order)
    }

    render() {
        const { order, customer, request } = this.props

        if (request.loading) {
            return <Spinner />
        }

        if (request.error) {
            return <PaymentErrorPage error={request.error} retry={this.retry} />
        }

        if (request.status === "succeeded") {
            return <PaymentSuccessPage />
        }

        return <StripeProvider apiKey={StripeApiKey}>
            <Elements>
                <PaymentFormPage order={order} customer={customer} request={request} submit={this.submit} />
            </Elements>
        </StripeProvider>
    }
}

function getOrderAndCustomer(orderRef) {
    return async (dispatch, getState) => {
        dispatch(Order.getByRef(orderRef)).then(() => {
            const order = Order.selectRef(getState(), orderRef)
            dispatch(Customer.get(order.customer))
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    let props = {
        request: state.invoicePayment,
        order:   Order.selectRef(state, ownProps.match.params.order),
    }
    if (props.order) {
        props.customer = Customer.selectId(state, props.order.customer)
    }
    return props
}
const mapDispatchToProps = {
    getOrder: Order.getByRef,
    getOrderAndCustomer,
    getInvoicePayment,
    postInvoicePayment,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentView))