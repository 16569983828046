import React from 'react'
import StyledLink from './styled/Link'

import theme from './theme'

function Link({ color, ...other }) {
	const options = {
		color,
	}
    return <StyledLink {...{ options, ...other }} />
}

Link.defaultProps = {
	color: 'default',
	theme,
}

export default Link