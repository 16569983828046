import styled from 'styled-components/macro'

const View = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: auto;

    padding: ${p => p.noPad ? '0' : '2rem'};

    /* has to be scroll, not auto */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: 32em) {
        padding: ${p => p.noPad ? '0' : '1rem'};
    }

    background: ${p => p.background};
`

View.defaultProps = {
    background: 'none',
}

export default View