import React, { useRef } from 'react'
import { useParams } from 'react-router'
import { Layout, View, LoadingLayout, Breadcrumbs, Breadcrumb, Spacer, PageWidth, BreadcrumbSpacer, BreadcrumbButton, Header, Ts } from '../ui'
import { Group, User, useDispatch, useAction, useSelector, useFetching } from '../../store'
import { ErrorView, NotFoundView } from '../errors'
import GroupUserList from './GroupUserList'
import GroupForm from './GroupForm'


export function GroupView({ }) {
    const form = useRef()
    const dispatch = useDispatch()
    const { id } = useParams()
    const hasGroup = useAction(Group.get(id), [id])
    const hasUsers = useAction(User.getByGroup(id), [id])
    const loading = useFetching(Group, User, hasGroup, hasUsers)
    const group = useSelector(Group.select.id(id))
    const users = useSelector(User.select.all())

    if (loading) {
        return <LoadingLayout />
    }
    if (!group) {
        return <ErrorView title="Not Found">
            <Ts>Group does not exist</Ts>.
        </ErrorView>
    }

    const updateGroup = group => {
        dispatch(Group.update(group))
    }
    const removeUser = async id => {
        await dispatch(Group.removeUser(group.id, id))
        await dispatch(User.getByGroup(group.id))
    }
    const submitForm = () => form.current.submitForm()

    return <Layout title={group.name} headerOkIcon="check" onHeaderOkClick={submitForm}>
        <Breadcrumbs>
            <Breadcrumb url="/groups">Groups</Breadcrumb>
            <Breadcrumb url={`/groups/${group.id}`}>{group.name}</Breadcrumb>
            <BreadcrumbSpacer />
            <BreadcrumbButton variant="success" icon="check" title="Save" onClick={submitForm} />
        </Breadcrumbs>
        <View name="group">
            <PageWidth>
                <Header><Ts>Group</Ts></Header>
                <GroupForm group={group} onSubmit={updateGroup} formRef={form} />
                <Spacer height={2} />
                <Header><Ts>Users</Ts></Header>
                <GroupUserList users={users} onRemove={removeUser} />
            </PageWidth>
        </View>
    </Layout>
}

export default GroupView
