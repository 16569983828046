import { combineReducers } from 'redux'

import { errors } from './reducers/errors'
import { stats } from './reducers/stats'
import { invoicePayment } from './reducers/invoicePayment'

import { 
    Product, 
    Brand, 
    
    User, 
    Group, 
    
    Customer, 
    MapCustomer,
    Address, 
    Order, 
    Job,

    Sale,
    Comment,
    Activity,
    File,
} from '../store/models'


const RootReducer = combineReducers({
    // old style
    errors,
    stats,
    invoicePayment,

    // core
    [User.id]: User.reducer,
    [Group.id]: Group.reducer,

    // sales
    [Customer.id]: Customer.reducer,
    [MapCustomer.id]: MapCustomer.reducer,
    [Address.id]: Address.reducer,
    [Order.id]: Order.reducer,
    [Job.id]: Job.reducer,

    // crm
    [Sale.id]: Sale.reducer,
    [Comment.id]: Comment.reducer,
    [Activity.id]: Activity.reducer,
    [File.id]: File.reducer,
})

export default RootReducer