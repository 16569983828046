import _ from 'lodash'

// time related constants

export const Weekdays = [ 'saturday', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday' ]
export const WeekdayOrder = _.map([ 6, 0, 1, 2, 3, 4, 5 ], i => Weekdays[i])

export const Months = { 
    1: 'January', 
    2: 'Febuary', 
    3: 'March', 
    4: 'April', 
    5: 'May', 
    6: 'June', 
    7: 'July', 
    8: 'August', 
    9: 'September', 
    10: 'October', 
    11: 'November', 
    12: 'December',
}
