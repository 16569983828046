import React from 'react'
import NormalDoge from './NormalDoge'
import ChristmasDoge from './ChristmasDoge'
import PeterDoge from './PeterDoge'

function Doge() {
    // ibland peter
    if (Math.random() < 0.01) {
        return <PeterDoge />
    }

    let d = new Date()

    // christmas doge
    // 1st dec to 7th jan
    let christmas = d.getMonth() == 11 || (d.getMonth() == 0 && d.getDate() < 7)
    if (christmas) {
        return <ChristmasDoge />
    }

    return <NormalDoge />
}

export default Doge