import _ from 'lodash'
import * as yup from 'yup'
import { CreateModel } from '../../model'
import { apiGet } from '../../api'
import { throwError } from '../../actions/errors'
import fetch from '../../../util/fetch'


export const Group = CreateModel({
    id: 'group',
    url: 'group',
    schema: yup.object().shape({
        name: yup.string()
            .min(3, 'Too short')
            .required('Required'),
    }),
}, actions => ({
    getWithUser: apiGet({
        url:    id => `user/${id}/group`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
    addUser: (groupId, userId) => {
        return async dispatch => {
            dispatch(actions.fetch(groupId))
            try {
                await fetch(`group/${groupId}/add/${userId}`, { method: 'POST' })
                dispatch(actions.abort())
            } 
            catch(error) {
                dispatch(actions.error())
                dispatch(throwError(error.message, error.status))
            }
        }
    },
    removeUser: (groupId, userId) => {
        return async dispatch => {
            dispatch(actions.fetch(groupId))
            try {
                await fetch(`group/${groupId}/remove/${userId}`, { method: 'POST' })
                dispatch(actions.abort())
            } 
            catch(error) {
                dispatch(actions.error())
                dispatch(throwError(error.message, error.status))
            }
        }
    },
}))

export default Group