import _ from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Layout, LoadingLayout, Map } from '../ui'
import { MapCustomer, useAction, useFetching, useSelector } from '../../store'
import MapContainer from './styled/MapContainer'
import MapWrap from './styled/MapWrap'
import MapFilters from './MapFilters'

import MarkerIcon from '../../../img/marker.png'


const hasPosition = c => !!c.lat && !!c.lng
const toMarker = ({ id, lat, lng }) => ({
    key: id,
    customer: id,
    icon: MarkerIcon,
    position: { lat, lng },
})

export function MapView() {
    const history = useHistory()
    const [filter, setFilter] = useState('all')
    const customers = useSelector(MapCustomer.select.all())
    const hasCustomers = useAction(MapCustomer.filter(filter), [filter])
    const loading = useFetching(MapCustomer, hasCustomers)
    if (loading) {
        return <LoadingLayout />
    }

    const onClick = marker => history.push('/customer/' + marker.customer)

    const markers = _.filter(customers, hasPosition).map(toMarker)
    return <Layout title="Customer Map">
        <MapContainer>
            <MapWrap>
                <Map
                    markers={markers}
                    onClickMarker={onClick}
                />
            </MapWrap>
            <MapFilters selected={filter} onChange={setFilter} />
        </MapContainer>
    </Layout>
}

export default MapView