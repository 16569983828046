import _ from 'lodash'
import React from 'react'
import { Comment, Activity, File, useAction, useFetching, useSelector } from '../../../store'
import { useSessionStorage } from '../../../util/localstorage'
import { Tab, Nav, Badge } from 'react-bootstrap'
import { Spinner, Spacer, Ts } from '../../ui'
import OverviewFeed from './OverviewFeed'
import ImageFeed from './ImageFeed'
import DocumentFeed from './DocumentFeed'


export const sortByCreatedTime = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at)
}

export const addFeedTypeTag = tag => {
    return item => _.set(item, 'post_type', tag)
}

export function Feed({ sale, editable }) {
    const hasComments = useAction(Comment.getBySale(sale.id), [sale.id])
    const hasActivities = useAction(Activity.getBySale(sale.id), [sale.id])
    const hasFiles = useAction(File.getBySale(sale.id), [sale.id])
    const comments = useSelector(Comment.select.all()).map(addFeedTypeTag('comment'))
    const activities = useSelector(Activity.select.all()).map(addFeedTypeTag('activity'))
    const images = useSelector(File.select.where(c => c.type === 'image')).map(addFeedTypeTag('file'))
    const documents = useSelector(File.select.where(c => c.type === 'document')).map(addFeedTypeTag('file'))
    const loading = useFetching(Comment, Activity, File, hasComments, hasActivities, hasFiles)
    const [tabKey, setTabKey] = useSessionStorage('sale_feed_tab', 'overview')
    
    if (loading) {
        return <Spinner />
    }

    let overviewCount = comments.length + activities.length
    let imageCount = images.length
    let documentCount = documents.length

    return <Tab.Container activeKey={tabKey} onSelect={setTabKey}>
        <Nav variant="pills">
            <Nav.Item>
                <Nav.Link eventKey="overview">
                    <Ts>Overview</Ts>
                    {overviewCount > 0 && <Badge pill variant="light" style={{ marginLeft: '0.5em' }}>{overviewCount}</Badge>}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="pictures">
                    <Ts>Pictures</Ts>
                    {imageCount > 0 && <Badge pill variant="light" style={{ marginLeft: '0.5em' }}>{imageCount}</Badge>}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="documents">
                    <Ts>Documents</Ts>
                    {documentCount > 0 && <Badge pill variant="light" style={{ marginLeft: '0.5em' }}>{documentCount}</Badge>}
                </Nav.Link>
            </Nav.Item>
        </Nav>
        <Spacer/>
        <Tab.Content>
            <Tab.Pane eventKey="overview">
                <OverviewFeed sale={sale} editable={editable} comments={comments} activities={activities} />
            </Tab.Pane>
            <Tab.Pane eventKey="pictures">
                <ImageFeed sale={sale} editable={editable} images={images} />
            </Tab.Pane>
            <Tab.Pane eventKey="documents">
                <DocumentFeed sale={sale} editable={editable} documents={documents} />
            </Tab.Pane>
        </Tab.Content>
    </Tab.Container>
}

export default Feed