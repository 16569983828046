import _ from 'lodash'
import React from 'react'
import { StateNames, StateIcons } from '../../const'
import { BreadcrumbDropdown, BreadcrumbDropdownButton } from '../ui'


export function StatusBreadcrumb({ state, onSelect }) {
    const state_name = _.get(StateNames, state, '')
    return <BreadcrumbDropdown title={state_name} icon={StateIcons[state]}>
        {_.map(StateNames, (title, key) => {
            if (key === state) {
                // skip the current active state
                return null
            }
            return <BreadcrumbDropdownButton
                title={title}
                key={key}
                icon={StateIcons[key]}
                onSelect={() => onSelect(key)}
            />
        })}
    </BreadcrumbDropdown>
}

export default StatusBreadcrumb
