import styled from 'styled-components/macro'


export const FeedButtons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
    button {
        padding: 0.25em 0.35em;
        margin-right: 0.5em;
        &:last-child {
            margin-right: 0;
        }
    }
`

export default FeedButtons