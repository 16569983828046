import _ from 'lodash'
import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { DateField, SelectField, MarkdownField, Persist } from '../../form'
import { ButtonRow, Icon, SubmitButton, Ts, ts } from '../../ui'
import { Activity, useFetching } from '../../../store'
import { Activities } from '../../../const'


export function ActivityForm({ sale_id, activity, formRef, onSubmit }) {
    activity.sale_id = sale_id
    const newActivity = !activity || !activity.id
    const loading = useFetching(Activity)

    return <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={activity}
        validationSchema={Activity.schema}
        onSubmit={onSubmit}
    >
    <Form>
        {/* {newActivity && <Persist name="new-activity" />} */}
        <SelectField name="activity_type" label="Activity type">
            {_.map(Activities, (activity, key) => (
                <option value={key}>{ts(activity.name)}</option>
            ))}
        </SelectField>
        <DateField name="scheduled_at" label="Time" showTime />
        <MarkdownField name="notes" label="Notes" />
        <ButtonRow>
            <SubmitButton submitting={loading} />
            <Button type="reset" variant="secondary">
                <Icon name="times" />
                <Ts>Reset</Ts>
            </Button>
        </ButtonRow>
    </Form>
    </Formik>
}

export default ActivityForm