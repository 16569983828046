import _ from 'lodash'
import React from 'react'
import { Table, THead, TBody, Tr, Th, Td, Ts, Icon, Link } from '../ui'
import { Button } from 'react-bootstrap'


export function UserListItem({ user, onRemove }) {
    const { id, name, email } = user
	const href = `/users/${id}`
    const remove = () => onRemove(id)
    return <Tr>
        <Td>
            <Link to={href}>{name}</Link>
        </Td>
        <Td>
            <Link to={href}>{email}</Link>
        </Td>
        <Td>
            <Button variant="danger" size="sm" onClick={remove}>
                <Icon name="times" />
            </Button>
        </Td>
    </Tr>
}

export function GroupUserList({ users, onRemove }) {
    return <Table full-width>
        <THead>
            <Tr>
                <Th><Ts>Name</Ts></Th>
                <Th><Ts>Email</Ts></Th>
                <Th><Ts>Actions</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(users, user => <UserListItem key={user.id} user={user} onRemove={onRemove} />)}
        </TBody>
    </Table>
}

export default GroupUserList