
export const Permission = {
    Customer: {
        New: 'new_customer',
        View: 'get_customer',
        Edit: 'set_customer',
        Delete: 'delete_customer',
    },
    Order: {
        View: 'get_order',
        New: 'new_order',
        Invoice: 'new_fortnox',
    },
    Sale: {
        View: 'get_sale',
        New: 'new_sale',
        Edit: 'set_sale',
        Delete: 'delete_sale',
        Handle: 'crm_assign',
        Overview: 'crm_overview',
        Design: 'crm_design',
    },
    Stats: {
        Reports: 'get_report',
        Stats: 'get_stats',
        Edit: 'set_stats',
    },
    Group: {
        View: 'get_group',
        New: 'new_group',
        Edit: 'set_group',
        Delete: 'delete_group',
    },
    User: {
        Login: 'login',
        View: 'get_user',
        New: 'new_user',
        Edit: 'set_user',
        Delete: 'delete_user',
        Upload: 'upload',
    },
}

export default Permission
