import React from 'react'
import { RowColumn, Header } from '../../ui'
import Page from '../Page'
import CreditCardForm from './CreditCardForm'
import OrderSummary from './OrderSummary'

export function PaymentFormPage({ order, customer, request, submit }) {
    const { amount, currency, description } = request

    return <Page description={description}>
        {order && <RowColumn>
            <OrderSummary order={order} customer={customer} />
        </RowColumn>}
        <RowColumn>
            <Header size="medium">Pay with Credit Card</Header>
            <p>Total due: {amount} {currency}</p>
        </RowColumn>
        <CreditCardForm submit={submit} request={request} />
    </Page>
}

export default PaymentFormPage