import _ from 'lodash'
import React from 'react'
import { Icon, Ts } from '..'
import Block from './styled/Block'
import Link from './styled/Link'
import NameLabel from './styled/NameLabel'
import TypeLabel from './styled/TypeLabel'
import { getFormat } from '../../form/uploader/Preview'

export const Attachment = ({ url, title }) => {
    const type = getFormat(url) || '?'
    return <Link href={url} target="_blank" title={title}>
         <Block>   
            <Icon name="file" size={3} color="#fff" fixedWidth={true} />
            <TypeLabel>{type}</TypeLabel>
            {title && <NameLabel>
                <Ts>{title}</Ts>
            </NameLabel>}
        </Block>
    </Link>
}


export default Attachment
