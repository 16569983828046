import React from 'react'
import Row from './Row'
import Column from './Column'

// shorthand for a single column row
export default function({ children, ...props }) {
    return <Row {...props}>
        <Column {...props}>
            {children}
        </Column>
    </Row>
}
