import styled from 'styled-components/macro'

export const StyledBoard = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 0;
    overflow-x: auto;
    background-color: #eaeaea;
    padding: 0.5rem;
    padding-bottom: 0;

    @media screen and (max-width: 32em) {
        flex-direction: column;
    }
`

export default StyledBoard
