import _ from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Ts } from '../ui'
import { Textarea } from './Textarea'


export function TextareaField({ label, ...props }) {
    return <Form.Group controlId={props.name}>
        {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
        <Textarea label={label} {...props} />
    </Form.Group>
}

export default TextareaField