import _ from 'lodash'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Ts, Icon } from '../../ui'
import { StateIcons, STATE_INSTALL } from '../../../const/sale'

export function InstallCard({ sale }) {
    const ticketLink = `https://jsenergi.summera.support/index.aspx?OpenTicket=${sale.project_reference}`
    return <Card style={{ marginBottom: '2em' }}>
        <Card.Header>
            <Icon name={StateIcons[STATE_INSTALL]} style={{ marginRight: '0.5em' }} /> 
            <Ts>Installation</Ts>
        </Card.Header>
        <Card.Body>
            <p><b>Ärendet har skickats vidare till installation.</b></p>
            <p>Gå till summera <a target="_blank" href={ticketLink}>#{sale.project_id}</a></p>
        </Card.Body>
    </Card>
}

export default InstallCard