import _ from 'lodash'
import React from 'react'
import { Attachment, ts } from '../../ui'
import PreviewImage from './styled/PreviewImage'

const imageFormats = [ "jpg", "jpeg", "gif", "png", "heic" ]

export const getFormat = url => {
    if (!url) return ""
    const dot = url.lastIndexOf('.')
    if (dot < 0) {
        return ""
    }
    return url.substr(dot + 1).toLowerCase()
}

export const isImage = url => {
    const ext = getFormat(url)
    return _.includes(imageFormats, ext)
}

export function Preview({ url }) {
    if (isImage(url)) {
        return <PreviewImage src={url} alt={ts('Upload preview')} />
    }
    return <Attachment url={url} />
}

export default Preview