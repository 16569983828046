import React from 'react'
import styled from 'styled-components/macro'
import Dropzone from 'react-dropzone'
import { Icon, Ts } from '../../ui'
import DropInstructionLabel from './styled/DropInstructionLabel'

const UploadInstructions = styled.div`
    color: #888;
    background-color: #f4f4f4;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    padding: 2rem;
    z-index: -100;
    user-select: none;
`

function DropUploader({ onUpload }) {
    const dropzoneRef = React.createRef();
    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    return <Dropzone ref={dropzoneRef} onDrop={onUpload} multiple noClick noKeyboard>
        {({getRootProps, getInputProps, acceptedFiles}) => (
            <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <UploadInstructions onClick={openDialog}>
                <Icon name="file" size={2} />
                <DropInstructionLabel>
                    <Ts>Klicka för att ladda upp, eller släpp filer här</Ts>
                </DropInstructionLabel>
            </UploadInstructions>
            </div>
        )}
    </Dropzone>
}

DropUploader.defaultProps = {
    urls: [],
}

export default DropUploader