import React from 'react'
import { useField } from 'formik'
import { Form } from 'react-bootstrap'
import { Ts } from '../ui'
import DateInput from './DateInput'


export function DatePicker({ name, showTime, dateFormat, timeFormat, ...props }) {
    const [field, meta, helpers] = useField(name)

    return <React.Fragment>
        <DateInput
            showTimeSelect={showTime}
            value={meta.value}
            onChange={helpers.setValue}
            {...props}
        />
        {meta.error && <Form.Control.Feedback type="invalid"><Ts>{meta.error}</Ts></Form.Control.Feedback>}
    </React.Fragment>
}

export default DatePicker