import React from 'react'
import { Layout, View, Breadcrumbs, Breadcrumb, Spinner } from '../ui'
import { Customer, useAction, useSelector, useFetching } from '../../store'
import { useLocalStorage } from '../../util/localstorage'
import CustomersList from './CustomersList'
import CustomerPaging from '../customer_search/CustomerPaging'
import CustomerFilter from './CustomerFilter'

const DefaultFilter = {
    name: '',
    email: '',
    phone: '',
    company: '',
    address: '',
    city: '',
    country: '',
}


export function CustomerListView() {
    const [page, setPage] = useLocalStorage('customers_page', 1)
    const [filter, setFilter] = useLocalStorage('customers_filter', DefaultFilter)

    const hasCustomers = useAction(Customer.filter({ ...filter, page }), [filter, page])
    const customers = useSelector(Customer.select.all())
    const loading = useFetching(Customer, hasCustomers)

    const changeFilter = filter => {
        setPage(1)
        setFilter(filter)
    }

    return <Layout title="Customers">
        <Breadcrumbs>
            <Breadcrumb url='/customers'>Customers</Breadcrumb>
        </Breadcrumbs>
        <View name="customers" noPad>
            <CustomerPaging setPage={setPage} />
            <CustomerFilter filter={filter} setFilter={changeFilter}>
                {!loading && <CustomersList customers={customers} />}
            </CustomerFilter>
            {loading && <Spinner />}
        </View>
    </Layout>
}

export default CustomerListView
