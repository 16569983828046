import React from 'react'
import { Anchor, Tr, Td } from '../ui'
import { formatCurrency } from '../../util/format'
import { GetStoreProductUrl } from '../../util/url'

function OrderItem({ item, currency }) {
    const { name, sku, qty } = item
    const discount = formatCurrency(item.discount, currency)
    const tax = formatCurrency(item.tax, currency)
    const price = formatCurrency(item.price, currency)
    const storeLink = GetStoreProductUrl(sku)

	return <Tr>
		<Td>
            <Anchor href={storeLink} target="_blank">{name}</Anchor>
        </Td>
		<Td>{sku}</Td>
		<Td right>{qty}</Td>
		<Td right>{discount}</Td>
		<Td right>{tax}</Td>
		<Td right>{price}</Td>
	</Tr>
}

export default OrderItem