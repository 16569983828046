import React from 'react'
import { Form } from 'react-bootstrap'
import { Ts } from '../ui'
import { Input } from './Input'


export function InputField({ label, ...props }) {
    return <Form.Group controlId={props.name}>
        {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
        <Input label={label} {...props} />
    </Form.Group>
}

export default InputField