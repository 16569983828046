import React from 'react'
import { useHistory } from 'react-router'
import { Markdown, DateSpan, Ts } from '../../ui'
import { Comment, useDispatch } from '../../../store'
import { CommentText, EditedLabel } from './styled/CommentText'
import FeedItem from '../feed/FeedItem'


export function CommentPost({ type, created_at, updated_at, text, ...post }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const deleteComment = () => dispatch(Comment.destroy(post.id))
    const editComment = () => history.push(`/sales/${post.sale_id}/comment/${post.id}`)

    return <FeedItem {...post} created_at={created_at} onEdit={editComment} onDelete={deleteComment} icon="comment">
        <CommentText>
            <Markdown source={text} />
        </CommentText>
        {created_at !== updated_at && <EditedLabel>
            <Ts>Edited</Ts> <DateSpan time={updated_at} />
        </EditedLabel>}
    </FeedItem>
}

export default CommentPost