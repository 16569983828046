import React from 'react'
import { Link } from '../ui'


export function UserLink({ user }) {
    if (!user) {
        return <span>Ingen</span>
    }
    const url = `/users/${user.id}`
    return <Link to={url}>{user.name}</Link>
}

export default UserLink