import React from 'react'
import { useLocation } from 'react-router'
import { Alert } from 'react-bootstrap'
import { Layout, View, Ts, Header, PageWidth, Spacer } from '../ui'


export function ErrorView({ title, children }) {
    const location = useLocation()
    return <Layout title={title}>
        <View>
            <PageWidth>
                <Header><Ts>{title}</Ts></Header>
                <Spacer />
                <Alert variant="danger">
                    {children}
                </Alert>
            </PageWidth>
        </View>
    </Layout>
}

ErrorView.defaultProps = {
    title: 'Error',
}

export default ErrorView
