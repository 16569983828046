const weekdayOffsets = {
    saturday:  -2,
    sunday:    -1,
    monday:     0,
    tuesday:    1,
    wednesday:  2,
    thursday:   3,
    friday:     4,
}

export const WeekDays = [ 
    'saturday', 'sunday',
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday',
]

export function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0,0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    var yearStart = new Date(d.getFullYear(),0,1);
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return [d.getFullYear(), weekNo];
}

export function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export function getISOWeekDayDate(day, week, year) {
    const monday = getDateOfISOWeek(week, year)
    const offset = weekdayOffsets[day]
    return addDays(monday, offset)
}

function addDays(date, days) {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}
