import { THROW_ERROR, CLEAR_ERRORS } from '../actions/errors.js'

const initialErrorState = {
    error: false,
    code: false,
    message: '',
}

export function errors(state, action) {
    state = state || initialErrorState

    switch(action.type) {
    case THROW_ERROR:
        return {
            ...state,
            error: true,
            ...action,
        }
    case CLEAR_ERRORS:
        return initialErrorState
    default:
        return state
    }
}
