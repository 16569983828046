import styled from 'styled-components/macro'

export const MenuTitle = styled.label`
    display: none;

    color: #fff;
    order: ${p => p.order || 1};
	padding: 0 12px;
    user-select: none;
	font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    flex-grow: 1;
    margin-bottom: 0;

    @media screen and (max-width: 32em) {
        display: block;
    }
`

export default MenuTitle