import _ from 'lodash'
import React from 'react'
import * as colors from '../../../const/colors'
import { Card, Button } from 'react-bootstrap'
import { Ts, Icon, Anchor } from '../../ui'

export function InstallPrompt({ sale, onSubmit }) {

    const todos = checkInstallRequirements(sale)
    const ready = _.every(todos, todo => todo.done)


    return <Card style={{ marginBottom: '2em' }}>
        <Card.Header>
            <Icon name="star" style={{ marginRight: '0.5em' }} /> 
            Vunnen!
        </Card.Header>
        <Card.Body>
            <p>Skicka caset vidare till installation.</p>
            <h4>Checklista</h4>
            <ul>
                {todos.map(({ title, done }) => <li key={title}>
                    {done && <Icon name="check" style={{color: colors.Green}} />}
                    {!done && <Icon name="times" style={{color: colors.Red}} />}
                    <Ts>{title}</Ts>
                </li>)}
            </ul>

            <Anchor href={`/sales/${sale.id}/gallery`} target="_blank">Länk till Bilder</Anchor>
        </Card.Body>
    </Card>
}

function checkInstallRequirements(sale) {
    return [
        {
            title: 'Välj region',
            done: sale.region !== '',
        },
        {
            title: 'Fyll i Personnummer',
            done: sale.pno !== '', // todo: validation?
        },
        {
            title: 'Fyll i Fastighetsbeteckning',
            done: sale.property_id !== '',
        },
    ]
}

export default InstallPrompt