import React from 'react'
import { useLocation } from 'react-router'
import { Ts } from '../ui'
import ErrorView from './ErrorView'


function NotFoundView({ }) {
    const location = useLocation()
    return <ErrorView title="Not Found">
        <Ts location={location.pathname}>The page at :location: does not exist.</Ts>
    </ErrorView>
}

export default NotFoundView
