import React, { useRef } from 'react'
import { useParams } from 'react-router'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'react-bootstrap'
import { Layout, View, LoadingLayout, Breadcrumbs, Breadcrumb, BreadcrumbSpacer, BreadcrumbButton, Header, Spacer, Ts } from '../ui'
import { User, Group, useAction, useSelector, useFetching, useDispatch } from '../../store'
import { ErrorView, NotFoundView } from '../errors'
import PermissionEditor from '../group/PermissionEditor'
import UserGroups from './UserGroups'
import UserForm from './UserForm'


export function UserView({ }) {
    const { id } = useParams()
    const hasUser = useAction(User.get(id), [id])
    const hasGroups = useAction(Group.all(), [])
    const loading = useFetching(User, hasUser, Group, hasGroups)
    const dispatch = useDispatch()
    const user = useSelector(User.select.id(id))
    const groups = useSelector(Group.select.ordered())
    const form = useRef()

    if (loading) {
        return <LoadingLayout />
    }
    if (!user) {
        return <ErrorView title="Not Found">
            <Ts>User does not exist</Ts>.
        </ErrorView>
    }

    const update = user => {
        dispatch(User.update(User.sanitize(user)))
    }
    const addGroup = async (groupId) => {
        await dispatch(Group.addUser(groupId, user.id))
        await dispatch(User.get(user.id))
    }
    const removeGroup = async (groupId) => {
        await dispatch(Group.removeUser(groupId, user.id))
        await dispatch(User.get(user.id))
    }
    const submitForm = () => form.current.submitForm()

    return <Layout title={user.name} headerOkIcon="check" onHeaderOkClick={submitForm}>
        <Breadcrumbs>
            <Breadcrumb url="/users">Users</Breadcrumb>
            <Breadcrumb url={`/users/${user.id}`}>{user.name}</Breadcrumb>
            <BreadcrumbSpacer />
            <BreadcrumbButton icon="check" title="Save" variant="success" onClick={submitForm} />
        </Breadcrumbs>

        <View name="user">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <Header><Ts>User</Ts></Header>
                        <UserForm
                            formRef={form}
                            user={user}
                            onSubmit={update}
                        />
                        <Spacer height={2} />
                    </Col>
                    <Col md={6}>
                        <Header><Ts>Groups</Ts></Header>
                        <Spacer height={1} />
                        <UserGroups
                            user={user}
                            groups={groups}
                            onAdd={addGroup}
                            onRemove={removeGroup}
                        />
                        <Spacer height={2} />
                    </Col>
                </Row>
            </Container>

            <Header><Ts>Calculated Permissions</Ts></Header>
            <p>Användarens behörighet beräknas utifrån användargruppernas tilldelade rättigheter.</p>
            <Formik initialValues={user}>
                <Form>
                    <PermissionEditor name="access" disabled={true} />
                </Form>
            </Formik>

        </View>
    </Layout>
}

export default UserView
