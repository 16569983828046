import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumb } from '../../ui'
import { Sale, Comment, useDispatch, useAction, useFetching } from '../../../store'
import SaleBreadcrumbs from '../SaleBreadcrumbs'
import CommentForm from './CommentForm'

const CommentDefaultValues = { 
    type: 'text',
}


export function NewCommentView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id } = useParams()
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const loading = useFetching(Sale, hasSale)
    if (loading) {
        return <LoadingLayout />
    }

    const backUrl = `/sales/${sale_id}`
    const createComment = comment => {
        dispatch(Comment.create(Comment.sanitize(comment), () => {
            history.push(backUrl)
        }))
    }

    return <Layout title="New Comment" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()} backUrl={backUrl}>
        <SaleBreadcrumbs sale_id={sale_id}>
            <Breadcrumb url={`/sales/${sale_id}/comment`}>New Comment</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <CommentForm sale_id={sale_id} comment={CommentDefaultValues} onSubmit={createComment} formRef={form} />
        </View>
    </Layout>
}

export default NewCommentView