import React from 'react'
import { Layout } from '../layout/Layout'
import { Breadcrumb, Breadcrumbs } from '../breadcrumbs'
import { Spinner } from '../spinner'
import { View } from '../view'

export function LoadingLayout() {
    return <Layout title="Loading">
        <Breadcrumbs>
            <Breadcrumb>Loading</Breadcrumb>
        </Breadcrumbs>
        <View name="loading">
            <Spinner />
        </View>
    </Layout>
}

export default LoadingLayout