import React, { useRef } from "react";
import { useHistory, useParams } from "react-router";
import { Layout, LoadingLayout, View, Breadcrumb } from "../../ui";
import {
    Sale,
    File,
    useDispatch,
    useAction,
    useFetching,
} from "../../../store";
import SaleBreadcrumbs from "../SaleBreadcrumbs";
import ImageForm from "./ImageForm";

export function NewImageView({}) {
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const { sale_id } = useParams();
    const hasSale = useAction(Sale.get(sale_id), [sale_id]);
    const loading = useFetching(Sale, hasSale);
    if (loading) {
        return <LoadingLayout />;
    }

    const backUrl = `/sales/${sale_id}`;
    const createImages = async (form) => {
        for (const file of form.files) {
            console.log(file);
            let type = file.mime.indexOf("image") >= 0 ? "image" : "document";
            await dispatch(
                File.create(
                    File.sanitize({
                        ...file,
                        sale_id,
                        type,
                    })
                )
            );
        }
        history.push(backUrl);
    };

    return (
        <Layout
            title="Upload Image"
            headerOkIcon="check"
            onHeaderOkClick={() => formRef.current.submitForm()}
            backUrl={backUrl}
        >
            <SaleBreadcrumbs sale_id={sale_id}>
                <Breadcrumb url={`/sales/${sale_id}/image`}>
                    Upload Image
                </Breadcrumb>
            </SaleBreadcrumbs>
            <View>
                <ImageForm
                    path={`crm/${sale_id}`}
                    onSubmit={createImages}
                    formRef={formRef}
                />
            </View>
        </Layout>
    );
}

export default NewImageView;
