import { CreateModel } from "../../model";
import { apiPost } from "../../api";
import * as yup from "yup";

function noWhitespace() {
    return this.transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
    );
}

yup.addMethod(yup.number, "noWhitespace", noWhitespace);

export const Sale = CreateModel(
    {
        id: "sale",
        url: "sale",
        schema: yup.object().shape({
            id: yup.number(),
            owner_id: yup.number(),
            email: yup.string().email(),
            priority: yup.number(),
            other: yup.string(),
            value: yup.number().truncate().noWhitespace(),

            Property: yup.object().shape({
                Notes: yup.string().max(512),
            }),

            Install: yup.object().shape({
                IndoorPlacement: yup.string().max(256),
                OutdoorPlacement: yup.string().max(256),
                Notes: yup.string().max(512),
            }),

            Addons: yup.object().shape({
                Notes: yup.string().max(512),
            }),
        }),
    },
    (actions, endpoint) => ({
        search: apiPost({
            url: `${endpoint}/search`,
            before: actions.fetch,
            after: actions.storeMany,
            error: actions.abort,
        }),

        filter: apiPost({
            url: `${endpoint}/filter`,
            before: actions.fetch,
            after: actions.storeMany,
            error: actions.abort,
        }),

        refreshOffersheet: apiPost({
            url: (item) => `${endpoint}/${item.id}/offersheet`,
            before: actions.fetch,
            after: actions.update,
            error: actions.abort,
        }),
    })
);

export default Sale;
