import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumb } from '../../ui'
import { Sale, File, useDispatch, useAction, useFetching } from '../../../store'
import SaleBreadcrumbs from '../SaleBreadcrumbs'
import FileForm from './FileForm'


export function NewImageView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id } = useParams()
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const loading = useFetching(Sale, hasSale)
    if (loading) {
        return <LoadingLayout />
    }

    const backUrl = `/sales/${sale_id}`
    const createFile = async form => {
        for(const file of form.files) {
            let type = file.mime.indexOf('image') >= 0 ? 'image' : 'document'
            await dispatch(File.create(File.sanitize({
                ...file,
                type,
                sale_id,
            })))
        }
        history.push(backUrl)
    }

    return <Layout title="Upload File" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()} backUrl={backUrl}>
        <SaleBreadcrumbs sale_id={sale_id}>
            <Breadcrumb url={`/sales/${sale_id}/file`}>Upload File</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <FileForm path={`crm/${sale_id}`} onSubmit={createFile} formRef={form} />
        </View>
    </Layout>
}

export default NewImageView