import styled from 'styled-components/macro'


export const FeedHeader = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem !important;

    & > i {
        margin: 0 0.5em;
    }
`

export default FeedHeader
