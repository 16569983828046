import {
    GET_INVOICE_PAYMENT,
    RECEIVE_INVOICE_PAYMENT,
    POST_INVOICE_PAYMENT,
    INVOICE_PAYMENT_SUCCESS,
    INVOICE_PAYMENT_ERROR,
} from '../actions/invoicePayment'

const DefaultState = {
    loading: false,
    paid: false,
    error: '',
    currency: 'SEK',
}

export function invoicePayment(state, action) {
    if (typeof(state) === 'undefined') {
        return DefaultState
    }

    switch(action.type) {
        case GET_INVOICE_PAYMENT: {
            return {
                ...state,
                loading: true,
                error: false,
            }
        }
        case RECEIVE_INVOICE_PAYMENT: { 
            return {
                ...state,
                loading: false,
                ...action.request,
            }
        }
        case POST_INVOICE_PAYMENT: {
            return {
                ...state,
                loading: true,
            }
        }
        case INVOICE_PAYMENT_SUCCESS: {
            return { 
                ...state,
                loading: false,
                paid: true,
                ...action.request,
            }
        }
        case INVOICE_PAYMENT_ERROR: {
            return {
                ...state,
                loading: false,
                paid:    false,
                error:   action.error,
            }
        }
        default:
            return state
    }
}

export default invoicePayment