import React from 'react'
import NextButton from './NextPageButton'
import PreviousButton from './PreviousPageButton'
import { PagingRow, PagingInfo, PagingButtons, PageLoader } from './styled'
import { Ts, Icon } from '../ui'
import { Customer, useSelector } from '../../store'


export function CustomerPaging({ setPage, loading }) {
    const { page, pages, total } = useSelector(Customer.select.paging())

    return <PagingRow> 
        <PagingInfo> 
            <Ts>Page</Ts> {page} <Ts>of</Ts> {pages} ({total} <Ts>customers</Ts>)
        </PagingInfo>
        <PagingButtons>
            {loading && <PageLoader>
                <Icon name="refresh" spin />
            </PageLoader>}
            <PreviousButton page={page} setPage={setPage} />
            <span>&nbsp;</span>
            <NextButton page={page} pages={pages} setPage={setPage} />
        </PagingButtons>
    </PagingRow>
}

export default CustomerPaging