import _ from 'lodash'
import React from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import { AreaTypes, Permission } from '../../const'
import { DateInput } from '../form'
import { Icon, Fence } from '../ui'
import { FilterBarForm, QueryDisplayBar, QueryMessage } from './styled'


export function SalesFilterBar({ query, setQuery, users }) {
    const setAreaFilter = area => setQuery({ ...query, area })
    const setUserFilter = id => setQuery({ ...query, user: Number(id) })
    const setFromDate = from => setQuery({ ...query, from })
    const setToDate = to => {
        to.setHours(23, 59, 59, 999)
        setQuery({ ...query, to })
    }

    if (query.query) {
        return <QueryDisplayBar>
            <Button onClick={() => setQuery({ ...query, query: '' })} size="sm" variant="secondary">
                <Icon name="arrow-left" />
                <span>Visa alla</span>
            </Button>
            <QueryMessage>Visar sökresultat för <i>{query.query}</i></QueryMessage>
        </QueryDisplayBar>
    }

    return <FilterBarForm>
        <Form.Row style={{ alignItems: 'center', margin: 0 }}>
            <Fence permission={Permission.Sale.Overview}>
                <Col>
                    <Form.Control as="select" onChange={e => setUserFilter(e.target.value)} value={query.user}>
                        <option value="0">Alla Användare</option>
                        {_.map(users, user => <option key={user.id} value={user.id}>{user.name}</option>)}
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Control as="select" onChange={e => setAreaFilter(e.target.value)} value={query.area}>
                        <option value="">Alla Regioner</option>
                        {_.map(AreaTypes, (title, code) => <option key={code} value={code}>{title}</option>)}
                    </Form.Control>
                </Col>
            </Fence>
            <Col>
                <DateInput
                    value={query.from}
                    onChange={setFromDate}
                    selectsStart
                    maxDate={query.to}
                />
            </Col>
            <Col>
                <DateInput
                    value={query.to}
                    onChange={setToDate}
                    selectsEnd
                    minDate={query.from}
                    maxDate={new Date()}
                />
            </Col>
        </Form.Row>
    </FilterBarForm>
}

export default SalesFilterBar
