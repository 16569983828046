import React from 'react'
import { useHistory } from 'react-router'
import { Ts, Icon } from '../'
import { Button as BootstrapButton } from 'react-bootstrap'


export function LinkButton({ title, icon, to, ...props }) {
    const history = useHistory()
    const onClick = () => history.push(to)

    return <BootstrapButton {...props} onClick={onClick}>
        {icon && <Icon name={icon} />}
        {title && <Ts>{title}</Ts>}
    </BootstrapButton>
}
    
LinkButton.defaultProps = {
    title: false,
    icon: false,
    variant: 'primary',
    to: null,
}

export default LinkButton
