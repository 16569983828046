import React from 'react'
import { Form } from 'react-bootstrap'
import { Ts } from '../ui'
import DatePicker from './DatePicker'


export function DateField({ label, ...props }) {
    return <Form.Group controlId={props.name}>
        {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
        <DatePicker {...props} />
    </Form.Group>
}

export default DateField