import _ from 'lodash'
import React from 'react'
import { ListGroup } from 'react-bootstrap'


function flattenErrors(errors, messages = []) {
    _.forEach(errors, (value, key) => {
        if (_.isArray(value)) {
            _.each(value, errors => flattenErrors(errors, messages))
        } else {
            messages.push(value)
        }
    })
    return messages
}

export function ValidationErrors({ errors }) {
    const messages = flattenErrors(errors)
    if (!messages.length) {
        return null
    }
    console.log(errors)
    console.log(messages)
    return <ListGroup>
        {messages.map((msg, i) => <ValidationErrorItem key={i} message={msg} />)}
    </ListGroup>
}

export function ValidationErrorItem({ message }) {
    return <ListGroup.Item variant="danger">{message}</ListGroup.Item>
}

export default ValidationErrors