import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { Icon } from '../../ui'
import { SourceTypes } from '../../../const'
import TypeBadge from './TypeBadge'
import PriorityBadge from './PriorityBadge'
import SaleLocation from './SaleLocation'
import SaleOwner from './SaleOwner'
import { StyledCard, CardRow, CardID, CardLink } from './Card'


export function NewCard({ sale }) {
    const { id, name, desired_type, priority } = sale
    const link = `/sales/${id}`
    const highlight = sale.source === 'ikea'

    const leadTimeStr = moment(sale.created_at).format('MMM Do')
    const source_name = _.get(SourceTypes, sale.source, 'Övriga')

    return <StyledCard highlight={highlight}>
        <CardRow>
            <CardID>${id}</CardID>
            <TypeBadge code={desired_type} />
        </CardRow>

        <CardRow>
            <CardLink to={link}>{name}</CardLink>
            <PriorityBadge level={priority} />
        </CardRow>

        <LeadLabel>
            <Icon name="arrow-right"/> {leadTimeStr} från {source_name}
        </LeadLabel>
        
        <CardRow>
            <SaleLocation sale={sale} />
            <SaleOwner sale={sale} />
        </CardRow>
    </StyledCard>
}

export const LeadLabel = styled.span`
    display: block;
    font-size: 0.8rem;
`

export default NewCard
