import React from 'react'
import { CardCVCElement } from 'react-stripe-elements'
import StripeInput from '../styled/StripeInput'
import StripeStyle from '../styled/StripeStyle'

export function CardCVC() {
    return <StripeInput>
        <CardCVCElement style={StripeStyle} />
    </StripeInput>
}

export default CardCVC