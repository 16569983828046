import _ from 'lodash'
import React from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { ButtonRow, Fence, Icon, Spacer, Ts } from '../ui'
import { InputField } from '../form'
import { WeekDays } from '../../util/week'
import { updateDayStats } from '../../store/actions/stats'
import { formatCurrency } from '../../util/format'
import { StatsTable } from './styled'
import { Permission } from '../../const'
import * as yup from 'yup'


function capitalize(str) {
    if (str.length === 0)
        return str
    return str[0].toUpperCase() + str.substr(1).toLowerCase()
}

const daySchema = yup.object().shape({
    sales: yup.number().required(),
    pumps: yup.number().required(),
})
const schema = yup.object().shape(_.reduce(WeekDays, (r, day) => _.set(r, day, daySchema), { }))


export function WeekStatEditor({ week }) {
    const dispatch = useDispatch()

    const saveStats = week => {
        const days = _.map(WeekDays, day => ({
                ...week[day],
                sales: parseInt(week[day].sales, 10),
                pumps: parseInt(week[day].pumps, 10),
            }))
            .filter(day => day.sales || day.pumps)

        if (days.length) {
            dispatch(updateDayStats(days))
        }
    }

    return <Formik
        enableReinitialize
        initialValues={week}
        validationSchema={schema}
        onSubmit={saveStats}
    >
    {({ values, handleSubmit, handleReset }) => {
        const sum_sales = _.map(WeekDays, day => _.get(values, [ day, 'sales' ], 0))
                        .reduce((r, v) => r + parseInt(v, 10), 0)
        const sum_pumps = _.map(WeekDays, day => _.get(values, [ day, 'pumps' ], 0))
                        .reduce((r, v) => r + parseInt(v, 10), 0)

        return <Form onSubmit={handleSubmit} onReset={handleReset}>
        <StatsTable>
            <tbody>
            {WeekDays.map(day => <tr key={day}>
                <th>
                    <Ts>{capitalize(day)}</Ts>
                </th>
                <td>
                    <InputField name={`${day}.sales`} suffix="kr" autoComplete="off" />
                </td>
                <td>
                    <InputField name={`${day}.pumps`} suffix="st" autoComplete="off" />
                </td>
            </tr>)}
            <tr className="sum">
                <th><Ts>Sum</Ts></th>
                <td>{formatCurrency(sum_sales, 'kr')}</td>
                <td>{sum_pumps}</td>
            </tr>
            </tbody>
        </StatsTable>
        <Spacer />
        <Fence permission={Permission.Stats.Edit}>
            <ButtonRow>
                <Button type="submit" variant="primary">
                    <Icon name="check" />
                    <Ts>Save</Ts>
                </Button>
                <Button type="reset" variant="secondary">
                    <Icon name="times" />
                    <Ts>Reset</Ts>
                </Button>
            </ButtonRow>
        </Fence>
        </Form>
    }}
    </Formik>
}

export default WeekStatEditor