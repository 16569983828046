import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector as useReduxSelector, shallowEqual } from 'react-redux'

export function useAction(fn, deps = []) {
    const dispatch = useDispatch()
    const [fetched, setFetched] = useState(false)
    useEffect(() => {
        fn(dispatch)
        setFetched(true)
    }, deps)
    return fetched
}

function isFetching(state, models) {
    return _.reduce(models, (fetching, model) => {
        // special support for fetching flags returned from useAction
        if (typeof(model) == 'boolean') {
            return fetching || !model
        }

        return fetching || model.isFetching(state)
    }, false)
}

export function useFetching(...models) {
    return useReduxSelector(state => isFetching(state, models), shallowEqual)
}

export const useSelector = selector => useReduxSelector(selector, shallowEqual)

// export react-redux hooks from here for convenience
export { useDispatch }