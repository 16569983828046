import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Card } from 'react-bootstrap'
import { DateSpan, Ts } from '../../ui'
import { AreaTypes } from '../../../const'
import PriorityBadge from '../../sales/card/PriorityBadge'
import UserLink from '../../user/UserLink'


const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const HeaderName = styled.span`
    font-weight: bold;
    flex-grow: 1;
`

const HeaderArea = styled.span`
    color: #777;
    font-size: 0.8em;
    font-weight: bold;

    border-right: 1px solid #ccc;
    padding-right: 0.5em;
    margin-right: 0.5em;
`

const HeaderAuthor = styled.span`
    color: #777;
    font-size: 0.8em;
    font-weight: bold;

    border-right: 1px solid #ccc;
    padding-right: 0.5em;
    margin-right: 0.5em;
`

const HeaderTime = styled.span`
    color: #777;
    font-size: 0.8em;
`


export function LeadCardHeader({ name, area, created_at, id, priority, author }) {
    const area_name = _.get(AreaTypes, area, '')
    return <Card.Header as={CardHeader}>
        <PriorityBadge style={{ marginRight: '0.5em' }} level={priority} />
        <HeaderName>{`$${id} `}{name}</HeaderName>
        {!!author && <HeaderAuthor><UserLink user={author} /></HeaderAuthor>}
        <HeaderArea><Ts>{area_name}</Ts></HeaderArea>
        <HeaderTime><DateSpan time={created_at} noTime /></HeaderTime>
    </Card.Header>
}

export default LeadCardHeader