import Cookies from 'universal-cookie'

const LOCAL_JWT = 'jwt'
const LOCAL_USER = 'user'

export function getJwt() {
    const token = localStorage.getItem(LOCAL_JWT)
    if (!token || token === 'false') {
        return false
    }
    return token
}

export function getLocalUser() {
    var user = localStorage.getItem(LOCAL_USER)
    if (!user || user === 'false') {
        return false
    }
    return JSON.parse(user)
}

export function setJwt(token) {
    localStorage.setItem(LOCAL_JWT, token)

    const cookies = new Cookies()
    cookies.set('jwt', token, {
        domain: getBaseDomain(),
        secure: true,
        maxAge: 30 * 24 * 3600,
    })
}

export function setLocalUser(user) {
    localStorage.setItem(LOCAL_USER, JSON.stringify(user))
}

export function login(token, user) {
    setJwt(token)
    setLocalUser(user)
}

export function logout() {
    console.log('Logout')
    setJwt(false)
    setLocalUser({
        id: 0,
        email: '',
        name: '',
    })

    const cookies = new Cookies()
    cookies.remove('jwt', {
        domain: getBaseDomain(),
        secure: true,
    })
}

export function getBaseDomain() {
    const host = window.location.hostname
    return host.split('.')[host.split('.').length-2]+'.'+host.split('.')[host.split('.').length-1];
}
