import React from 'react'
import { Badge } from 'react-bootstrap'


export function PriorityBadge({ level, style={} }) {
    if (level <= 0) {
        return null
    }
    let variant = 'danger'
    switch(level) {
    case 1: variant = 'danger'; break;
    case 2: variant = 'warning'; break;
    case 3: variant = 'success'; break;
    }
    return <Badge pill variant={variant} style={{color: '#fff', ...style}}>{level}</Badge>
}

export default PriorityBadge
