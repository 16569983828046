import React from 'react'
import { useHistory } from 'react-router'
import { Markdown, Ts, ts, DateSpan, Icon, Spacer } from '../../ui'
import { Activity, useDispatch } from '../../../store'
import { Activities } from '../../../const'
import { Button } from 'react-bootstrap'
import FeedItem from '../feed/FeedItem'


export function ActivityPost({ type, editable, ...activity }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const deleteActivity = () => dispatch(Activity.destroy(activity.id))
    const editActivity = () => history.push(`/sales/${activity.sale_id}/activity/${activity.id}`)
    const complete = () => dispatch(Activity.update({ ...activity, completed: true }))

    const { notes, scheduled_at, activity_type, completed } = activity
    const ActivityType = Activities[activity_type]

    return <FeedItem {...activity} onEdit={editActivity} onDelete={deleteActivity} editable={editable} icon="car">
        <h5><Icon name={ActivityType.icon} /> {ts(ActivityType.name)} <Ts>scheduled at</Ts> <DateSpan time={scheduled_at} /></h5>
        
        {!completed && <Button variant="primary" onClick={complete}>
            <Icon name="check" />
            <Ts>Done</Ts>
        </Button>}
        {completed && <Button variant="success" disabled>
            <Icon name="check" />
            <Ts>Done</Ts>
        </Button>}

        {notes && <React.Fragment>
            <Spacer />
            <Markdown source={notes} />
        </React.Fragment>}
    </FeedItem>
}

export default ActivityPost