import React from "react";
import { useHistory, useParams } from "react-router";
import {
    Layout,
    View,
    Row,
    Column,
    LoadingLayout,
    Spacer,
    Header,
    Ts,
    BreadcrumbSpacer,
    BreadcrumbButton,
    Fence,
} from "../ui";
import { Customer, useAction, useSelector, useFetching } from "../../store";
import { Permission } from "../../const";
import CustomerBreadcrumbs from "./CustomerBreadcrumbs";
import CustomerOrderList from "./CustomerOrderList";
import CustomerJobList from "./CustomerJobList";
import CustomerMap from "./CustomerMap";
import CustomerInfo from "./CustomerInfo";
import CreateSaleButton from "./CreateSaleButton";

function CustomerView({}) {
    const history = useHistory();
    const { id } = useParams();

    const hasCustomer = useAction(Customer.get(id), [id]);
    const loading = useFetching(Customer, hasCustomer);
    const customer = useSelector(Customer.select.id(id));
    if (loading || !customer) {
        return <LoadingLayout />;
    }

    const editCustomer = () => history.push(`/customer/${id}/edit`);

    return (
        <Layout
            headerOkIcon="pencil"
            onHeaderOkClick={editCustomer}
            title={customer.name}
            backUrl="/customers"
        >
            <CustomerBreadcrumbs customer_id={id}>
                <BreadcrumbSpacer />
                <CreateSaleButton customer={customer} />
                <Fence permission={Permission.Customer.Edit}>
                    <BreadcrumbButton
                        title="Edit Customer"
                        icon="pencil"
                        variant="success"
                        onClick={editCustomer}
                    />
                </Fence>
            </CustomerBreadcrumbs>
            <View name="customer">
                <Spacer />
                <Row padding="none" align="stretch">
                    <Column size={6}>
                        <CustomerInfo customer={customer} />
                    </Column>
                    <Column size={6}>
                        <CustomerMap customer={customer} height="100%" />
                    </Column>
                </Row>
                <Spacer />
                <Header>
                    <Ts>Jobs</Ts>
                </Header>
                <CustomerJobList customer_id={id} />
                <Spacer />
                <Header>
                    <Ts>Orders</Ts>
                </Header>
                <CustomerOrderList customer_id={id} />
            </View>
        </Layout>
    );
}

export default CustomerView;
