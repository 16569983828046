import _ from 'lodash'
import fetch from '../util/fetch'
import { throwError } from './actions/errors'

function formatUrl(baseUrl, param) {
    if (_.isFunction(baseUrl)) {
        return baseUrl(param || { })
    }
    return baseUrl
}

export function apiGet({ url, before, after, error, type, ...options }) {
    return param => {
        const parameterizedUrl = formatUrl(url, param)
        return async dispatch => {
            if (_.isFunction(before)) {
                dispatch(before(param))
            }
            try {
                const json = await fetch(parameterizedUrl, options)
                if (_.isFunction(after)) {
                    dispatch(after(json, param))
                }
            } 
            catch(exception) {
                if (_.isFunction(error)) {
                    dispatch(error())
                }
                dispatch(throwError(exception))
            }
        }
    }
}

export function apiPost({ url, before, after, error, ...options }) {
    return (item, callback) => {
        const postData = item || { }
        return async dispatch => {
            if (_.isFunction(before)) {
                dispatch(before(item))
            }
            try {
                const json = await fetch(formatUrl(url, postData), {
                    method: 'POST',
                    body: JSON.stringify(postData),
                    ...options,
                })
                if (_.isFunction(after)) {
                    dispatch(after(json))
                }
                if (_.isFunction(callback)) {
                    callback(json)
                }
            }
            catch(exception) {
                if (_.isFunction(error)) {
                    dispatch(error())
                }
                dispatch(throwError(exception))
            }
        }
    }
}

export function apiPut({ url, before, after, error, ...options }) {
    return (item, callback) => {
        const putData = item || { }
        if (!putData.id) {
            throw new Error("Put operation requires an id")
        }

        return async dispatch => {
            if (_.isFunction(before)) {
                dispatch(before(putData))
            }
            try {
                const json = await fetch(formatUrl(url, putData), {
                    method: 'PUT',
                    body: JSON.stringify(putData),
                    ...options,
                })
                if (_.isFunction(after)) {
                    dispatch(after(json))
                }
                if (_.isFunction(callback)) {
                    callback(json)
                }
            }
            catch(exception) {
                if (_.isFunction(error)) {
                    dispatch(error())
                }
                dispatch(throwError(exception))
            }
        }
    }
}

export function apiDelete({ url, before, after, error, ...options }) {
    return (id, callback) => {
        if (!id) {
            throw new Error("Delete operation requires an id")
        }

        return async dispatch => {
            if (_.isFunction(before)) {
                dispatch(before(id))
            }
            try {
                await fetch(formatUrl(url, id), {
                    method: 'DELETE',
                    ...options,
                })
                if (_.isFunction(after)) {
                    dispatch(after(id))
                }
                if (_.isFunction(callback)) {
                    callback(id)
                }
            }
            catch(exception) {
                if (_.isFunction(error)) {
                    dispatch(error())
                }
                dispatch(throwError(exception))
            }
        }
    }
}