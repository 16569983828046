import _ from 'lodash'
import {
    STORE_FETCH,
    STORE_ABORT,
    STORE_SINGLE,
    STORE_MANY,
    STORE_DELETE,
    STORE_CLEAR,
    STORE_APPEND,
    STORE_UPDATE,
} from './actions'

export const initialStoreState = {
    isFetching: false,
    paging: {
        page:  0,
        pages: 0,
        total: 0,
    },
    byId:  { },
    order: [ ],
}

// General Purpose Reducer
export function StoreReducer(type, options) {
    options = _.extend({
        idAttr: 'id',
        extender: null,
    }, options || { })

    const getId = item => { return ''+item[options.idAttr] }

    return (state, action) => {
        if (typeof(state) === 'undefined') {
            return initialStoreState
        }

        // only handle actions on this object type
        if (action.type !== type) {
            return state
        }

        switch(action.operation) {
            // set fetching state
            case STORE_FETCH: {
                return {
                    ...state,
                    isFetching: true,
                }
            }

            case STORE_ABORT: {
                return {
                    ...state,
                    isFetching: false,
                }
            }

            // replace the collection with a single item
            case STORE_SINGLE: {
                const newId = getId(action.item)
                return {
                    ...state,
                    isFetching: false,
                    order: [ newId ],
                    byId: {
                        [newId]: action.item,
                    },
                    paging: {
                        page: 1,
                        pages: 1,
                        total: 1,
                    },
                }
            }

            // replace the collection with a list of items
            case STORE_MANY: {
                const newIds   = _.map(action.items, item => getId(item))
                const newItems = _.reduce(action.items, (m, item) => _.set(m, getId(item), item), { })
                return {
                    ...state,
                    isFetching: false,
                    order: newIds,
                    byId: newItems,
                    paging: {
                        page: action.page || 1,
                        pages: action.pages || 1,
                        total: action.total || newIds.length,
                    },
                }
            }

            // update an existing item with new properties
            case STORE_UPDATE: {
                const id = getId(action.item)
                return {
                    ...state,
                    isFetching: false,
                    byId: {
                        ...state.byId,
                        [id]: action.item,
                    },
                }
            }

            // append a new item to the collection
            case STORE_APPEND: {
                const id = getId(action.item)
                return {
                    ...state,
                    isFetching: false,
                    order: [ id, ...state.order ],
                    byId: {
                        ...state.byId,
                        [id]: action.item,
                    },
                    paging: {
                        ...state.paging,
                        total: state.paging.total + 1,
                        pages: action.pages || state.paging.pages,
                    },
                }
            }

            // delete an item from the collection
            case STORE_DELETE: {
                const deleteId = getId(action)
                const { [deleteId]: deleted, ...withoutDeleted } = state.byId
                return {
                    ...state,
                    isFetching: false,
                    order: state.order.filter(id => id !== deleteId),
                    byId: withoutDeleted,
                    paging: {
                        ...state.paging,
                        total: state.paging.total - 1,
                    },
                }
            }

            // empty the collection
            case STORE_CLEAR: {
                return initialStoreState
            }

            default:
                // allow nested reducers to add custom functionality
                if (options.extender) {
                    if (_.isFunction(options.extender)) {
                        return options.extender(action, state)
                    } else {
                        // please pass a fucking function
                        throw new Error("Expected reducer extension to be a function")
                    }
                }

                // unknown operation - return an error
                throw new Error(`Unknown operation ${action.operation}`)
        }
    }
}