import styled from 'styled-components/macro'


export const ColumnContainer = styled.div`
    width: 13.37%;
    min-width: 230px;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0.5rem;
    margin-bottom: 0;

    &:last-child {
        padding-right: 0.5rem;
        box-sizing: content-box;
    }

    @media screen and (max-width: 32rem) {
        width: 100%;
        margin: 0;
        margin-bottom: 0.5rem;
    }
`

export const ColumnHeader = styled.div`
    padding: 0.75rem 0.5rem;
    background-color: #2E1760;
    color: white;
    border-radius: 3px 3px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-left: 0.25rem;
    }
`

export const ColumnTitle = styled.label`
    flex-grow: 1;
    margin: 0;
`

export const CardContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    min-height: 0;

    @media screen and (max-width: 32rem) {
        display: ${p => p.closed ? 'none' : 'display'};
        max-height: 50vh;
    }

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2E1760;
        background-color: #aaa;
    }
`

export const CardList = styled.ul`
    background-color: white;
    border-radius: 0 0 3px 3px;
`

export default ColumnContainer
