import styled from 'styled-components/macro'

const Breadcrumbs = styled.ul`
    background-color: #171738;
    padding: 0.5em 0.5em;
    color: #fff;
    line-height: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    margin: 0;

    min-height: 45px;

    display: flex;
    flex-direction: row;
    justify-items: flex-start;

    @media screen and (max-width: 32em) {
        display: none;
    }
`

export default Breadcrumbs
