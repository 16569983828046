import styled from 'styled-components/macro'

export const Spacer = styled.div`
    display: block;
    height: ${p => p.height}rem;
    flex: 0 0 ${p => p.height}rem;
`

Spacer.defaultProps = {
    height: 1,
}