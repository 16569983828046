import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumb, BreadcrumbButton, BreadcrumbSpacer, PageWidth } from '../../ui'
import { Sale, User, useAction, useFetching, useSelector, useDispatch } from '../../../store'
import { Permission } from '../../../const'
import SaleBreadcrumbs from '../SaleBreadcrumbs'
import LeadForm from './LeadForm'


export function LeadEditorView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id } = useParams()
    const hasLead = useAction(Sale.get(sale_id), [sale_id])
    const hasUsers = useAction(User.getWithPermission(Permission.Sale.Handle), [])
    const loading = useFetching(Sale, hasLead, User, hasUsers)
    const sale = useSelector(Sale.select.id(sale_id))
    const users = useSelector(User.select.ordered())

    if (loading || !sale) {
        return <LoadingLayout />
    }

    const editLead = sale => {
        dispatch(Sale.update(Sale.sanitize(sale), sale => {
            history.push(`/sales/${sale.id}`)
        }))
    }

    const deleteLead = () => {
        if (!confirm('Ta bort case?')) {
            return
        }
        dispatch(Sale.destroy(sale_id, () => {
            history.push(`/sales/`)
        }))
    }

    const submit = () => form.current.submitForm()

    return <Layout title={sale.name} headerOkIcon="check" onHeaderOkClick={submit}>
        <SaleBreadcrumbs sale_id={sale.id}>
            <Breadcrumb>Edit</Breadcrumb>
            <BreadcrumbSpacer/>
            <BreadcrumbButton onClick={submit} icon="check" title="Save" variant="success" />
        </SaleBreadcrumbs>
        <View>
            <PageWidth>
            <LeadForm 
                sale={sale}
                users={users}
                onSubmit={editLead}
                onDelete={deleteLead}
                formRef={form}
            />
            </PageWidth>
        </View>
    </Layout>
}

export default LeadEditorView