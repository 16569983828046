import React from 'react'

import StyledRow from './styled/Row'
import Column from './Column'


function Row({
	collapse,
	children,
	padding,
	align,
	justify,
	reverse,
	reset,
	...other
}) {
	return  <StyledRow
		options={{ align, justify, padding, reverse, reset, collapse }}
		{...other}
	>
		{ React.Children.map(children, child => {
			if (child && child.type === Column) return React.cloneElement(child, {
				padding
			})
		})}
	</StyledRow>
}

Row.defaultProps = {
	padding: 'default',
	align: 'start',
	justify: 'between'
}

export default Row
