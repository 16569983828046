import React, { useState } from 'react'

export const DrawerContext = React.createContext({
    opened: '',
    open: key => { },
    close: key => { },
})


export function Drawers({ children, open = '' }) {
    const [opened, setOpened] = useState(open, [open])
    const ctx = {
        opened,
        open: key => setOpened(key),
        close: key => {
            if (key === opened) {
                setOpened('')
            }
        },
    }
    return <DrawerContext.Provider value={ctx}>
        {children}
    </DrawerContext.Provider>
}

export default Drawers
