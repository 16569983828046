import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'


const activeClassName = 'menu-active'


const MenuLink = styled(NavLink).attrs({
	activeClassName,
})`
	color: #f0f0f0;
	text-decoration: none;
	font-size: 0.8rem;
	padding: 8px 12px;
	border-radius: 8px;
	display: block;
	font-weight: bold;
    
    &:hover {
        color: #fff;
		/* background-color: rgba(0,0,0,0.15); */
		text-decoration: none;
    }

	i {
		margin-right: 0.4em;
	}

	@media screen and (max-width: 32em) {
		font-size: 1.0rem;
		line-height: 2.5em;
		text-transform: inherit;
	}

	&.${props => props.activeClassName} {
		background-color: rgba(0,0,0,0.1);
		color: #fff;
	}
`

export default MenuLink
