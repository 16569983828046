import _ from 'lodash'
import React from 'react'
import { Order, useAction, useSelector, useFetching } from '../../store'
import { formatFullDate, formatCurrency } from '../../util/format'
import { Spinner, Link, Table, THead, TBody, Tr, Th, Td, Ts } from '../ui'


function CustomerOrderList({ customer_id }) {
    useAction(Order.getByCustomer(customer_id))
    const loading = useFetching(Order)
    const orders = useSelector(Order.select.ordered())

    if (loading) {
        return <Spinner />
    }

    return <Table full-width>
        <THead>
            <Tr>
                <Th><Ts>Reference</Ts></Th>
                <Th><Ts>Time</Ts></Th>
                <Th><Ts>Source</Ts></Th>
                <Th right><Ts>Discount</Ts></Th>
                <Th right><Ts>Tax</Ts></Th>
                <Th right><Ts>Total</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(orders, CustomerOrderItem)}
        </TBody>
    </Table>
}

function CustomerOrderItem({ id, currency, reference, source, time, discount, tax, total }) {
    const url = `/order/${id}`
    const date = formatFullDate(new Date(time))

    return <Tr key={id}>
        <Td><Link to={url}>{reference}</Link></Td>
        <Td><Link to={url}>{date}</Link></Td>
        <Td><Link to={url}>{source}</Link></Td>
        <Td right><Link to={url}>{formatCurrency(discount, currency)}</Link></Td>
        <Td right><Link to={url}>{formatCurrency(tax, currency)}</Link></Td>
        <Td right><Link to={url}>{formatCurrency(total, currency)}</Link></Td>
    </Tr>
}

export default CustomerOrderList
