import React from 'react'
import { Link } from '../'
import StyledLogo from './styled/Logo'
import LogoImg from '../../../../img/logo-white.svg'

export const Logo = props => {
    return <StyledLogo>
        <Link to="/">
            <img src={LogoImg} alt="JS Energi" style={{display:'block'}} />
        </Link>
    </StyledLogo>
}

export default Logo