import styled from 'styled-components/macro'

const MapFilters = styled.div`
    display: flex;
    padding: 6px;
    background-color: #fff;
	box-shadow: 0rem 0rem 0.5rem 0rem rgba(0,0,0,0.3);
    z-index: 999;

    overflow-x: auto;

    button {
        margin-right: 0.5rem;
    }
`

export default MapFilters
