import React from 'react'
import { useHistory } from 'react-router'
import { Layout, View, Breadcrumbs, Breadcrumb, Fence, LoadingLayout, BreadcrumbSpacer, BreadcrumbButton, PageWidth } from '../ui'
import { User, useAction, useFetching, useSelector, Group } from '../../store'
import { Permission } from '../../const'
import UserList from './UserList'


function UserListView() {
    const history = useHistory()
    const hasUsers = useAction(User.all())
    const hasGroups = useAction(Group.all())
    const loading = useFetching(User, hasUsers, Group, hasGroups)
    const users = useSelector(User.select.ordered())
    if (loading) {
        return <LoadingLayout />
    }

    const newUser = e => history.push('/users/new')

    return <Layout headerOkIcon="plus" onHeaderOkClick={newUser} title="Users" permission={Permission.User.New}>
        <Breadcrumbs>
            <Breadcrumb url="/users">Users</Breadcrumb>
            <BreadcrumbSpacer />
            <Fence permission={Permission.User.New}>
                <BreadcrumbButton icon="user" title="New User" onClick={newUser} variant="success" />
            </Fence>
        </Breadcrumbs>
        <View name="users">
            <PageWidth>
                <UserList users={users} />
            </PageWidth>
        </View>
    </Layout>
}

export default UserListView