import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { STATES, STATE_NEW, StateNames, StateIcons, StateOrder } from '../../const/sale'
import { StyledBoard } from './styled'
import InfiniteColumn from './InfiniteColumn'
import { nextActivity } from './card/ActivityInfo'
import Card from './card/Card'
import TodoCard from './card/TodoCard'
import NewCard from './card/NewCard'

export let CreatedTime = sale => moment(sale.created_at).unix()

export function NextActivityTime(sale) {
    const activity = nextActivity(sale)
    return moment(activity.scheduled_at).unix()
}

function Board({ query }) {
    return <StyledBoard>
        <InfiniteColumn
            key="todo"
            state="todo"
            name="Todo"
            icon="check"
            query={{ ...query, todo: true }}
            defaultOrder="asc"
            sortColumn="activities.scheduled_at"
            render={TodoCard}
        />
        {_.map(STATES, state => (
            <InfiniteColumn
                key={state}
                state={state} 
                name={StateNames[state]}
                icon={StateIcons[state]}
                query={query}
                sortColumn="created_at"
                defaultOrder={StateOrder[state] || 'desc'}
                render={state == STATE_NEW ? NewCard : Card} 
            />
        ))}
    </StyledBoard>
}

export default Board
