import React from 'react'
import { Redirect } from 'react-router'


export function Home() {
    if (window.innerWidth <= 512) {
        return <Redirect to="/menu" />
    } else {
        return <Redirect to="/customers" />
    }
}

export default Home