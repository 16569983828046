import React from 'react'
import { Sale, useFetching, useSelector } from '../../store'
import { Breadcrumbs, Breadcrumb } from '../ui'


export function SaleBreadcrumbs({ sale_id, children }) {
    const sale = useSelector(Sale.select.id(sale_id))
    const loading = !sale || useFetching(Sale)
	const name = loading ? `$${sale_id}` : sale.name

    return <Breadcrumbs>
        <Breadcrumb url="/sales">Sales</Breadcrumb>
        <Breadcrumb url={`/sales/${sale_id}`}>{name}</Breadcrumb>
        {children}
    </Breadcrumbs>
}

export default SaleBreadcrumbs