import moment from 'moment'
import dateFormat from 'dateformat';

// format strings
const timestamp        = "HH:MM";
const fullDateWithYear = "d mmmm yyyy, " + timestamp;
const fullDateNoYear   = "d mmmm, " + timestamp;
const fullDateNoTime   = "d mmmm yyyy"
const dateFmt = "yyyy-mm-dd"

export default formatFullDate;

export function formatFullDate(time, alwaysIncludeYear = true) {
    var fmtString = fullDateWithYear;

    // dont include year if its the current year
    const now = new Date();
    if (!alwaysIncludeYear && time.getFullYear() === now.getFullYear()) {
        fmtString = fullDateNoYear;
    }

    return dateFormat(time, fmtString);
}

export function formatDateNoTime(time) {
    return dateFormat(time, fullDateNoTime)
}

export function formatDate(time) {
    return dateFormat(time, dateFmt)
}

export function formatDateTime(time) {
    time = moment(time)
    if (time.hours() <= 2) {
        // middle of the night - this means the exact time is missing.
        return formatDate(time)
    }
    return dateFormat(time, dateFmt + " " + timestamp)
}

export function formatYearMonth(time) {
    return dateFormat(time, "yyyy-mm")
}

export function formatTime(time) {
    return dateFormat(time, timestamp);
}

export function formatCurrency(value = 0, currency) {
    return value.toFixed(0).replace(/./g, function(c, i, a) {
        return i && c !== "." && ((a.length - i) % 3 === 0) ? ' ' + c : c;
    }) + (currency ? ' ' + currency : '');
}

export function getWeekdayName(dayOfWeek) {
    // if dayOfWeek is not set, use current day
    if (typeof(dayOfWeek) === 'undefined')
        dayOfWeek = (new Date()).getDay();

    switch(dayOfWeek) {
    case 0: return "sunday";
    case 1: return "monday";
    case 2: return "tuesday";
    case 3: return "wednesday";
    case 4: return "thursday";
    case 5: return "friday";
    case 6: return "saturday";
    default: return "unknown";
    }
}
