import _ from 'lodash'
import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, View, Breadcrumb, LoadingLayout } from '../../ui'
import { Sale, Activity, useDispatch, useAction, useFetching } from '../../../store'
import SaleBreadcrumbs from '../SaleBreadcrumbs'
import ActivityForm from './ActivityForm'
import { getLocalUser } from '../../../util/jwt'


export function NewActivityView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id } = useParams()
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const loading = useFetching(Sale, hasSale)
    const me = getLocalUser()
    if (loading) {
        return <LoadingLayout />
    }

    const midnight = new Date()
    midnight.setHours(8,0,0,0)
    const activity = {
        activity_type: 'visit',
        scheduled_at: midnight,
        user_id: me.id,
        sale_id,
    }
    
    const backUrl = `/sales/${sale_id}`
    const createActivity = activity => {
        dispatch(Activity.create(Activity.sanitize(activity), () => {
            history.push(backUrl)
        }))
    }

    return <Layout title="New Activity" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()} backUrl={backUrl}>
        <SaleBreadcrumbs sale_id={sale_id}>
            <Breadcrumb>New Activity</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <ActivityForm sale_id={sale_id} activity={activity} onSubmit={createActivity} formRef={form} />
        </View>
    </Layout>
}

export default NewActivityView