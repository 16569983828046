import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2'
import _ from 'lodash';

import ChartWrapper from './styled/ChartWrapper'
import ChartTitle from './styled/ChartTitle'
import { Spinner } from '../ui'
import { getWeeklyStats } from '../../store/actions/stats'

class WeekChart extends Component {
    static defaultProps = {
        statDisplay: 'sales',
        emaAlpha: 0.9,
        scaleFactor: 1,
        color: 'black',
        background: 'gray',
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(getWeeklyStats())
    }

    render() {
        const { stats, loading, title, statDisplay, scaleFactor, emaAlpha, color, small } = this.props

        if (loading) {
            return <Spinner />
        }

        let options = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: small ? 10 : 20,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.0)",
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontSize: small ? 10 : 30,
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0.085)",
                    }
                }]
            },
            legend: { display: false },
        }

        let sales = []
        let labels = []
        let moving = []
        let avgsum = 0
        _.each(stats, stat => {
            labels.push(stat.week)

            let sale = stat[statDisplay] * scaleFactor
            sales.push(sale)

            // EMA
            if (moving.length === 0) {
                avgsum = sale
            } else {
                avgsum = avgsum * emaAlpha + sale * (1 - emaAlpha)
            }
            moving.push(avgsum)
        })

        let data = {
            labels: labels,
            datasets: [
                {
                    label: 'Försäljning',
                    data: sales,
                    borderColor: color, // 'rgba(149, 29, 34, 1)',
                    backgroundColor: 'rgba(0, 29, 34, 0.05)',
                    borderWidth: small ? 3 : 8,
                    pointRadius: small ? 2 : 4,
                },
                {
                    label: 'EMA',
                    data: moving,
                    borderColor: 'rgba(100, 100, 100, 0.15)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: small ? 2 : 4,
                    pointRadius: small ? 1 : 2,
                },
            ],
        }

        const height = small ? 45 : 55
        return <ChartWrapper small={small}>
            <Line data={data} options={options} width={100} height={height} />
            <ChartTitle small={small}>{title}</ChartTitle>
        </ChartWrapper>
    }
}

const mapStateToProps = state => ({
    loading: _.get(state, ['stats', 'weekly', 'loading'], true),
    stats: _.get(state, ['stats', 'weekly', 'stats'], [ ]), 
})
export default connect(mapStateToProps)(WeekChart)
