import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { File, useAction, useFetching, useSelector } from '../../store'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Spinner } from '../ui'
import { useParams } from 'react-router-dom'

const GalleryWrapper = styled.div`
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
`

const GalleryEntry = styled.div`
    background-color: #222;
    border: 0.4rem solid #222;
    border-radius: 0.4rem;

    margin-bottom: 2rem;
    width: fit-content;
    max-width: 100%;
    
    label {
        display: block;
        font-size: 0.9em;
        font-family: monospace;
        color: #eee;
    }
`

const GalleryImage = styled.img`
    display: block;
    align-self: flex-start;
    max-width: 100%;
`


export function GalleryView({ }) {
    const { sale_id } = useParams()
    const hasComments = useAction(File.getBySale(sale_id), [sale_id])
    const images = useSelector(File.select.where(c => c.type === 'image'))
    const loading = useFetching(File, hasComments)

    if (loading) {
        return <Spinner />
    }

    if (!images.length) {
        return <p style={{padding:'1em'}}>No images to show</p>
    }

    return <GalleryWrapper>
        <h2>${sale_id}</h2>
        {_.map(images, image => 
            <GalleryEntry>
                <label>{image.name}</label>
                <TransformWrapper>
                    <TransformComponent>
                        <GalleryImage src={image.url} />
                    </TransformComponent>
                </TransformWrapper>
            </GalleryEntry>
        )}
    </GalleryWrapper>
}

export default GalleryView
