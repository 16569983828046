/*global google*/
import React from 'react'
import _ from 'lodash'
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps'
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer"

import { Spinner } from '../'

const OFFICE_COORDS = { lat: 56.063143, lng: 12.714985 }

const BaseMap = withScriptjs(
    withGoogleMap(
        props => {
            const markers = props.markers || [ ]
            const heatmap = _.map(props.heatmap || [ ], p => new google.maps.LatLng(p))

            const renderMarker = (marker, i) => {
                return <Marker key={i} {...marker}
                    onClick={() => props.onMarkerClick(marker)}
                    onRightClick={() => props.onMarkerRightClick(marker)}
                />
            }
            return <GoogleMap
                ref={props.onMapLoad}
                defaultZoom={props.zoom}
                defaultCenter={props.center}
                onClick={props.onMapClick}
            >
                {markers.map(renderMarker)}
                <HeatmapLayer data={heatmap} options={{radius: 1, dissipating: false, 
                }} />
            </GoogleMap>
        }
    )
)

const MapsLibraries = ['visualization']
const LibString = '&libraries=' + MapsLibraries.join(',')
const MapsAPIKey = "AIzaSyCD-9ZWrWx3ui-2BYkUT_ZdGmoQc7FIqPk"

const MapsUrl = "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" + MapsAPIKey + LibString

class Map extends React.Component {
    static defaultProps = {
        // events
        onClick: _.noop,
        onRightClick: _.noop,
        onClickMarker: _.noop,
        onRightClickMarker: _.noop,

        height: '100%',
        markets: [ ],
        center: OFFICE_COORDS,
        zoom: 3,
    }

    render() {
        const { 
            markers, heatmap, zoom, center, height,
            onClick, onRightClick, 
            onClickMarker, onRightClickMarker
        } = this.props

        const getContainer = () => {
            const maxHeightStyle = { 
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                height,
            }
            return <div style={maxHeightStyle} />
        }
        const getLoadingElement = () => {
            return <Spinner />
        }

        return <BaseMap
            googleMapURL={MapsUrl}
            loadingElement={getLoadingElement()}
            containerElement={getContainer()}
            mapElement={getContainer()}

            onMapClick={onClick}
            onRightClick={onRightClick}
            onMarkerClick={onClickMarker}
            onMarkerRightClick={onRightClickMarker}

            markers={markers}
            heatmap={heatmap}
            center={center}
            zoom={zoom}
        />
    }
}

export default Map
