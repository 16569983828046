import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router'
import { Grid, ButtonRow, Icon, Ts } from '../ui'
import { Button } from 'react-bootstrap'


function NewFeedItemMenu({ sale }) {
    const history = useHistory()
    const { id } = sale

    const PostLinkButton = ({ title, icon, type, ...props }) => {
        const onClick = () => history.push(`/sales/${id}/${type}`)
        return <Button variant="secondary" onClick={onClick} {...props}>
            <Icon name={icon} />
            <Ts>{title}</Ts>
        </Button>
    }

    return <Grid>
        <ButtonRow>
            <PostLinkButton type="edit" title="Edit" icon="pencil" variant="primary" />
            <PostLinkButton type="comment" title="Comment" icon="comment" />
            <PostLinkButton type="image" title="Image" icon="camera" />
            <PostLinkButton type="file" title="File" icon="file" />
            <PostLinkButton type="activity" title="Activity" icon="car" />
        </ButtonRow>
    </Grid>
}

export default NewFeedItemMenu