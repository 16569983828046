import { CreateModel, } from '../../model'
import { apiGet } from '../../api'


export const Job = CreateModel({
    id: 'job',
    url: 'job',
}, (actions, endpoint) => ({
    getByCustomer: apiGet({
        url:    id => `job/customer/${id}`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
}))

export default Job