import React from 'react'
import styled from 'styled-components/macro'
import { Link } from '../link'
import { Icon } from '../icon'

export const ListItem = styled.li`
    padding: 12px 24px;
`

export const ListLinkItem = styled(ListItem)`
    padding: 0;
`

const StyledListLink = styled(({ ...other }) => <Link {...other} />)`
    display: block;
    padding: 12px 24px;
    color: #333;

    i.fa {
        margin-right: 0.75em;
    }

    &:hover {
		background-color: #eaeaea;
    }

    @media screen and (max-width: 32em) {
        padding: 16px;
        font-size: 18px;
    }
`

export function ListLink({ icon, children, ...props }) {
    return <ListLinkItem>
        <StyledListLink {...props}>
            {icon && <Icon name={icon} />}
            {children}
        </StyledListLink>
    </ListLinkItem>
}

ListLink.defaultProps = {
    color: 'default',
}

export default ListItem