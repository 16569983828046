import _ from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import { Ts, ts } from '../ui'


export function Textarea({ label, rows, placeholder, onChange, innerRef, ...props }) {
    const [field, meta] = useField(props)
    return <React.Fragment>
        <Form.Control 
            as="textarea"
            rows={rows}
            isValid={meta.touched && !meta.error}
            isInvalid={meta.error}
            placeholder={ts(placeholder || label)}
            ref={innerRef}
            {...field}
            {...props}
            value={field.value || ''} 
            onChange={e => {
                field.onChange(e)
                if (_.isFunction(onChange)) {
                    onChange(e.currentTarget.value)
                }
            }}
        />
        {meta.error && <Form.Control.Feedback type="invalid"><Ts>{''+meta.error}</Ts></Form.Control.Feedback>}
    </React.Fragment>
}

Textarea.defaultProps = {
    rows: 4,
}

export default Textarea
