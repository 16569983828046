import _ from "lodash";

export const PumpType = {
    LuftLuft: "luft_luft",
    LuftVatten: "luft_vatten",
    VatskaVatten: "vatska_vatten",
    Franluft: "franluft",
};

export const PumpTypes = [
    {
        type: PumpType.LuftLuft,
        title: "Luft/Luft",
        color: "#75dddd",
        unit: "kWh",
    },
    {
        type: PumpType.LuftVatten,
        title: "Luft/Vatten",
        color: "#007ea7",
        unit: "kWh",
    },
    {
        type: PumpType.VatskaVatten,
        title: "Vätska/Vatten",
        color: "#00a780",
        unit: "kWh",
    },
    {
        type: PumpType.Franluft,
        title: "Frånluft",
        color: "#d1495b",
        unit: "kWh",
    },
];

export const Offers = [
    ...PumpTypes,

    { type: "service_avtal", title: "Serviceavtal", color: "#a049d1" },
];

export function GetOfferTypeById(type) {
    const t = _.find(Offers, (t) => t.type === type);
    if (!t) {
        return {
            type: "",
            title: "Okänd",
            color: "#000000",
            unit: "kWh",
        };
    }
    return t;
}

export const HeatingTypes = [
    { type: "", title: "Okänd", color: "#000000", unit: "kWh" },
    ...PumpTypes,
    { type: "elradiator", title: "Elradiator", color: "#f58f29", unit: "kWh" },
    { type: "oljepanna", title: "Oljepanna", color: "#171738", unit: "m³" },
    { type: "vedpanna", title: "Vedpanna", color: "#d36135", unit: "m³" },
    { type: "gaspanna", title: "Gaspanna", color: "#09bc8a", unit: "m³" },
    { type: "elpanna", title: "Elpanna", color: "#000000", unit: "kWh" },
    { type: "pellets", title: "Pellets", color: "#4f3130", unit: "ton" },
    { type: "fjarrvarme", title: "Fjärrvärme", color: "#00a780", unit: "kWh" },
];

export function GetHeatingTypeById(type) {
    const t = _.find(HeatingTypes, (t) => t.type === type);
    if (!t) {
        return HeatingTypes[0];
    }
    return t;
}

export default HeatingTypes;
