import _ from 'lodash'
import React from 'react'
import { getLocalUser } from '../../../util/jwt'
import StyledMenu from './styled/Menu'
import MenuItem from './MenuItem'
import MenuToggle from './MenuToggle'
import menuItems from '../../../const/menu'


class Menu extends React.Component {
    constructor() {
        super()
        this.state = { expanded: false }

        this.toggle = this.toggle.bind(this)
        this.closeIfOpen = this.closeIfOpen.bind(this)
        this.clickOutside = this.clickOutside.bind(this)
    }

    toggle() {
        const { expanded } = this.state
        if (!expanded) {
            document.addEventListener('click', this.clickOutside)
        }
        else {
            document.removeEventListener('click', this.clickOutside)
        }
        this.setState({ expanded: !expanded })
    }

    clickOutside(e) {
        this.closeIfOpen()
        e.preventDefault()
    }

    closeIfOpen() {
        const { expanded } = this.state
        if (expanded) {
            this.toggle()
        }
    }

    render() {
        const user = getLocalUser()
        const { expanded } = this.state

        const renderMenuItem = (menuItem, i) => {
            if (menuItem.access) {
                if (!_.has(user.access, menuItem.access)) {
                    return null
                }
            }
            return <MenuItem key={i} {...menuItem} expanded={expanded} onClick={this.closeIfOpen}/>
        }
        return <StyledMenu>
            <MenuToggle onClick={this.toggle} expanded={expanded} />
            {menuItems.map(renderMenuItem)}
        </StyledMenu>
    }
}

export default Menu