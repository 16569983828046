export const THROW_ERROR = 'THROW_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export function throwError({ message, status, redirect = undefined }) {
    return {
        type: THROW_ERROR,
        status,
        message,
        redirect,
    }
}

export function clearErrors() {
    return { type: CLEAR_ERRORS }
}
