import _ from "lodash";
import React from "react";
import styled from "styled-components/macro";
import { Button } from "react-bootstrap";
import {
    Grid,
    Row,
    Column,
    RowColumn,
    Header,
    FSpan,
    PhoneLink,
    EmailLink,
    Ts,
    Markdown,
    Spacer,
    Icon,
    ButtonRow,
    Drawer,
    Drawers,
} from "../../ui";
import { SourceTypes } from "../../../const";
import UserLink from "../../user/UserLink";
import DirectionsButton from "./DirectionsButton";
import TypeBadge from "../../sales/card/TypeBadge";
import CrmFields, {
    CrmFieldDisplay,
    FieldTable,
    FieldTableRow,
} from "./CrmFields";
import { Formik, Form } from "formik";

function CallButton({ phone }) {
    return (
        <Button href={`tel:${phone}`} variant="secondary">
            <Icon name="phone" />
            <span>{phone}</span>
        </Button>
    );
}

function EmailButton({ email }) {
    return (
        <Button href={`mailto:${email}`} variant="secondary">
            <Icon name="envelope-o" />
            <span>{email}</span>
        </Button>
    );
}

const OtherInfoBox = styled.div`
    padding: 1rem 1rem;
    line-height: 1.5em;
    background-color: #f3f3f3;
    min-height: 40px;
`;

const BigHidden = styled.div`
    display: none !important;
    @media screen and (max-width: 32em) {
        display: block !important;
    }
`;

export function LeadDisplay({ sale }) {
    return (
        <Grid>
            <Row>
                <Column size={6}>
                    <TypeBadge
                        code={sale.desired_type}
                        style={{ fontSize: "1em" }}
                    />
                </Column>
                <Column size={6}>
                    <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                        <UserLink user={sale.owner} />
                    </div>
                </Column>
            </Row>

            {sale.other && (
                <RowColumn>
                    <Header size="small">
                        <Ts>Notes</Ts>
                    </Header>
                    <OtherInfoBox>
                        <Markdown source={sale.other} />
                    </OtherInfoBox>
                </RowColumn>
            )}

            <BigHidden>
                <Spacer />
                <ButtonRow>
                    {sale.phone && <CallButton phone={sale.phone} />}
                    {sale.email && <EmailButton email={sale.email} />}
                    <DirectionsButton sale={sale} />
                </ButtonRow>
            </BigHidden>

            <RowColumn>
                <Drawers open="customer">
                    <Formik initialValues={sale}>
                        <Form>
                            <Drawer name="customer" title="Customer">
                                <FieldTable>
                                    <tbody>
                                        <FieldTableRow label="Source">
                                            <FSpan
                                                value={sale.source}
                                                valueMap={SourceTypes}
                                            />
                                        </FieldTableRow>
                                        <FieldTableRow label="Hur hittade ni oss?">
                                            <FSpan value={sale.found_us} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Name">
                                            <FSpan value={sale.name} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Email">
                                            <EmailLink value={sale.email} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Phone">
                                            <PhoneLink value={sale.phone} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Company">
                                            <FSpan value={sale.company} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Personal number">
                                            <FSpan value={sale.pno} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Street">
                                            <FSpan value={sale.street} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Postcode">
                                            <FSpan value={sale.postcode} />
                                        </FieldTableRow>
                                        <FieldTableRow label="City">
                                            <FSpan value={sale.city} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Fastighetsbeteckning">
                                            <FSpan value={sale.property_id} />
                                        </FieldTableRow>
                                        <FieldTableRow label="Befintlig värmepump">
                                            <FSpan
                                                value={sale.current_heatpump}
                                            />
                                        </FieldTableRow>
                                        <FieldTableRow label="Affärsvärde exkl. moms utan rot">
                                            <FSpan value={sale.value} />
                                        </FieldTableRow>
                                    </tbody>
                                </FieldTable>
                            </Drawer>

                            <CrmFields render={CrmFieldDisplay} />
                        </Form>
                    </Formik>
                </Drawers>
            </RowColumn>
        </Grid>
    );
}

export default LeadDisplay;
