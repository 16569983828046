import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Table, Tr, Th, Td, THead, TFoot, TBody } from '../../ui'

const SummaryWrapper = styled.div`
    padding-bottom: 1.0rem;
`

export function OrderSummary({ order, customer }) {
    const { items, currency, discount, shipping, total } = order

    return <SummaryWrapper>
        <Table>
            <THead>
                <Tr>
                    <Th>Product</Th>
                    <Th number>Tax</Th>
                    <Th number>Price</Th>
                </Tr>
            </THead>
            <TBody>
                {_.map(items, (item, i) => {
                    const { name, qty, tax, price } = item
                    return <Tr key={i}>
                        <Td>{qty}x {name}</Td>
                        <Td number>{tax * qty} {currency}</Td>
                        <Td number>{price * qty} {currency}</Td>
                    </Tr>
                })}
            </TBody>
            <TFoot>
                {shipping > 0 && <Tr>
                    <Td colSpan="2">Shipping</Td>
                    <Td number>{shipping} {currency}</Td>
                </Tr>}
                {discount > 0 && <Tr>
                    <Td colSpan="2">Discount</Td>
                    <Td number>-{discount} {currency}</Td>
                </Tr>}
                <Tr>
                    <Th colSpan="2">Total</Th>
                    <Th number>{total} {currency}</Th>
                </Tr>
            </TFoot>
        </Table>
    </SummaryWrapper>
}

OrderSummary.defaultProps = {
    order: { 
        items: [ ],
    },
}

export default OrderSummary