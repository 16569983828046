import styled from 'styled-components/macro'

const Main = styled.div`
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export default Main
