import _ from 'lodash'
import React from 'react'
import { sortByCreatedTime } from './Feed'
import Post from './Post'
import EmptyFeed from './EmptyFeed'


export function DocumentFeed({ sale, editable, documents }) {
    const history = [
        ...documents,
    ].sort(sortByCreatedTime)

    // empty feed message
    if (!history.length) {
        return <EmptyFeed />
    }

    return _.map(history, post => <Post key={`${post.type}_${post.id}`} {...post} editable={editable} />)
}

export default DocumentFeed