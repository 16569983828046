import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumb } from '../../ui'
import { Comment, Sale, useDispatch, useFetching, useAction, useSelector } from '../../../store'
import CommentForm from './CommentForm'
import SaleBreadcrumbs from '../SaleBreadcrumbs'


export function NewCommentView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id, comment_id } = useParams()
    const hasComment = useAction(Comment.get(comment_id), [comment_id])
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const loading = useFetching(Comment, Sale, hasComment, hasSale)
    const comment = useSelector(Comment.select.id(comment_id))
    if (loading) {
        return <LoadingLayout />
    }

    const backUrl = `/sales/${sale_id}`
    const updateComment = comment => {
        dispatch(Comment.update(Comment.sanitize(comment), () => {
            history.push(backUrl)
        }))
    }

    return <Layout title="Edit Comment" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()} backUrl={backUrl}>
        <SaleBreadcrumbs sale_id={sale_id}>
            <Breadcrumb url={`/sales/${sale_id}/comment/${comment_id}`}>Edit Comment</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <CommentForm sale_id={sale_id} comment={comment} onSubmit={updateComment} formRef={form} />
        </View>
    </Layout>
}

export default NewCommentView