import styled from 'styled-components/macro'

export const FooterMenu = styled.div`
    display: none;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: #222222;
    flex-direction: row;

    @media screen and (max-width: 32em) {
        display: flex;
    }
`

export default FooterMenu