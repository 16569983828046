import React from 'react'
import { useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumb } from '../ui'
import SaleBreadcrumbs from './SaleBreadcrumbs'
import NewFeedItemMenu from './NewFeedItemMenu'
import { Sale, useAction, useFetching, useSelector } from '../../store'


export function NewSalePostView({ }) {
    const { sale_id } = useParams()
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const loading = useFetching(Sale, hasSale)
    const sale = useSelector(Sale.select.id(sale_id))
    if (loading) {
        return <LoadingLayout />
    }

    return <Layout title="Add">
        <SaleBreadcrumbs sale_sale_id={sale_id}>
            <Breadcrumb url={`/sales/${sale_id}/post`}>New Post</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <NewFeedItemMenu sale={sale} />
        </View>
    </Layout>
}

export default NewSalePostView