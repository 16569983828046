import _ from 'lodash'
import React from 'react'
import { Link, Tr, Td } from '../ui'
import GroupLink from './GroupLink'


function UserListItem({ user }) {
    const { id, name, email, groups } = user
	const href = `/users/${id}`

    return <Tr>
        <Td>
            <Link to={href}>{name}</Link>
        </Td>
        <Td>
            <Link to={href}>{email}</Link>
        </Td>
        <Td>
            {_.map(groups, group => <GroupLink key={group.id} id={group.id} />)}
        </Td>
    </Tr>
}

export default UserListItem
