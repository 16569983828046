import _ from 'lodash'
import React from 'react'
import { Form, Col } from 'react-bootstrap'
import { getDateOfISOWeek } from '../../util/week'


export function WeekSelector({ year, setYear, week, setWeek }) {
    const changeWeek = e => setWeek(e.target.value)
    const changeYear = e => setYear(e.target.value)

    const isPast = week => {
        var weekDate = getDateOfISOWeek(week, year)
        return weekDate < today
    }

    const today = new Date()
    const weeks = _.range(52, 1, -1).filter(isPast)
    const years = _.range(today.getFullYear(), 2016, -1)

    return <Form.Row>
        <Col>
            <Form.Control as="select" onChange={changeYear} value={year}>
                {_.map(years, year => <option key={year} value={year}>{year}</option>)}
            </Form.Control>
        </Col>
        <Col>
            <Form.Control as="select" onChange={changeWeek} value={week}>
                {_.map(weeks, week => <option key={week} value={week}>v{week}</option>)}
            </Form.Control>
        </Col>
    </Form.Row>
}

export default WeekSelector