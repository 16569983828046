import styled from 'styled-components/macro'

export const StatsTable = styled.table`
    width: 100%;
    padding: 0.5rem 0;

    td, th {
        border-top: none;
        padding: 0.5rem;

        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
    }

    th {
        vertical-align: middle;
    }

    td, input {
        text-align: right;
    }

    .sum td, .sum th { font-weight: bold; }

    .form-group,
    .mb-3 {
        margin-bottom: 0 !important;
    }
`