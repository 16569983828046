import _ from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumbs, Breadcrumb, Icon } from '../ui'
import { Sale, useAction, useFetching, useSelector } from '../../store'
import { CardList, EmptySalesLabel } from './styled'
import Card from './card/Card'
import SalesFilterBar from './SalesFilterBar'


export function ExpandedSalesView({ }) {
    const { state } = useParams()
    const [query, setQuery] = useState({ state })
    const hasSales = useAction(Sale.search(query), [query])
    const sales = useSelector(Sale.select.all())
    const loading = useFetching(Sale, hasSales)

    if (loading) {
        return <LoadingLayout />
    }

    const empty = sales.length === 0
    return <Layout title={state}>
        <Breadcrumbs>
            <Breadcrumb url='/sales'>Sales</Breadcrumb>
            <Breadcrumb url={'/sales/state/'+state}>{state}</Breadcrumb>
        </Breadcrumbs>
        <SalesFilterBar query={query} setQuery={setQuery} />
        <View name="sales-expanded" background="#f4f4f4">
            <CardList>{_.map(sales, sale => <Card sale={sale} key={sale.id} />)}</CardList>
            {empty && <EmptySalesLabel>
                <span>Här var det tomt... <Icon name="frown-o" /></span>
            </EmptySalesLabel>}
        </View>
    </Layout>
}

export default ExpandedSalesView