import _ from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import { Ts, ts } from '../ui'


export function Select({ label, options, children, optionKey, optionValue, skipValidate, ...props }) {
    const [field, meta] = useField(props)
    // reduce array of objects into key-value object
    if (_.isArray(options)) {
        if (!optionKey || !optionValue) {
            throw new Error("Key and Value props must be set to pass option array")
        }
        options = _.reduce(options, (r, option) => _.set(r, option[optionKey], option[optionValue]), { })
    }

    return <React.Fragment>
        <Form.Control 
            as="select"
            isValid={!skipValidate && meta.touched && !meta.error}
            isInvalid={!skipValidate && meta.error}
            {...field}
            {...props}
            value={field.value || ''}
        >
            {children}
            {_.map(options, (label, value) => <option key={value} value={value}>{ts(label)}</option>)}
        </Form.Control>
        {meta.error && <Form.Control.Feedback type="invalid"><Ts>{meta.error}</Ts></Form.Control.Feedback>}
    </React.Fragment>
}

Select.Bool= {
    '': '',
    'true': 'Yes',
    'false': 'No',
}

export default Select
