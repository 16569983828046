import { CreateModel, } from '../../model'
import { apiGet } from '../../api'


export const Order = CreateModel({
    id: 'order',
    url: 'order',
}, (actions, endpoint) => ({
    getByCustomer: apiGet({
        url:    id => `customer/${id}/order/`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
    getByRef: apiGet({
        url:    id => `order/ref/${id}`,
        before: actions.fetch,
        after:  actions.store,
        error:  actions.abort,
    }),

    selectRef: (state, ref) => Order.selectOne(state, order => order.reference === ref),
}))

export default Order