import React from 'react'
import { useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumbs, Breadcrumb } from '../ui'
import { Order, useAction, useFetching, useSelector } from '../../store'
import OrderDetails from './OrderDetails'
import OrderItems from './OrderItems'


function OrderView({ }) {
    const { id } = useParams()

    const hasOrder = useAction(Order.get(id), [id])
    const loading = useFetching(Order, hasOrder)
    const order = useSelector(Order.select.id(id))
    if (loading || !order) {
        return <LoadingLayout />
    }

    const { reference } = order
    return <Layout title={`#${reference}`} backUrl={`/customers/${order.customer_id}`}>
		<Breadcrumbs>
			<Breadcrumb>Orders</Breadcrumb>
			<Breadcrumb url={`/order/${id}`}>#{reference}</Breadcrumb>
		</Breadcrumbs>
		<View name="order">
            <OrderDetails order={order} />
            <OrderItems order={order} />
		</View>
	</Layout>
}

export default OrderView
