import React from 'react'
import { Table, TBody, Tr, Th, Td, Ts, Link, Breadcrumbs } from '../ui'
import { GetBrandById } from '../../const'
import CustomerAddress from './CustomerAddress'


function BrandSpan({ id }) {
    const brand = GetBrandById(id)
    if (!brand) {
        return null
    }
    return <span>{brand.name}</span>
}

export function CustomerInfo({ customer }) {
    const { name, email, phone, fortnox, pump_brand, pump_model, pump_year, address } = customer
    return <Table>
        <TBody>
            <Tr>
                <Th>
                    <Ts>Name</Ts>
                </Th>
                <Td>{name || 'N/A'}</Td>
            </Tr>
            <Tr>
                <Th>
                    <Ts>Email</Ts>
                </Th>
                <Td><Link to={`mailto:${email}`}>{email}</Link></Td>
            </Tr>
            <Tr>
                <Th>
                    <Ts>Phone</Ts>
                </Th>
                <Td>{phone}</Td>
            </Tr>
            {!!fortnox && <Tr>
                <Th>
                    <Ts>Fortnox ID</Ts>
                </Th>
                <Td>{fortnox || '0'}</Td>
            </Tr>}
            <Tr>
                <Th>
                    <Ts>Adress</Ts>
                </Th>
                <Td>
                    <CustomerAddress address={address || {}} />
                </Td>
            </Tr>
            <Tr>
                <Th>Värmepump</Th>
                <Td>
                    <BrandSpan id={pump_brand} /> &nbsp;
                    <span>{pump_model}</span>
                    {!!pump_year && <span>&nbsp;({pump_year})</span>}
                </Td>
            </Tr>
        </TBody>
    </Table>
}

CustomerInfo.defaultProps = {
    customer: { },
}

export default CustomerInfo