
import React from 'react'
import styled from 'styled-components/macro'
import UserLink from '../../user/UserLink'


export const OwnerLabel = styled.span`
    flex-grow: 1;
    text-align: right;
    font-size: 0.7em;
    line-height: 1.25rem;

    a {
        color: #555;
        &:hover {
            color: #888;
        }
    }
`

export function SaleOwner({ sale }) {
    if (!sale.owner) {
        return null
    }
    return <OwnerLabel><UserLink user={sale.owner} /></OwnerLabel>
}

export default SaleOwner
