import styled from 'styled-components/macro'
import { ListItem, ListLinkItem } from './ListItem'

export const List = styled.ul`
	${ListItem}:nth-child(odd),
	${ListLinkItem}:nth-child(odd) {
		background-color: #f4f4f4;
	}
`

export default List