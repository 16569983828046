import _ from 'lodash'
import React, {useState} from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Field } from 'formik'
import { Ts, ts } from '../ui'


// react bootstrap - formik adaptors
export function AutocompleteField({ name, label, placeholder, options, labelKey, valueKey, onSearch, ...props }) {
    return <Field name={name}>
        {({ field, meta, form }) => {
            return <Form.Group>
            {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
            {!onSearch && <Typeahead
                id={name}
                name={name}
                defaultSelected={[ field.value ]}
                options={options}
                labelKey={labelKey}
                isValid={meta.touched && !meta.error}
                isInvalid={meta.error}
                placeholder={ts(placeholder || label)}
                {...props}
            />}
            {onSearch && <AsyncTypeahead
                id={name}
                name={name}
                options={options}
                labelKey={labelKey}
                isValid={meta.touched && !meta.error}
                isInvalid={meta.error}
                placeholder={ts(placeholder || label)}
                useCache={false}
                onChange={values => {
                    if (values.length > 0) {
                        let v = values[0]
                        if (valueKey) {
                            if (_.isFunction(valueKey)) {
                                v = valueKey(v)
                            } else {
                                v = v[valueKey]
                            }
                        }
                        console.log(v)
                        form.setFieldValue(field.name, v)
                    }
                }}
                onSearch={onSearch}
                {...props}
            />}
            {meta.error && meta.error[labelKey] && <Form.Control.Feedback type="invalid"><Ts>{meta.error[labelKey]}</Ts></Form.Control.Feedback>}
        </Form.Group>
    }}
    </Field>
}

AutocompleteField.defaultProps = {
    onSearch: false,
}

export default AutocompleteField