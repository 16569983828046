import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import AnimatedNumber from 'react-animated-number'
import { formatCurrency } from '../../util/format'
import { Icon } from '../ui'
import ts from '../../util/ts'

import {
    getWeekStats
} from '../../store/actions/stats'

const GoalPumps = 14
const GoalSales = 400000

const DailySalesTable = styled.table`
    box-sizing: border-box;
    width: 100%;
    font-size: 4.0rem;
    margin: 2.0rem 0;

    .fa-star {
        color: #ffcc00;
    }

    td {
        padding: 1.0rem 0.5rem;
    }
    thead td {
        font-weight: bold;
        border-bottom: 4px solid #ddd;
    }
    tfoot td {
        font-weight: bold;
        border-top: 4px solid #ddd;
    }

    ${props => props.small ? `
        font-size: 1.0rem;
        margin: 0.5rem 0;

        td {
            padding: 8px 12px;
        }
        tbody tr:nth-child(odd) td {
            background-color: #f6f6f6;
        }
        thead td {
            border-bottom: 1px solid #ddd;
        }
        tfoot td {
            border-top: 1px solid #ddd;
        }
    ` : ``}
`
const NumberTd = styled.td`
    text-align: right;
`

const days = [
    'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
];

const EmptyDay = { sales: 0, pumps: 0 }
const EmptyWeek = _.reduce(days, (w, day) => _.set(w, day, EmptyDay), { })

class SalesTable extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(getWeekStats())
    }

    render() {
        const { week, small } = this.props

        return <DailySalesTable small={small}>
            <colgroup>
                <col/>
                <col/>
                <col/>
            </colgroup>
            <thead>
                <tr>
                    <td>v{week.number}</td>
                    <NumberTd>Reservdelar</NumberTd>
                    <NumberTd>Pump</NumberTd>
                </tr>
            </thead>
            <tbody>
                {days.map((day, i) => this.renderDay(i, day, week))}
            </tbody>
            <tfoot>
                <tr>
                    <td>{ts('Sum')}</td>
                    <NumberTd>
                        {week.total.sales >= GoalSales &&
                            <Icon name="star" spin />
                        }
                        <AnimatedNumber
                            value={week.total.sales}
                            duration={1000}
                            stepPrecision={0}
                            formatValue={n => n > 0 ? formatCurrency(n) : ''}
                        />
                    </NumberTd>
                    <NumberTd>
                        {week.total.pumps >= GoalPumps &&
                            <Icon name="star" spin />
                        }
                        {week.total.pumps}
                    </NumberTd>
                </tr>
            </tfoot>
        </DailySalesTable>
    }

    renderDay(i, label, week) {
        const id = label.toLowerCase();
        const day = _.get(week, id, EmptyDay)

        const formatPumps = pumps => {
            if (pumps === 0)
                return '';
            return pumps;
        }

        return <tr key={i}>
            <td>{ts(label.substr(0,3))}</td>
            <NumberTd>
                <AnimatedNumber
                    value={day.sales}
                    duration={1000}
                    stepPrecision={0}
                    formatValue={n => n > 0 ? formatCurrency(n) : ''}
                />
            </NumberTd>
            <NumberTd>
                {formatPumps(day.pumps)}
            </NumberTd>
        </tr>
    }
}

const mapStateToProps = state => ({
    week: _.get(state, ['stats', 'week'], EmptyWeek),
})
export default connect(mapStateToProps)(SalesTable)
