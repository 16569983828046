import React from 'react'
import styled from 'styled-components/macro'
import { SourceTypes } from '../../../const'


export const SourceBadge = ({ source, style = {} }) => {
    if (!source) {
        return null
    }
    const name = SourceTypes[source]
    return <Badge variant="light">{name}</Badge>
}

const Badge = styled.div`
    font-size: 75%;
    padding: .25em .4em;
    line-height: 1;

    color: #666;
`

export default SourceBadge
