import styled from 'styled-components/macro'


export const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

export const LoginContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Logo = styled.img`
    max-width: 200px;
    margin-bottom: 1rem;
`