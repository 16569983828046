import _ from 'lodash'
import { getWsUrl } from '../util/url'
import { getJwt } from '../util/jwt'
import { OrderNotificationAction, StatUpdateAction } from './actions/stats'

export const SOCKET_CONNECTING = 'SOCKET_CONNECTING'
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'

var Socket = null


export function connectSocket(dispatch) {
    const token = getJwt()

    console.log('Websocket connecting...')
    Socket = new WebSocket(getWsUrl())
    dispatch({
        type: SOCKET_CONNECTING,
        socket: Socket,
    })

    Socket.onopen = () => {
        console.log('Websocket connected')
        dispatch({ type: SOCKET_CONNECTED })

        // send auth message
        if (token) {
            Socket.send(JSON.stringify({
                type: 'auth',
                data: {
                    token,
                },
            }))
        }
    }

    Socket.onclose = () => {
        console.log('Websocket closed. Retrying in 5 seconds...')

        // reconnect in 5 sec
        setTimeout(() => {
            connectSocket(dispatch)
        }, 5 * 1000)
    }

    Socket.onerror = e => {
        console.log('Websocket error:', e)
        Socket.close()
    }

    Socket.onmessage = event => {
        const { type, data } = JSON.parse(event.data)
        switch(type) {
        case 'order':
            dispatch(OrderNotificationAction(data))

            // show doge
            if (window.doge) {
                window.doge(data.amount)
            }
            break

        case 'update':
            dispatch(StatUpdateAction(data))
            break

        default:
            console.log("Unhandled websocket message:", type)
            console.debug(data)
            break
        }
    }
}