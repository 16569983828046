import _ from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'
import { ts } from '../ui'


export function CheckField({ label, ...props }) {
    const [field] = useField(props)
    return <Form.Check 
        label={ts(label)}
        checked={field.value || false} 
        {...props}
        {...field}
    />
}

export default CheckField
