import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import { Layout, View, Breadcrumbs, Breadcrumb, BreadcrumbSpacer, BreadcrumbButton } from '../ui'
import { User, useDispatch } from '../../store'
import UserForm from './UserForm'

const DefaultUserValues = { }


function NewUserView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()

    const submitForm = () => form.current.submitForm()
    const submit = user => {
        dispatch(User.create(User.sanitize(user), user => {
            history.push(`/users/${user.id}`)
        }))
    }

    return <Layout title="New User" headerOkIcon="check" onHeaderOkClick={submitForm}>
        <Breadcrumbs>
            <Breadcrumb url="/users">Users</Breadcrumb>
            <Breadcrumb url="/users/new">New User</Breadcrumb>
            <BreadcrumbSpacer />
            <BreadcrumbButton title="Save" icon="check" variant="success" onClick={submitForm} />
        </Breadcrumbs>
        <View name="user">
            <UserForm user={DefaultUserValues} onSubmit={submit} formRef={form} />
        </View>
    </Layout>
}

export default NewUserView