import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Lato:300,400,600,700');
	@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

	html, body, div, span, applet, object, iframe,
	blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		font-family: "Lato", sans-serif !important;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	*{
		box-sizing: border-box;
	}
    html, body, #root {
        height: 100%;
    }

    /* bootstrap fixes */

    button, a {
        i.fa + span {
            margin-left: 0.3em;
        }
    }

    div.container-fluid {
        padding: 0;
    }

	.form-label label {
		padding-bottom: 0.5em;
	}
	
	h1,h2,h3,h4,h5,h6 {
		margin-bottom: 0.5em;
	}
`

export const Layout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export default Layout
