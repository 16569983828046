import React from 'react'
import { Layout } from '../ui/layout'
import { List, ListLink, Ts, View } from '../ui'
import menuItems from '../../const/menu'


export function MenuView() {
    return <Layout title="JS Energi">
        <View name="menu">
            <List>
                {menuItems.map(({ title, icon, href }) => (
                    <ListLink to={href} icon={icon}>
                        <Ts>{title}</Ts>
                    </ListLink>
                ))}
            </List>
        </View>
    </Layout>
}

export default MenuView