import styled from 'styled-components/macro'
import * as Color from '../../../../const/colors'

const SiteHeader = styled.div`
	box-shadow: 0rem 0rem 0.5rem 0rem rgba(0,0,0,0.3);
	background-color: ${Color.Red};
	z-index: 999;

	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	padding: 10px 12px;
`

export default SiteHeader
