import React from 'react'
import styled from 'styled-components/macro'
import { Ts } from '../../ui'


const EmptyMessage = styled.label`
    display: block;
    padding: 1rem;

    color: #999;
    text-align: center;
`


export function EmptyFeed({ }) {
    return <EmptyMessage>
        <Ts>Nothing to show</Ts>
    </EmptyMessage>
}

export default EmptyFeed