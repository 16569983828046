import _ from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumbs, Breadcrumb, Icon, Input } from '../ui'
import { Sale, useAction, useFetching, useSelector, useDispatch } from '../../store'
import { CardList, EmptySalesLabel } from './styled'
import Card from './card/Card'


export function SaleSearchView({ }) {
    const dispatch = useDispatch()
    const { keywords } = useParams()
    const hasSales = useAction(Sale.search({ query: keywords }), [keywords])
    const sales = useSelector(Sale.select.all())
    const loading = useFetching(Sale, hasSales)
    if (loading) {
        return <LoadingLayout />
    }

    const onSearch = keywords => dispatch(Sale.search({ query: keywords }))

    const empty = sales.length === 0
    return <Layout title="Search" backUrl="/sales">
        <Breadcrumbs>
            <Breadcrumb url="/sales">Sales</Breadcrumb>
            <Breadcrumb url="/sales/search">Search</Breadcrumb>
        </Breadcrumbs>
        <View name="sales-expanded">
            <Form.Control type="text" search placeholder="Sök i CRM..." onReturn={onSearch} value={keywords} />
            <CardList>
                {sales.map(sale => <Card key={sale.id} sale={sale} />)}
            </CardList>
            {empty && <EmptySalesLabel>
                <span>Här var det tomt... <Icon name="frown-o" /></span>
            </EmptySalesLabel>}
        </View>
    </Layout>
}

export default SaleSearchView
