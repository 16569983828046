import _ from 'lodash'
import React from 'react'
import { Table, THead, TFoot, TBody, Tr, Th, Td, Ts } from '../ui'
import OrderItem from './OrderItem'
import { formatCurrency } from '../../util/format'

function OrderItems({ order }) {
    const { items, currency } = order
    const discount = formatCurrency(order.discount, currency)
    const shippingAmount = formatCurrency(order.shippingAmount, currency)
    const tax = formatCurrency(order.tax, currency)
    const total = formatCurrency(order.total, currency)

	return <Table full-width>
		<THead>
			<Tr>
				<Th><Ts>Item</Ts></Th>
				<Th><Ts>SKU</Ts></Th>
				<Th right><Ts>Qty</Ts></Th>
				<Th right><Ts>Discount</Ts></Th>
				<Th right><Ts>Tax</Ts></Th>
				<Th right><Ts>Price</Ts></Th>
			</Tr>
		</THead>
		<TBody>
			{_.map(items, (item, i) => <OrderItem key={i} item={item} currency={currency} />)}
		</TBody>
		<TFoot>
			<Tr>
				<Td colSpan="5"><Ts>Shipping</Ts></Td>
				<Td right>{shippingAmount}</Td>
			</Tr>
			<Tr>
				<Td colSpan="3"><Ts>Total</Ts></Td>
				<Td right>{discount}</Td>
				<Td right>{tax}</Td>
				<Td right>{total}</Td>
			</Tr>
		</TFoot>
	</Table>
}

OrderItems.defaultProps = {
    order: { },
}

export default OrderItems
