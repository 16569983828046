import React from 'react'
import { Ts, Icon } from '..'
import StyledMenuToggle from './styled/MenuToggle'

function MenuToggle({ expanded, onClick }) {
    if (expanded) {
        return <StyledMenuToggle onClick={onClick}>
            <Icon name="times" />
            <Ts>Close</Ts>
        </StyledMenuToggle>
    }
    else {
        return <StyledMenuToggle onClick={onClick}>
            <Icon name="bars" />
            <Ts>Menu</Ts>
        </StyledMenuToggle>
    }
}

export default MenuToggle
