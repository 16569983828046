import React from 'react'
import StyledView from './styled/View'


function View({ name, children, ...props }) {
    return <StyledView className={name} {...props}>
		{children}
   	</StyledView>
}

export default View
