import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const FooterButtonLink = styled(({ isActive, ...props }) => <Link {...props} />)`
    flex: 1;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: ${props => props.isActive ? '#eee' : '#bbb'};

    padding: 8px;
    padding-bottom: 10px;

    border-bottom: 2px solid ${props => props.isActive ? '#eee' : '#222'};
    
    i.fa {
        font-size: 20px;
        padding-bottom: 4px;
    }

    &:hover {
        color: #eee;
        text-decoration: none;
    }
`

export default FooterButtonLink