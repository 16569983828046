import _ from 'lodash'

//
// selectors
//

// find item by id
export function selectId(store, id) {
    return store.byId[id]
}

export function hasId(store, id) {
    return _.has(store.byId, id.toString())
}

export function selectOne(store, pred) {
    const items = selectWhere(store, pred)
    if (items.length) {
        return items[0]
    }
}

// returns all items in order
export function selectOrdered(store) {
    return store.order.map(id => store.byId[id.toString()])
}

// find items by predicate
// preserves collection order
export function selectWhereOrdered(store, pred) {
    if (!_.isFunction(pred)) {
        throw new Error("Expected predicate function (item) => bool")
    }
    return selectOrdered(store).filter(pred)
}

// iterates over all items in the collection in random order
// useful for filtering etc where order does not matter
export function selectAll(store) {
    return _.values(store.byId)
}

// find items by predicate
// does not preserve order
export function selectWhere(store, pred) {
    if (!_.isFunction(pred)) {
        throw new Error("Expected predicate function (item) => bool")
    }
    return selectAll(store).filter(pred)
}