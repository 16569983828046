import React from 'react'
import Page from './styled/Page'
import { RowColumn, Header } from '../ui'
import logoImg from '../../../img/js_logo_text_small.png'

export function InvoicePaymentPage({ description, children }) {
    return <Page>
        <RowColumn>
            <img src={logoImg} alt="JS Energi" />
        </RowColumn>
        <RowColumn>
            <Header>{description}</Header>
        </RowColumn>
        {children}
    </Page>
}

export default InvoicePaymentPage