import React from 'react'
import { useHistory } from 'react-router'
import StyledMenuBack from './styled/MenuBack'
import { Icon } from '..'

export function MenuBack({ url }) {
    const history = useHistory()

    const onClick = () => {
        if (url) {
            history.push(url)
        } else {
            window.history.back()
        }
    }

    return <StyledMenuBack onClick={onClick}>
        <Icon name="chevron-left" />
    </StyledMenuBack>
}

export default MenuBack