import _ from 'lodash'
import React from 'react'
import { Table, THead, TBody, Tr, Th, Ts } from '../ui'
import UserListItem from './UserListItem'


function UserList({ users }) {
    return <Table full-width>
        <THead>
            <Tr>
                <Th><Ts>Name</Ts></Th>
                <Th><Ts>Email</Ts></Th>
                <Th><Ts>Groups</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(users, user => <UserListItem key={user.id} user={user} />)}
        </TBody>
    </Table>
}

export default UserList
