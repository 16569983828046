import fetch from '../../../util/fetch'

export async function uploadFile(file, path) {
    // package form data
    let formData = new FormData()
    formData.append('file', file)
    formData.append('path', path)

    // upload file
    let json = await fetch('upload', {
        method: 'POST',
        form: formData,
    })
    return json
}
