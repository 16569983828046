import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import { Layout, LoadingLayout, View, Breadcrumbs, Breadcrumb } from '../../ui'
import { Customer, Sale, User, useAction, useFetching, useSelector, useDispatch } from '../../../store'
import { Permission } from '../../../const'
import LeadForm from './LeadForm'

const NewLeadSource = 'internal'
const NewLeadState = 'lead'

const LeadDefaultValues = {
    state: NewLeadState,
    source: NewLeadSource,
    created: new Date(),
    customer_id: 0,
}


export function NewLeadView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const hasUsers = useAction(User.getWithPermission(Permission.Sale.Handle), [])
    const loading = useFetching(User, hasUsers)
    const users = useSelector(User.select.ordered())

    if (loading) {
        return <LoadingLayout />
    }

    const createLead = sale => {
        dispatch(Sale.create(Sale.sanitize(sale), sale => {
            history.push(`/sales/${sale.id}`)
        }))
    }

    return <Layout title="New Sale" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()}>
        <Breadcrumbs>
            <Breadcrumb url="/sales">Sales</Breadcrumb>
            <Breadcrumb>New Sale</Breadcrumb>
        </Breadcrumbs>
        <View>
            <LeadForm sale={LeadDefaultValues} users={users} onSubmit={createLead} />
        </View>
    </Layout>
}

export default NewLeadView