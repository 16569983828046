import React from 'react'
import styled from 'styled-components/macro'
import { Formik, Form } from 'formik'
import { ListGroup, Button, ListGroupItem } from 'react-bootstrap'
import { Icon, Ts } from '../ui'
import GroupSelectField from './GroupSelect'
import * as yup from 'yup'
import GroupLink from './GroupLink'


const GroupEntry = styled(ListGroup.Item)`
    &.list-group-item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`

const GroupName = styled.label`
    flex: 1 0;
    margin: 0;
    margin-right: 1rem;
`

const schema = yup.object({
    id: yup.number().min(1, 'Select a group'),
})

export function UserGroups({ user, groups, onAdd, onRemove }) {
    const addableGroups = _.filter(groups, group => _.findIndex(user.groups, usergroup => usergroup.id === group.id) < 0)
    return <Formik onSubmit={({ id }) => onAdd(id)} initialValues={{group: 0}} validationSchema={schema}>
        <Form style={{ maxWidth: '600px' }}>
            <ListGroup>
                {_.map(user.groups, group => <GroupEntry key={group.id}>
                    <GroupName>
                        <GroupLink id={group.id} />
                    </GroupName>
                    <Button size="sm" variant="danger" onClick={() => onRemove(group.id)}>
                        <Icon name="times" />
                    </Button>
                </GroupEntry>)}
                <GroupEntry>
                    <GroupName>
                        <GroupSelectField name="id" label="Add to Group" groups={addableGroups} />
                    </GroupName>
                    <Button variant="success" size="sm" type="submit">
                        <Icon name="plus" />
                    </Button>
                </GroupEntry>
            </ListGroup>
        </Form>
    </Formik>
}

export default UserGroups
