import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { clearErrors } from '../../../store/actions/errors'
import { Ts } from '..'


export function Errors() {
    const history = useHistory()
    const dispatch = useDispatch()
    const errors = useSelector(state => state.errors)

    if (!errors || !errors.error) {
        return null
    }

    const { message, status, redirect } = errors
    const close = () => {
        if (status === 401 && redirect) {
            // redirect to login
            history.push('/login?p=' + redirect)
        }
        dispatch(clearErrors())
    }

    return <Modal show={true} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>
                <Ts>Error</Ts>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={close}>
                <Ts>Ok</Ts>
            </Button>
        </Modal.Footer>
    </Modal>
}

export default Errors