
import fetch from '../../util/fetch'

export const GET_INVOICE_PAYMENT = 'GET_INVOICE_PAYMENT'
export const POST_INVOICE_PAYMENT = 'POST_INVOICE_PAYMENT'
export const RECEIVE_INVOICE_PAYMENT = 'RECEIVE_INVOICE_PAYMENT'
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS'
export const INVOICE_PAYMENT_ERROR = 'INVOICE_PAYMENT_ERROR'

export function getInvoicePayment(order) {
    return async dispatch => {
        dispatch({
            type: GET_INVOICE_PAYMENT,
            order,
        })
        const request = await fetch('payment/order/' + order)
        dispatch({
            type: RECEIVE_INVOICE_PAYMENT,
            request,
        })
    }
}

export function postInvoicePayment(request) {
    return async dispatch => {
        dispatch({
            type: POST_INVOICE_PAYMENT,
        })
        try {
            await fetch('payment', {
                method: 'POST',
                json: request,
            })
            dispatch({
                type: INVOICE_PAYMENT_SUCCESS,
            })
        }
        catch (err) {
            dispatch({
                type: INVOICE_PAYMENT_ERROR,
                error: err.message,
            })
        }
    }
}