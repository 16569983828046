import _ from "lodash";
import { HeatingTypes, PumpType } from ".";

const OptionalBool = {
    "": "Select",
    yes: "Yes",
    no: "No",
};

const Replacement = {
    install_type: "replace",
};

const PumpTypes = (types) => ({ desired_type: types });

export const CrmFieldDefs = {
    Property: {
        title: "Fastighet",
        fields: {
            Size: {
                type: "text",
                label: "Boarea",
                suffix: "m2",
            },
            HeatedArea: {
                type: "text",
                label: "Uppvärmd yta",
                suffix: "m2",
            },
            Occupants: {
                type: "text",
                label: "Antal personer i hushållet",
                placeholder: "0",
                suffix: "personer",
            },
            HeatingType: {
                type: "select",
                label: "Nuvarande uppvärmning",
                options: _.reduce(
                    HeatingTypes,
                    (r, heating) => _.set(r, heating.type, heating.title),
                    {},
                ),
            },
            Consumption: {
                type: "text",
                label: "Energiförbrukning",
            },
            Insulation: {
                type: "select",
                label: "Isolering",
                options: {
                    "": "Select",
                    bad: "Dålig",
                    average: "Medel",
                    good: "Good",
                },
            },
            Year: {
                type: "text",
                label: "Byggår",
            },
            Type: {
                type: "select",
                label: "Typ av fastighet",
                options: {
                    "": "Select",
                    house: "Hus",
                    apartment: "Lägenhet",
                    property: "Fastighet",
                },
            },
            Blueprint: {
                type: "select",
                label: "Ritning över fastigheten",
                options: OptionalBool,
            },
            HouseType: {
                type: "select",
                label: "Huskropp (källare exkluderad)",
                options: {
                    "": "Select",
                    "1floor": "1-plan",
                    "2floor": "2-plan",
                    "3floor": "3-plan",
                    "4floor": "4-plan",
                },
            },
            Basement: {
                type: "select",
                label: "Källare",
                options: OptionalBool,
            },
            PowerCapacity: {
                type: "select",
                label: "Elkapacitet finns",
                options: OptionalBool,
            },
            Fuse: {
                type: "select",
                label: "Huvudsäkring",
                options: {
                    "": "Select",
                    16: "16A - Grå",
                    20: "20A - Blå",
                    25: "25A - Gul",
                    35: "35A - Svart",
                    50: "50A - Vit",
                    63: "63A - Koppar",
                    80: "80A",
                    100: "100A",
                },
            },
            ExtraWater: {
                type: "select",
                label: "Behov av extra varmvatten",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            Asbestos: {
                type: "select",
                label: "Asbest (isolering/rörisolering)",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            DrillPermitInfo: {
                type: "text",
                label: "Informerat om bygglov och tillstånd för borr",
                options: OptionalBool,
                requires: {
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },

            // Fastighet: Värmesystem

            HeatingSection: {
                type: "divider",
                label: "Värmesystem",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            FloorHeat: {
                type: "select",
                label: "Golvvärme",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            FloorHeatDividers: {
                type: "text",
                label: "Antal fördelare till golvvärme",
                placeholder: "0",
                suffix: "st",
                requires: {
                    "Property.FloorHeat": "yes",
                },
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            RadiatorSystem: {
                type: "select",
                label: "Radiatorsystem",
                options: {
                    "": "Select",
                    none: "Nej",
                    "1pipe": "1-Rör",
                    "2pipe": "2-Rör",
                },
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            HighTempRadiators: {
                type: "select",
                label: "Högtemp Radiatorer",
                options: OptionalBool,
                requires: {
                    "Property.RadiatorSystem": ["1pipe", "2pipe"],
                },
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            OtherHeatingType: {
                type: "text",
                label: "Annan typ av värmesystem",
                requires: {
                    "Property.FloorHeat": ["", "no"],
                    "Property.RadiatorSystem": ["", "none"],
                },
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            WaterShutoff: {
                type: "select",
                label: "Inkommande vatten kan stängas",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            ExistingSensors: {
                type: "select",
                label: "Befintlig rumsgivare",
                options: OptionalBool,
                requires: {
                    ...Replacement,
                    ...PumpTypes([
                        PumpType.Franluft,
                        PumpType.LuftVatten,
                        PumpType.VatskaVatten,
                    ]),
                },
            },
            ExistingDrain: {
                type: "select",
                label: "Finns avlopp i anslutning till pumpen",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            IndoorUnitMeasure: {
                type: "text",
                label: "Mått på befintlig innedel",
                suffix: "cm BxHxD",
                requires: {
                    ...Replacement,
                    ...PumpTypes([
                        PumpType.Franluft,
                        PumpType.LuftVatten,
                        PumpType.VatskaVatten,
                    ]),
                },
            },
            OutdoorUnitMeasure: {
                type: "text",
                label: "Mått på befintlig utedel",
                suffix: "cm BxHxD",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.LuftVatten]),
                },
            },
            VentilationCapacity: {
                type: "text",
                label: "Ventilitionskapacitet",
                requires: PumpTypes([PumpType.Franluft]),
            },
            VentilationType: {
                type: "select",
                label: "Typ av ventilationssystem",
                options: {
                    "": "Select",
                    ftx: "FTX",
                    franluft: "Frånluft",
                    fran_till_luft: "Från- & Tilluft",
                    other: "Annat",
                },
                requires: PumpTypes([PumpType.Franluft]),
            },
            VentilationOther: {
                type: "text",
                label: "Ange ventilationstyp:",
                requires: {
                    "Property.VentilationType": "other",
                },
                requires: PumpTypes([PumpType.Franluft]),
            },

            // Fastighet: Befintligt borrhål/slinga

            ExistingLineSection: {
                type: "divider",
                label: "Befintligt borrhål/slinga",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },
            LineCount: {
                type: "text",
                label: "Antal slingor",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },
            LineLength: {
                type: "text",
                label: "Total längd",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },
            Dividers: {
                type: "text",
                label: "Antal fördelare",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },
            Medium: {
                type: "text",
                label: "Typ av medium",
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },
            HaveLineBlueprint: {
                type: "select",
                label: "Ritningar och info om befintliga borrhål/slingor",
                options: {
                    "": "Select",
                    collected: "Inhämtat",
                    missing: "Saknas",
                },
                requires: {
                    ...Replacement,
                    ...PumpTypes([PumpType.VatskaVatten]),
                },
            },

            OtherSection: {
                type: "divider",
                label: "Övrigt",
            },
            Notes: {
                type: "textarea",
                label: "Anteckningar",
            },
        },
    },
    Install: {
        title: "Installation",
        fields: {
            ReplacedBrand: {
                type: "text",
                label: "Utbytt märke",
                requires: Replacement,
            },
            ReplacedModel: {
                type: "text",
                label: "Utbytt modell",
                requires: Replacement,
            },
            SplitType: {
                type: "select",
                label: "Split",
                options: {
                    "": "Select",
                    single: "Singlesplit",
                    "multi-eq": "Multisplit Lika Innedelar",
                    multi: "Multisplit Olika Innedelar",
                },
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            SplitRoomSizes: {
                type: "text",
                label: "Storlekar på rum",
                requires: {
                    "Installation.SplitType": ["multi", "multi-eq"],
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            SplitPipeLength: {
                type: "text",
                label: "Total rörlängd",
                requires: {
                    "Installation.SplitType": ["multi", "multi-eq"],
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            UnitSeparation: {
                type: "text",
                label: "Avstånd mellan inne- och utedel",
                requires: {
                    "Installation.SplitType": "single",
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            Electrician: {
                type: "select",
                label: "Elektriker behövs",
                options: {
                    "": "Välj",
                    yes: "Ja",
                    no: "Nej. Befintlig installation",
                    extern: "Nej. Kund ordnar",
                },
            },
            PlatformType: {
                type: "select",
                label: "Arbetsplattform",
                options: {
                    "": "Ingen",
                    lift: "Lift",
                    crane: "Kran",
                    scaffold: "Ställning",
                },
                requires: {
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            CondensationDrain: {
                type: "select",
                label: "Kondensdränering",
                options: {
                    "": "Select",
                    pump: "Kondenspump",
                    drain: "Kopplas till avlopp",
                    outdoor: "Skickas utomhus",
                    other: "Annat",
                },
                requires: {
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            CondensationOther: {
                type: "text",
                label: "Annan kondensdränering",
                requires: {
                    ...PumpTypes([PumpType.LuftLuft]),
                    "Installation.CondensationDrain": "other",
                },
            },
            CondensationDistance: {
                type: "text",
                label: "Kondensrör längd",
                requires: {
                    ...PumpTypes([PumpType.LuftLuft]),
                },
            },
            NewDrills: {
                type: "select",
                label: "Nya borrhål",
                options: OptionalBool,
                requires: PumpTypes([PumpType.VatskaVatten]),
            },
            NewLines: {
                type: "select",
                label: "Nya slingor",
                options: OptionalBool,
                requires: PumpTypes([PumpType.VatskaVatten]),
            },

            // Innedel

            IndoorSection: {
                type: "divider",
                label: "Innedel",
            },
            IndoorPlacementOK: {
                type: "select",
                label: "Innedelens placering följer tillverkarens riktlinjer",
                options: OptionalBool,
            },
            IndoorPlacement: {
                type: "textarea",
                rows: 2,
                label: "Tilltänkt placering innedel",
            },
            IndoorMaxHeight: {
                type: "text",
                label: "Arbetshöjd innedel",
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            IndoorMoveHelp: {
                type: "select",
                label: "Flytthjälp",
                options: {
                    "": "Behövs ej",
                    carry: "Bärhjälp",
                    door_remove: "Demontering av dörrkarm",
                    other: "Annat",
                },
            },
            IndoorMoveOther: {
                type: "text",
                label: "Annan typ av flytthjälp",
                requires: {
                    "Installation.IndoorMoveHelp": "other",
                },
            },
            Ceiling: {
                type: "text",
                label: "Takhöjd",
            },
            CeilingOffset: {
                type: "text",
                label: "Avstånd från tak till innedel",
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            Height: {
                type: "text",
                label: "Installationshöjd",
            },

            // Utedel

            OutdoorSection: {
                type: "divider",
                label: "Utedel",
            },
            OutdoorPlacementOK: {
                type: "select",
                label: "Utedelens placering följer tillverkarens riktlinjer",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft, PumpType.LuftVatten]),
            },
            OutdoorPlacement: {
                type: "textarea",
                rows: 2,
                label: "Tilltänkt placering utedel",
                requires: PumpTypes([PumpType.LuftLuft, PumpType.LuftVatten]),
            },
            OutdoorMaxHeight: {
                type: "text",
                label: "Arbetshöjd utedel",
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            OutdoorMoveHelp: {
                type: "select",
                label: "Flytthjälp",
                options: {
                    "": "Behövs ej",
                    carry: "Bärhjälp",
                    crane: "Kran",
                    other: "Annat",
                },
            },
            OutdoorMoveOther: {
                type: "text",
                label: "Annan typ av flytthjälp",
                requires: {
                    "Installation.OutdoorMoveHelp": "other",
                },
            },
            WallMount: {
                type: "select",
                label: "Väggstativ",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            Landscaping: {
                type: "text",
                label: "Markarbete, kundens önskemål",
                requires: PumpTypes([PumpType.LuftLuft, PumpType.LuftVatten]),
            },

            // Håltagning

            HoleSection: {
                type: "divider",
                label: "Håltagning",
            },
            FacadeHoles: {
                type: "text",
                label: "Fasad: Antal hål",
            },
            FacadeType: {
                type: "text",
                label: "Fasad: Typ av vägg",
            },
            FacadeDiameter: {
                type: "text",
                label: "Fasad: Tjocklek",
            },
            WallHoles: {
                type: "text",
                label: "Vägg: Antal hål",
            },
            WallType: {
                type: "text",
                label: "Vägg: Typ av vägg",
            },
            WallDiameter: {
                type: "text",
                label: "Vägg: Tjocklek",
            },
            FloorHoles: {
                type: "text",
                label: "Golv: Antal hål",
            },
            FloorType: {
                type: "text",
                label: "Golv: Typ av golv",
            },
            FloorDiameter: {
                type: "text",
                label: "Golv: Tjocklek",
            },

            // Anslutning

            ConnectorSection: {
                type: "divider",
                label: "Anslutning",
            },
            ConnectorPlacement: {
                type: "select",
                label: "Anslutningens placering",
                options: {
                    "": "Select",
                    top: "Topp",
                    bottom: "Botten",
                },
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },

            LiftKit: {
                type: "select",
                label: "Förhöjningssats",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            InWaterDiameter: {
                type: "text",
                label: "Inkommande vatten: Diameter",
                suffix: "mm",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            InWaterPipeType: {
                type: "text",
                label: "Inkommande vatten: Typ av rör",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            HeatDiameter: {
                type: "text",
                label: "Värme tillopp: Diameter",
                suffix: "mm",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            HeatPipeType: {
                type: "text",
                label: "Värme tillopp: Typ av rör",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            ReturnDiameter: {
                type: "text",
                label: "Värme retur: Diameter",
                suffix: "mm",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            ReturnPipeType: {
                type: "text",
                label: "Värme retur: Typ av rör",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            HotWaterDiameter: {
                type: "text",
                label: "Tappvarmvatten: Diameter",
                suffix: "mm",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            HotWaterPipeType: {
                type: "text",
                label: "Tappvarmvatten: Typ av rör",
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            FluidConnector: {
                type: "text",
                label: "Anslutning mellan inne- och utedel (vätskeledningar)",
                requires: PumpTypes([PumpType.LuftVatten]),
            },
            CoolantConnector: {
                type: "text",
                label: "Avstånd mellan inne- och utedel",
                requires: PumpTypes([PumpType.LuftLuft, PumpType.LuftVatten]),
            },
            LineConnector: {
                type: "text",
                label: "Anslutning till slinga/borr: Diameter",
                suffix: "mm",
                requires: PumpTypes([PumpType.VatskaVatten]),
            },
            LineConnectorType: {
                type: "text",
                label: "Anslutning till slinga/borr: Typ av rör",
                requires: PumpTypes([PumpType.VatskaVatten]),
            },
            ExistingPhases: {
                type: "select",
                label: "Elmatning befintlig pump",
                options: {
                    "": "Välj",
                    "1phase": "1-fas",
                    "3phase": "3-fas",
                },
                requires: Replacement,
            },
            VentInsulation: {
                type: "select",
                labels: "Ventilationsisolering",
                options: {
                    "": "Välj",
                    ok: "OK",
                    insulate: "Isoleras",
                },
                requires: PumpTypes([PumpType.Franluft]),
            },
            InAirChannelSize: {
                type: "text",
                label: "Tillluft-kanalens storlek",
                requires: PumpTypes([PumpType.Franluft]),
            },
            OutAirChannelSize: {
                type: "text",
                label: "Frånluft-kanalens storlek",
                requires: PumpTypes([PumpType.Franluft]),
            },
            VentConnector: {
                type: "select",
                label: "Anslutning mellan pump och ventilationssytem",
                options: {
                    "": "Select",
                    flex: "Flexslang",
                    canal: "Kanal",
                    other: "Annan",
                },
                requires: PumpTypes([PumpType.Franluft]),
            },
            VentConnectorOther: {
                type: "text",
                label: "Annan typ av ventilationsanslutning",
                requires: {
                    "Installation.VentConnector": "other",
                    ...PumpTypes([PumpType.Franluft]),
                },
            },
            Drainage: {
                type: "select",
                label: "Dränering/kondens",
                options: {
                    "": "Select",
                    drain: "Avlopp",
                    chest: "Stenkista",
                },
                requires: PumpTypes([PumpType.LuftVatten]),
            },

            OtherSection: {
                type: "divider",
                label: "Övrigt",
            },
            Notes: {
                type: "textarea",
                label: "Anteckningar",
            },
        },
    },
    Addons: {
        title: "Tillval",
        fields: {
            CloudService: {
                type: "select",
                label: "Uppkoppling",
                options: OptionalBool,
            },
            ServiceAgreement: {
                type: "select",
                label: "Serviceavtal",
                options: OptionalBool,
            },
            Wifi: {
                type: "select",
                label: "Wifi",
                options: OptionalBool,
            },
            Removal: {
                type: "select",
                label: "Skrotning",
                options: OptionalBool,
            },
            MagnetFilter: {
                type: "select",
                label: "Magnetitfilter",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            DrainagePump: {
                type: "select",
                label: "Dräneringspump / kondenspump",
                options: OptionalBool,
            },
            ExtraRadiator: {
                type: "select",
                label: "Extra radiator",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            SensorWireless: {
                type: "select",
                label: "Rumsgivare trådlös",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            SensorWired: {
                type: "select",
                label: "Rumsgivare trådbunden",
                options: OptionalBool,
                requires: PumpTypes([
                    PumpType.Franluft,
                    PumpType.LuftVatten,
                    PumpType.VatskaVatten,
                ]),
            },
            Cabinet: {
                type: "select",
                label: "Överskåp",
                options: OptionalBool,
                requires: PumpTypes([PumpType.Franluft]),
            },
            FloorMount: {
                type: "select",
                label: "Markstativ",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            MountBlock: {
                type: "select",
                label: "Montageblock",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            DripTray: {
                type: "select",
                label: "Droppskål",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft, PumpType.LuftVatten]),
            },
            Roof: {
                type: "select",
                label: "Takskydd",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft]),
            },
            Shielding: {
                type: "select",
                label: "Pumpskydd",
                options: OptionalBool,
                requires: PumpTypes([PumpType.LuftLuft]),
            },

            OtherSection: {
                type: "divider",
                label: "Övrigt",
            },
            Notes: {
                type: "textarea",
                label: "Anteckningar",
            },
        },
    },
};

export default CrmFieldDefs;
