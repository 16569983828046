import _ from 'lodash'
import React from 'react'
import { ts } from '../ui'
import { Select } from '../form'


function GroupSelect({ name, label, groups }) {
    return <Select name={name} label={label} options={groups} optionKey="id" optionValue="name">
        <option value={0} key={0}>{ts('Select Group')}...</option>
    </Select>
}

export default GroupSelect
