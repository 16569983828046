import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { AreaTypes } from '../../../const'
import { Icon } from '../../ui'


export const LocationLabel = styled.span`
    display: block;
    font-size: 0.7em;
    color: #555;
`

export function SaleLocation({ sale }) {
    const area = _.get(AreaTypes, sale.area, false)
    const city = _.get(sale, 'city', '(ort saknas)')
    if (!area && !city) {
        return null
    }
    return <LocationLabel>
        <Icon name="map-marker" />
        <span>{area}</span>{(city && area) && <span>, </span>}<span>{city}</span>
    </LocationLabel>
}

export default SaleLocation
