import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../ui'
import Preview from './Preview'

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 32em) {
    flex-direction: row;
    }
`

const PreviewImage = styled.div`
    position: relative;

    @media screen and (min-width: 32em) {
        max-width: 300px;
        height: auto;
    }
`

const RemoveButton = styled(Button)`
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    min-width: 0;
    width: auto !important;
`


function PreviewGallery({ urls, onRemove }) {
    return <GalleryContainer>
    {_.map(urls, (url, idx) => <PreviewImage key={idx}>
        <Preview url={url} />
        <RemoveButton color="danger" icon="trash" onClick={() => onRemove(idx)} />
    </PreviewImage>)}
    </GalleryContainer>
}

PreviewGallery.defaultProps = {
    urls: [],
}

export default PreviewGallery