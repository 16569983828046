import styled from 'styled-components/macro'

export const CommentText = styled.div`
`

export const EditedLabel = styled.label`
    display: block;
    margin-top: 1.5em;
    font-style: italic;
    font-size: 0.8em;
`

export default CommentText
