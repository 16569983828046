import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { formatFullDate } from '../../util/format'
import SalesTable from './SalesTable'
import WeeklyChart from './WeekChart'
import MonthlyChart from './MonthChart'
import Carousel from './Carousel'
import Doge from './Doge'
import logoImg from '../../../img/logo.svg'


const DashView = styled.div`
    padding: 1.5rem;
`
const LogoImage = styled.img`
    width: 180px;
    height: auto;
`
const LogoContainer = styled.div`
    text-align: center;
    padding: 2.0rem;
`
const ActivityLabel = styled.label`
    display: block;
    text-align: center;
    padding: 0.5rem;
    font-size: 2.0rem;
`


class Dashboard extends React.Component {
    render() {
        const { lastUpdate } = this.props
        return <DashView>
            <LogoContainer>
                <LogoImage src={logoImg} role="presentation" />
            </LogoContainer>

            <SalesTable />

            <Carousel interval={30}>
                <MonthlyChart
                    title="Månadsförsäljning Reservdelar"
                    statDisplay="sales"
                    scaleFactor={0.001}
                    color="rgba(149, 29, 34, 1)"
                />
                <WeeklyChart
                    title="Veckoförsäljning Reservdelar"
                    statDisplay="sales"
                    emaAlpha={0.88}
                    scaleFactor={0.001}
                    color="rgba(149, 29, 34, 1)"
                    background="rgba(0, 29, 34, 0.05)"
                />

                <MonthlyChart
                    title="Månadsförsäljning Pumpar"
                    statDisplay="pumps"
                    color="rgba(29, 34, 149, 1)"
                />
                <WeeklyChart
                    title="Veckoförsäljning Pumpar"
                    statDisplay="pumps" 
                    emaAlpha={0.95} 
                    color="rgba(29, 34, 149, 1)"
                    background="rgba(29, 80, 149, 0.05)"
                />
            </Carousel>

            <ActivityLabel>{formatFullDate(lastUpdate)}</ActivityLabel>
            <Doge />
        </DashView>
    }
}

const mapStateToProps = state => ({
    lastUpdate: state.stats.lastActive,
})
export default connect(mapStateToProps)(Dashboard)
