import React from 'react'
import PropTypes from 'prop-types'
import Menu from './Menu'
import Logo from './Logo'
import StyledSiteHeader from './styled/SiteHeader'
import MenuItems from './styled/MenuItems'
import MenuIcon from './styled/MenuIcon'
import MenuBack from './MenuBack'
import MenuOk from './MenuOk'
import MenuTitle from './styled/MenuTitle'
import Fence from '../Fence'
import { Ts } from '..'


export const SiteHeader = ({ title, onOK, okIcon, backUrl, permission }) => {
    const hasOkButton = !!onOK
    return <StyledSiteHeader>
        <MenuIcon order={4}>
            <Logo />
        </MenuIcon>

        <MenuIcon order={1}>
            <MenuBack url={backUrl} />
        </MenuIcon>

        <MenuItems order={2}>
            <Menu />
        </MenuItems>

        <MenuTitle order={2}>
            <Ts>{title}</Ts>
        </MenuTitle>

        <Fence permission={permission}>
            <MenuOk visible={hasOkButton} onClick={onOK} icon={okIcon} />
        </Fence>
    </StyledSiteHeader>
}

SiteHeader.propTypes = {
    title:  PropTypes.string.isRequired,
    onOK:   PropTypes.func,
    okIcon: PropTypes.string.isRequired,
}

SiteHeader.defaultProps = {
    title: '',
    okIcon: 'check',
}

export default SiteHeader
