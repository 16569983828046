import React from 'react'
import { File, useDispatch } from '../../../store'
import FeedItem from '../feed/FeedItem'
import CommentImage from '../image/CommentImage'


export function ImagePost({ url, ...post }) {
    const dispatch = useDispatch()
    const deleteFile = () => dispatch(File.destroy(post.id))

    return <FeedItem {...post} onDelete={deleteFile} icon="camera">
        <CommentImage src={url} />
    </FeedItem>
}

export default ImagePost