import React from 'react'

class Carousel extends React.Component {
    static defaultProps = {
        // default interval in seconds
        interval: 30,
    }

    constructor() {
        super()
        this.timer = null
        this.next = this.next.bind(this)
        this.state = {
            index: 0,
        }
    }

    componentWillMount() {
        this.timer = setInterval(this.next, this.props.interval * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    next() {
        const { children } = this.props
        const { index } = this.state

        const count = React.Children.count(children)
        this.setState({
            index: (index + 1) % count,
        })
    }

    render() {
        const { children } = this.props
        const { index } = this.state
        const childArray = React.Children.toArray(children)

        return childArray[index]
    }
}

export default Carousel
