import React from 'react'
import { formatFullDate, formatDateNoTime } from '../../../util/format'


class DateSpan extends React.Component {
    render() {
        const { time, noTime } = this.props
        let date = formatFullDate(time)
        if (noTime) {
            date = formatDateNoTime(time)
        }
        return <span>{date}</span>
    }
}

export default DateSpan