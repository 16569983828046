import styled from 'styled-components/macro'

const AttachmentLink = styled.a`
    color: #666;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: #666;
    }

    margin-right: 1.0em;
    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 32em) {
        display: block;
        margin-right: 0;
        margin-top: 1.0rem;

        &:first-child {
            margin-top: 0;
        }
    }
`

export default AttachmentLink
