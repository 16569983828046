import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import theme from '../theme'

const Row = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    flex-shrink: 0;
	max-height: 100%;
	align-items: ${props => props.theme.aligns[props.options.align]};
	justify-content: ${props => props.theme.justifys[props.options.justify]};
	padding: ${props => props.theme.paddings[props.options.padding] / 2}px;

	${props => props.options.collapse && props.theme.breakpoints[props.options.collapse] && `
		@media (max-width: ${props.theme.breakpoints[props.options.collapse]}) {
			display: block;

			.hide-when-collapsed{
				display: none;
			}
		}
	`}

	${props => props.options.reverse && `
		flex-direction: row-reverse;
	`}

	${props => !props.options.reset && `
		margin: -${props.theme.paddings[props.options.padding]}px;
	`}
`

Row.defaultProps = { theme }

Row.propTypes = {
	options: PropTypes.shape({
		padding: PropTypes.string.isRequired,
		align: PropTypes.string.isRequired,
		justify: PropTypes.string.isRequired,
		reverse: PropTypes.bool,
		reset: PropTypes.bool
	}).isRequired,
	theme: PropTypes.shape({
		paddings: PropTypes.objectOf(PropTypes.number).isRequired,
		aligns: PropTypes.objectOf(PropTypes.string).isRequired,
		justifys: PropTypes.objectOf(PropTypes.string).isRequired
	}).isRequired
}

export default Row
