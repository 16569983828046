import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router'
import { Layout, View, Breadcrumbs, Breadcrumb, LoadingLayout, Fence, BreadcrumbButton, BreadcrumbSpacer, PageWidth } from '../ui'
import { Group, useAction, useFetching, useSelector } from '../../store'
import { Permission } from '../../const'
import GroupList from './GroupList'


function GroupListView() {
    const history = useHistory()
    const hasGroup = useAction(Group.all())
    const loading = useFetching(Group, hasGroup)
    const groups = useSelector(Group.select.ordered())
    if (loading) {
        return <LoadingLayout />
    }

    const newGroup = () => history.push('/groups/new')
    return <Layout title="Groups" headerOkIcon="plus" onHeaderOkClick={newGroup} permission={Permission.Group.New}>
        <Breadcrumbs>
            <Breadcrumb url="/groups">Groups</Breadcrumb>
            <BreadcrumbSpacer />
            <Fence permission={Permission.Group.New}>
                <BreadcrumbButton title="New Group" icon="plus" variant="success" onClick={newGroup} />
            </Fence>
        </Breadcrumbs>
        <View name="groups">
            <PageWidth>
                <GroupList groups={groups} />
            </PageWidth>
        </View>
    </Layout>
}

export default GroupListView
