import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { Icon } from '../../ui'
import { Activities, Red } from '../../../const'


export const ActivityLabel = styled.span`
    display: block;
    font-weight: bold;
    margin-top: 0.3rem;
    font-size: 0.8rem;

    ${p => p.past ? `color: ${Red};` : ''}
`

export function nextActivity(sale) {
    const activities = _.sortBy(sale.activities, ['scheduled_at'])
    return _.find(activities, activity => !activity.completed)
}

export function ActivityInfo({ activity }) {
    if (activity.completed) {
        return null
    }

    const visitTime = moment(activity.scheduled_at)
    const visitPast = moment().isAfter(visitTime)

    let visitTimeStr = visitTime.format('MMM Do HH:mm')
    if (visitTime.hours() <= 2) {
        visitTimeStr = visitTime.format('MMM Do')
    }

    const ActivityType = Activities[activity.activity_type]

    return <ActivityLabel past={visitPast} completed={activity.completed}>
        <Icon name={ActivityType.icon} /> {visitTimeStr}
    </ActivityLabel>
}

export default ActivityInfo
