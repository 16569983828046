import _ from 'lodash'
import { StoreReducer } from './reducer'
import { apiGet, apiPut, apiPost, apiDelete } from './api'
import { hasId, selectId, selectOne, selectAll, selectOrdered, selectWhere, selectWhereOrdered } from './selectors'
import {
    fetchStoreAction,
    singleStoreAction,
    clearStoreAction,
    appendStoreAction,
    updateStoreAction,
    deleteStoreAction,
    manyStoreAction,
    abortStoreAction,
} from './actions'

const defaultModelOptions = {
    createAction: 'append',
    cacheDuration: 60,
}

export function CreateModel({ id, url, schema, ...options }, extension) {
    options = _.extend({ }, defaultModelOptions, options)
    if (!id) {
        throw new Error("Model identifier is required")
    }
    if (!url) {
        throw new Error("Model url endpoint is required")
    }

    const reducer = StoreReducer(id)

    const actions = {
        fetch:     fetchStoreAction(id),
        abort:     abortStoreAction(id),
        store:     singleStoreAction(id),
        clear:     clearStoreAction(id),
        append:    appendStoreAction(id),
        update:    updateStoreAction(id),
        delete:    deleteStoreAction(id),
        storeMany: manyStoreAction(id),
    }

    const getStoreFromState = state => {
        const key = id.toLowerCase()
        return state[key]
    }

    let model = _.extend({ }, {
        id,
        actions,
        reducer,
        schema,
        getStore: getStoreFromState,

        // api methods
                
        get: apiGet({
            url:    id => `${url}/${id}`,
            before: actions.fetch,
            after:  actions.append,
            error:  actions.abort,
            //cached: true,
            cacheDuration: options.cacheDuration,
        }), 

        all: apiGet({
            url:    param => `${url}/${param.page ? param.page : ''}`,
            before: actions.fetch,
            after:  actions.storeMany,
            error:  actions.abort,
            //cached: true,
            cacheDuration: options.cacheDuration,
        }),

        update: apiPut({
            url:    item => `${url}/${item.id}`,
            before: actions.fetch,
            after:  actions.update,
            error:  actions.abort,
        }),

        create: apiPost({
            url:    url,
            before: actions.fetch,
            after:  actions[options.createAction],
            error:  actions.abort,
        }),

        destroy: apiDelete({
            url:    id => `${url}/${id}`,
            before: actions.fetch,
            after:  actions.delete,
            error:  actions.abort,
        }),

        // store query methods 
        
        selectId: (state, id, def) => {
            const store = getStoreFromState(state)
            return selectId(store, id, def)
        },

        hasId: (state, id) => {
            const store = getStoreFromState(state)
            return hasId(store, id)
        },

        selectOne: (state, pred) => {
            const store = getStoreFromState(state)
            return selectOne(store, pred)
        },

        selectAll: state => {
            const store = getStoreFromState(state)
            return selectAll(store)
        },

        selectOrdered: state => {
            const store = getStoreFromState(state)
            return selectOrdered(store)
        },

        selectWhere: (state, predicate) => {
            const store = getStoreFromState(state)
            return selectWhere(store, predicate)
        },

        selectWhereOrdered: (state, predicate) => {
            const store = getStoreFromState(state)
            return selectWhereOrdered(store, predicate)
        },

        // helpers

        isFetching: state => {
            const store = getStoreFromState(state)
            return store.isFetching
        },

        select: {
            id: (id, def) => state => {
                const store = getStoreFromState(state)
                return selectId(store, id, def)
            },

            hasId: id => state => {
                const store = getStoreFromState(state)
                return hasId(store, id)
            },

            one: pred => state => {
                const store = getStoreFromState(state)
                return selectOne(store, pred)
            },

            all: () => state => {
                const store = getStoreFromState(state)
                return selectAll(store)
            },

            ordered: () => state => {
                const store = getStoreFromState(state)
                return selectOrdered(store)
            },

            where: predicate => state => {
                const store = getStoreFromState(state)
                return selectWhere(store, predicate)
            },

            whereOrdered: predicate => state => {
                const store = getStoreFromState(state)
                return selectWhereOrdered(store, predicate)
            },

            paging: () => state => {
                const store = getStoreFromState(state)
                return store.paging
            },
        },

        sanitize: data => {
            if (!schema) {
                return data
            }
            return schema.cast(data)
        },
    })

    if (_.isFunction(extension)) {
        model = _.extend(model, extension(actions, url))
    }

    return model
}