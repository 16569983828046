import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Form, InputGroup, Tab, Tabs } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Field } from 'formik'
import { Ts, ts, Markdown } from '../ui'


const MarkdownPreview = styled.div`
    padding: 0.5rem;
    min-height: 6em;
    border: 1px solid #ced4da;
    border-top: 0;

    border-radius: .25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`

export function MarkdownField({ name, label, rows, onChange, innerRef, ...props }) {
    return <Field name={name}>
        {({ field, meta }) => <Tabs defaultActiveKey="edit">
            <Tab eventKey="edit" title={ts(label)}>
                <Form.Group controlId={name}>
                <Form.Control 
                    as="textarea"
                    rows={rows}
                    value={field.value || ''} 
                    isValid={meta.touched && !meta.error}
                    isInvalid={meta.error}
                    ref={innerRef}
                    onChange={e => {
                        field.onChange(e)
                        if (_.isFunction(onChange)) {
                            onChange(e.currentTarget.value)
                        }
                    }}
                    style={{
                        borderTop: 0,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                    {...props}
                />
                </Form.Group>
                <label style={{ fontSize: '0.8em' }}>
                    Du kan formattera texten med <a href="https://www.markdownguide.org/cheat-sheet/">markdown</a>.
                </label>
                {meta.error && <Form.Control.Feedback type="invalid"><Ts>{meta.error}</Ts></Form.Control.Feedback>}
            </Tab>
            <Tab eventKey="preview" title={ts("Preview")}>
                <MarkdownPreview>
                    <Markdown source={field.value} />
                </MarkdownPreview>
            </Tab>
        </Tabs>}
    </Field> 
}

MarkdownField.defaultProps = {
    rows: 4,
}

export default MarkdownField