import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { Icon } from '../icon'
import { Ts } from '../text'


export function SubmitButton({ text, icon, submitting, variant, ...props }) {
    return <Button type="submit" variant={variant} disabled={submitting} {...props}>
        <Icon
            name={submitting ? "spinner" : icon}
            spin={submitting}
            fixedWidth
        />
        <Ts>{text}</Ts>
    </Button>
}

SubmitButton.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    variant: PropTypes.string,
    submitting: PropTypes.bool,
}

SubmitButton.defaultProps = {
    text: 'Save',
    icon: 'check',
    variant: 'success',
    submitting: false,
}

export default SubmitButton
