import { useFormikContext } from 'formik'


export function FieldRequires({ children, ...requires }) {
    const form = useFormikContext()

    // only display the field if all requirements are fulfilled
    const display = _.reduce(requires, (r, expected, key) => {
        let ok = false
        const actual = _.get(form.values, key)
        if (_.isArray(expected)) {
            // if the desired value is an array, the requirement is ok if
            // any of the array elements matches the actual value
            ok = _.includes(expected, actual)
        } else {
            // otherwise, the value should match exactly
            ok = actual === expected
        }
        return r && ok
    }, true)

    return display ? children : null
}

export default FieldRequires