import React from 'react'
import { Card } from 'react-bootstrap'
import { Icon, Button, Anchor } from '../../ui'

import { Sale, useDispatch } from '../../../store'


const CardBodyStyle = {
    padding: '0.75em',
    display: 'flex',
    alignItems: 'center',
}

export function DriveLinks({ sale }) {
    const dispatch = useDispatch()
    if (!sale.Drive.FolderID) {
        return null
    }
    const onRefreshSheet = () => {
        dispatch(Sale.refreshOffersheet(sale))
    }
    return <Card style={{marginBottom: '1em'}}>
        <Card.Body style={CardBodyStyle}>
            <Icon name="folder" color="#959595" />
            <Anchor href={sale.Drive.FolderLink} target="_blank">Google Drive</Anchor>

            <div style={{width: '1em'}} />

            {!sale.Drive.OfferSheetLink && <React.Fragment>
                <Button 
                    icon="table"
                    size="sm"
                    title="Skapa Kalkylmaster"
                    variant="primary"
                    onClick={onRefreshSheet}
                />
            </React.Fragment>}
            {!!sale.Drive.OfferSheetLink && <React.Fragment>
                <Icon name="table" color="#34a853" />
                <Anchor href={sale.Drive.OfferSheetLink} target="_blank">Kalkyl Master</Anchor>
                <Button 
                    icon="refresh"
                    size="sm"
                    variant="secondary"
                    onClick={onRefreshSheet}
                    style={{ marginLeft: '0.5em' }}
                />
            </React.Fragment>}

        </Card.Body>
    </Card>
}

export default DriveLinks
