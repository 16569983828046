import React from 'react'

import DogeImg from '../../../img/christmas/doge.png'
import GlassesImg from '../../../img/christmas/glasses.png'
import BellsSfx from '../../../sfx/christmas/bells.wav'
import HohoSfx from '../../../sfx/christmas/hoho.wav'
import Hoho2Sfx from '../../../sfx/christmas/hoho2.wav'

import { DogeContainer, DogeWrapper, Doge, DogeGlasses } from './styled/ChristmasDoge'

class DogeAnim extends React.Component {
    constructor() {
        super()
        this.state = {
            showing: false,
            glasses: false,
        }

        this.show = this.show.bind(this)
        this.playSound = this.playSound.bind(this)

        window.doge = this.show
    }

    show(orderAmount) {
        // show doge
        this.setState({ showing: true })
        setTimeout(() => {
            // add glasses
            this.setState({ glasses: true })
            setTimeout(() => {
                // hide doge
                this.setState({ showing: false })
                setTimeout(() => {
                    // reset glasses
                    this.setState({ glasses: false })
                }, 1000)
            }, 3000)
        }, 900)
        this.playSound(orderAmount)
    }

    playSound(orderAmount) {
        if (orderAmount > 10000) {
            new Audio(Hoho2Sfx).play()
        }
        else if (orderAmount > 5000) {
            new Audio(HohoSfx).play()
        }
        else {
            new Audio(BellsSfx).play()
        }
    }

    render() {
        const { showing, glasses } = this.state

        const cntrClass = showing ? 'show' : ''
        const glsClass = glasses ? ' on' : ''

        return <DogeContainer className={cntrClass}>
            <DogeWrapper>
                <Doge src={DogeImg} />
                <DogeGlasses className={glsClass} src={GlassesImg} />
            </DogeWrapper>
        </DogeContainer>
    }
}

export default DogeAnim