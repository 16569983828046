import React from 'react'
import FooterButton from './FooterButton'
import { FooterMenu as StyledFooterMenu } from './styled'

export class FooterMenu extends React.Component {
    render() {
        return <StyledFooterMenu>
            <FooterButton icon="bars" title="Menu" to="/menu" exact />
            <FooterButton icon="user" title="CRM" to="/sales" active />
            <FooterButton icon="bar-chart" title="Stats" to="/appdash" />
        </StyledFooterMenu>
    }
}

export default FooterMenu