import styled from 'styled-components/macro'

/**
 * Stripe Elements wrapper div
 */
export const StripeInput = styled.div`
    background-color: #f3f3f3;
    padding: 0.5rem;
    border-radius: 3px;
`

export default StripeInput