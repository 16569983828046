import React from 'react'
import { File, useDispatch } from '../../../store'
import { Attachment, Attachments } from '../../ui'
import FeedItem from '../feed/FeedItem'


export function FilePost({ url, name, ...post }) {
    const dispatch = useDispatch()
    const deleteFile = () => dispatch(File.destroy(post.id))

    return <FeedItem {...post} onDelete={deleteFile} icon="file">
        <p>{name}</p>
        <Attachment title="Download" url={url} />
    </FeedItem>
}

export default FilePost