import React from 'react'

import ts from '../../../util/ts'
import { Icon } from '../'
import StyledMenuItem from './styled/MenuItem'
import MenuLink from './styled/MenuLink'

function MenuItem({ href, icon, title, expanded, onClick }) {
    return <StyledMenuItem expanded={expanded}>
        <MenuLink to={href} activeClassName="active" onClick={onClick}>
			<Icon name={icon} />
            { ts(title) }
        </MenuLink>
    </StyledMenuItem>
}

export default MenuItem
