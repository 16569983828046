import React from 'react'
import { CardNumberElement } from 'react-stripe-elements'
import StripeInput from '../styled/StripeInput'
import StripeStyle from '../styled/StripeStyle'

export function CardNumber() {
    return <StripeInput>
        <CardNumberElement style={StripeStyle} />
    </StripeInput>
}

export default CardNumber