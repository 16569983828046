import React from 'react'
import { Button } from 'react-bootstrap'
import { Ts, Icon } from '../../ui'


export function DirectionsButton({ sale }) {
    const { street, postcode, city } = sale
    console.log(sale)

    const mapsQuery = encodeURIComponent(`${street} ${city} ${postcode} SE`)
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${mapsQuery}`

    return <Button href={mapsLink} target="_blank" variant="secondary">
        <Icon name="car" />
        <Ts>Directions</Ts>
    </Button>
}

export default DirectionsButton