import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Badge } from 'react-bootstrap'
import { Icon, Ts, Spinner } from '../ui'
import { ColumnContainer, ColumnHeader, ColumnTitle, CardList, CardContainer } from './styled'
import { nextActivity } from './card/ActivityInfo'
import useLocalStorage from '../../util/localstorage'
import InfiniteScroll from "react-infinite-scroll-component";
import fetch from '../../util/fetch'

export function nextActivityTime(sale) {
    const activity = nextActivity(sale)
    return moment(activity.scheduled_at).unix()
}

export function InfiniteColumn({ state, name, icon, query, sortColumn, defaultOrder, render: Component }) {
    const [closed, setClosed] = useLocalStorage(`crm_board_${state}_closed`, true)
    const [sort, setSort] = useLocalStorage(`crm_board_${state}_sort`, defaultOrder)

    const per_page = 25
    const [sales, setSales] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [count, setCount] = useState(-1)

    const fetchPage = async (page) => {
        const res = await fetch('sale/search', {
            method: 'POST',
            json: {
                ...query,
                states: [state],
                sort: sortColumn || 'created_at',
                asc: sort === 'asc',
                per_page,
                page,
            },
        })
        setPage(page)
        setHasMore(res.length >= per_page)
        setSales(page > 1 ? sales.concat(res) : res)
        setCount(page > 1 ? res.length + sales.length : res.length)
    }

    // fetch next page
    const fetchMore = async () => fetchPage(page+1)

    // fetch first page
    useEffect(() => { 
        fetchPage(1) 
    }, [query, sort])

    // toggle sort order. causes a refetch of the first page
    const toggleSort = () => {
        setSort(sort === 'asc' ? 'desc' : 'asc')
    }

    // hide empty columns
    if (!sales.length) {
        return null
    }

    return <ColumnContainer>
        <ColumnHeader onClick={() => setClosed(!closed)}>
            <Icon name={icon} />
            <ColumnTitle>
                <Ts>{name}</Ts>
            </ColumnTitle>
            <Icon name={`sort-${sort}`} float="right" onClick={toggleSort} />
            <Badge pill variant="light" style={{ marginLeft: '0.5em' }}>{sales.length}{hasMore && '+'}</Badge>
        </ColumnHeader>
        <CardContainer closed={closed} id={`cards-${state}`}>
            <CardList>
                <InfiniteScroll
                    dataLength={count}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<Spinner />}
                    scrollableTarget={`cards-${state}`}
                >
                    {sales.map(sale => <Component sale={sale} key={sale.id} />)}
                </InfiniteScroll>
            </CardList>
        </CardContainer>
    </ColumnContainer>
}

export default InfiniteColumn
