import React, { useState } from 'react'
import { Attachment, Ts } from '../../ui'
import { isImage } from '../../form/uploader/Preview'
import StyledCommentImage from './styled/CommentImage'
import CommentImageContainer from './styled/CommentImageContainer'
import ImageZoomLabel from './styled/ImageZoomLabel'


function CommentImage({ src, ...props }) {
    const [expanded, setExpanded] = useState(false)
    const toggle = () => setExpanded(!expanded)

    // other file formats
    if (!isImage(src)) {
        return <Attachment url={src} />
    }

    // image preview
    return <CommentImageContainer zoom={expanded} onClick={toggle}>
        <StyledCommentImage 
            src={src}
            expanded={expanded} 
            onClick={toggle}
            {...props}
        />
        <ImageZoomLabel>
            {!expanded && <Ts>Click to enlarge</Ts>}
            { expanded && <Ts>Click to minimize</Ts>}
        </ImageZoomLabel>
    </CommentImageContainer>
}

export default CommentImage