import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from '../ui'


export const NextButton = ({ page, pages, setPage }) => {
    if (page >= pages) {
        return null
    }
    return <Button variant="secondary" onClick={() => setPage(page+1)}>
        <Icon name="chevron-right" />
    </Button>
}

export default NextButton