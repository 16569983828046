import _ from 'lodash'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Ts, Icon } from '..'
import Breadcrumb from './Breadcrumb'

export function BreadcrumbDropdown({ title, icon, children, onSelect, ...props }) {
    return <Breadcrumb right button>
        <Dropdown onSelect={onSelect}>
            <Dropdown.Toggle size="sm" variant="secondary" {...props}>
                {icon && <Icon name={icon} />}
                <Ts>{title}</Ts>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    </Breadcrumb>
}

BreadcrumbDropdown.defaultProps = {
    title: '',
    icon: false,
    onSelect: key => {},
}

export function BreadcrumbDropdownButton({ title, icon, ...props }) {
    return <Dropdown.Item {...props}>
        {icon && <Icon name={icon} />}
        <Ts>{title}</Ts>
    </Dropdown.Item>
}

BreadcrumbDropdown.defaultProps = {
    title: 'Button',
    icon: false,
}

export default BreadcrumbDropdown
