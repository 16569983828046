import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Layout } from '../ui/layout'
import WeekSelector from './WeekSelector'
import WeekStatEditor from './WeekStatEditor'
import { View, Breadcrumbs, Breadcrumb, Spacer, Ts } from '../ui'

import { useDispatch, useSelector } from 'react-redux'
import { getWeekStats } from '../../store/actions/stats'
import { getWeekNumber } from '../../util/week'

export function StatsView() {
    const date = new Date()
    const dispatch = useDispatch()
    const [year, setYear] = useState(date.getFullYear())
    const [week, setWeek] = useState(getWeekNumber(date))

    useEffect(() => { dispatch(getWeekStats(week, year)) }, [week, year])

    const stats = useSelector(state => state.stats.week)

    return <Layout title="Stats">
        <Breadcrumbs>
            <Breadcrumb url='/stats'>Stats</Breadcrumb>
        </Breadcrumbs>
        <View name="stats">
            <div style={{ maxWidth: '600px' }}>
            <label><Ts>Select Week</Ts>:</label>
            <WeekSelector
                year={year}
                week={week}
                setYear={setYear}
                setWeek={setWeek}
            />
            <Spacer />
            <WeekStatEditor week={stats} />
            </div>
        </View>
    </Layout>
}

export default StatsView