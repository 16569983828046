import React from 'react'
import { RowColumn } from '../../ui'
import Page from '../Page'

export function PaymentSuccessPage() {
    return <Page description="Payment accepted">
        <RowColumn>
            <p>Your payment was accepted and the invoice has been paid in full.</p>
        </RowColumn>
        <RowColumn>
            <p>Thank you for your business!</p>
        </RowColumn>
    </Page>
}

export default PaymentSuccessPage