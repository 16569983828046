import styled from 'styled-components/macro'

const MenuToggle = styled.div`
	display: none;
	padding: 0 12px;
    user-select: none;
	color: #fff;
	text-decoration: none;
	font-size: 0.8rem;
	line-height: 2rem;
	text-transform: uppercase;

	i {
		margin-right: 0.4em;
	}

    @media screen and (max-width: 1000px) {
        display: inline-block;
    }

    @media screen and (max-width: 32em) {
		display: block;
		text-align: center;
		font-size: 1rem;
		text-transform: none;

		i {
			//display: none;
		}

		span {
			//display: none;
		}
	}
`

export default MenuToggle
