import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout, View, Breadcrumb, LoadingLayout } from '../../ui'
import { Sale, Activity, useDispatch, useAction, useFetching, useSelector } from '../../../store'
import SaleBreadcrumbs from '../SaleBreadcrumbs'
import ActivityForm from './ActivityForm'


export function EditActivityView({ }) {
    const form = useRef()
    const history = useHistory()
    const dispatch = useDispatch()
    const { sale_id, activity_id } = useParams()
    const hasSale = useAction(Sale.get(sale_id), [sale_id])
    const hasActivity = useAction(Activity.get(activity_id), [activity_id])
    const activity = useSelector(Activity.select.id(activity_id))
    const loading = useFetching(Sale, Activity, hasSale, hasActivity)
    if (loading) {
        return <LoadingLayout />
    }
    
    const backUrl = `/sales/${sale_id}`
    const updateActivitity = activity => {
        dispatch(Activity.update(Activity.sanitize(activity), () => {
            history.push(backUrl)
        }))
    }

    return <Layout title="Edit Activity" headerOkIcon="check" onHeaderOkClick={() => form.current.submitForm()} backUrl={backUrl}>
        <SaleBreadcrumbs sale_id={sale_id}>
            <Breadcrumb>Edit Activity</Breadcrumb>
        </SaleBreadcrumbs>
        <View>
            <ActivityForm sale_id={sale_id} activity={activity} onSubmit={updateActivitity} formRef={form} />
        </View>
    </Layout>
}

export default EditActivityView