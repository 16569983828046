import styled from 'styled-components/macro'

const AttachmentBlock = styled.div`
    position: relative;
    text-align: center;
    padding: 0.75rem 0.5rem;
    border-radius: 0.25rem;

    background-color: #2E1760;
`

export default AttachmentBlock
