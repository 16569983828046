import React, { useEffect, useState } from 'react'
import Breadcrumb from './Breadcrumb'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import { Icon, ts } from '..'


export function BreadcrumbInput({ icon, value, onSubmit, placeholder, ...props }) {
    const [controlledValue, setValue] = useState(value)
    useEffect(() => setValue(value), [value])

    const onKeyPress = e => {
        if (e.keyCode === 13) {
            onSubmit(controlledValue)
        }
    }
    const onChange = e => {
        setValue(e.target.value)
    }
    const onClick = () => {
        onSubmit(value)
    }

    return <Breadcrumb right>
        <InputGroup size="sm">
            <FormControl 
                {...props}
                placeholder={ts(placeholder)}
                value={controlledValue}
                onChange={onChange}
                onKeyDown={onKeyPress}
            />
            <InputGroup.Append>
                <Button variant="secondary" onClick={onClick}>
                    <Icon name={icon} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    </Breadcrumb>
}

export default BreadcrumbInput