import _ from 'lodash'
import React from 'react'
import { Job, useAction, useSelector, useFetching } from '../../store'
import { formatFullDate } from '../../util/format'
import { Spinner, Table, THead, TBody, Tr, Th, Td, Ts } from '../ui'
import UserLink from '../user/UserLink'


function CustomerJobList({ customer_id }) {
    useAction(Job.getByCustomer(customer_id))
    const loading = useFetching(Job)
    const jobs = useSelector(Job.select.ordered())

    if (loading) {
        return <Spinner />
    }

    return <Table full-width>
        <THead>
            <Tr>
                <Th><Ts>ID</Ts></Th>
                <Th><Ts>Inkommet</Ts></Th>
                <Th><Ts>Avslutat</Ts></Th>
                <Th><Ts>Category</Ts></Th>
                <Th><Ts>Subject</Ts></Th>
                <Th><Ts>Technician</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(jobs, CustomerJobItem)}
        </TBody>
    </Table>
}

function CustomerJobItem({ source_id, requested_at, closed_at, category, subject, owner }) {
    const opened = formatFullDate(new Date(requested_at))
    const closed = formatFullDate(new Date(closed_at))

    return <Tr>
        <Td>#{source_id}</Td>
        <Td>{opened}</Td>
        <Td>{closed}</Td>
        <Td>{category}</Td>
        <Td>{subject}</Td>
        <Td><UserLink user={owner} /></Td>
    </Tr>
}

export default CustomerJobList
