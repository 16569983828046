import React from 'react'
import { Form } from 'react-bootstrap'
import { Ts } from '../ui'
import { Select } from './Select'


export function SelectField({ label, ...props }) {
    return <Form.Group controlId={props.name}>
        {label && <Form.Label><Ts>{label || ''}</Ts></Form.Label>}
        <Select label={label} {...props} />
    </Form.Group>
}

SelectField.Bool= {
    '': '',
    'true': 'Yes',
    'false': 'No',
}

export default SelectField
