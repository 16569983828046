import { CreateModel } from '../../model'
import { apiGet } from '../../api'
import * as yup from 'yup'


export const Activity = CreateModel({
    id: 'sale_activity',
    url: 'sale/activity',
    schema: yup.object().shape({
        sale_id: yup.number().required(),
        activity_type: yup.string().required(),
        scheduled_at: yup.date().required(),
        notes: yup.string(),
        user_id: yup.number().min(1).required(),
    }),
}, (actions, endpoint) => ({
    getBySale: apiGet({
        url:    id => `sale/${id}/activities`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
}))

export default Activity