import _ from 'lodash'
import fetch from 'isomorphic-fetch'
import getUrl from './url'
import { getJwt, logout } from './jwt'

const defaultFetchOptions = () => ({
    headers: {
        'authorization': 'Bearer ' + getJwt(),
        'content-type':  'application/json',
        'cache-control': 'no-cache',
        'pragma':        'no-cache',
    },
})

async function authfetch(url, options) {
    options = _.merge({ }, defaultFetchOptions(), options)

    if (options.json) {
        options.body = JSON.stringify(options.json)
        delete options.json
    }

    if (options.form) {
        options.body = options.form
        delete options.form
        delete options.headers['content-type']
    }

    if (options.noauth) {
        delete options.headers['authorization']
    }

    // todo: check if external url
    let response = await fetch(getUrl(url), options)

    // other error
    if (response.status !== 200) {
        let message = `Server returned code ${response.status}`
        if (response.status == 401) {
            message = 'Unauthorized'
            logout()
        }
        try {
            let json = await response.json()
            message = json.message
        }
        catch (err) { }
        let e = new Error(message)
        e.status = response.status
        e.redirect = encodeURIComponent(window.location.pathname)
        throw e
    }

    let json = await response.json()

    if (!response) {
        throw new Error("Empty response")
    }
    if (json.ok === false) {
        let e = new Error(json.message)
        e.status = response.status
        throw e
    }

    return json
}

export default authfetch