import _ from 'lodash'
import React from 'react'
import { Customer } from '../../store'
import { Header, Ts, ButtonRow, Icon } from '../ui'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { InputField, SelectField } from '../form'
import PumpBrands from '../../const/brands'

const brandOptions = _.reduce(PumpBrands, (r, brand) => _.set(r, brand.id, brand.name), {})

function CustomerEditor({ customer, onSubmit }) {
    return <Formik
        enableReinitize
        initialValues={customer}
        onSubmit={onSubmit}
        validationSchema={Customer.schema}
    >
    {({ handleSubmit, handleReset }) => <Form onSubmit={handleSubmit} onReset={handleReset} style={{ maxWidth: '600px' }}>
        <Header><Ts>Customer Information</Ts></Header>

        <InputField name="name" label="Name" />
        <InputField name="email" label="Email" />
        <InputField name="phone" label="Phone" />
        <InputField name="pno" label="Personal Number" />
        <InputField name="fortnox" label="Fortnox ID" />
        <InputField name="company" label="Company" />
        <InputField name="orgnr" label="Organization Number" />
        <SelectField name="pump_brand" label="Pump Brand" options={brandOptions} />
        <InputField name="pump_model" label="Pump Model" />
        <InputField name="pump_year" label="Pump Year" />

        <ButtonRow>
            <Button variant="primary" type="submit">
                <Icon name="check" />
                <Ts>Save</Ts>
            </Button>
            <Button variant="secondary" type="reset">
                <Icon name="times" />
                <Ts>Reset</Ts>
            </Button>
        </ButtonRow>
    </Form>}
    </Formik>
}

export default CustomerEditor