const theme = {
	columns: 12,
	paddings: {
        none: 0,
        small: 12,
		default: 24,
		large: 48
	},
	breakpoints: {
		medium: '640px',
		large: '1024px'
	},
	justifys: {
		start: 'flex-start',
		end: 'flex-end',
		between: 'space-between',
		around: 'space-around',
		center: 'center'
	},
	aligns: {
		start: 'flex-start',
		end: 'flex-end',
		stretch: 'stretch',
		baseline: 'baseline',
		center: 'center'
	}
}

export default theme
