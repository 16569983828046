import _ from "lodash";
import { Activity } from "../store";

// state ids
export const STATE_NEW = "new";
export const STATE_ASSIGNED = "contact"; // Tilldelad
export const STATE_BOOKED = "booked"; // Bokad
export const STATE_ONGOING = "ongoing"; // Pågående
export const STATE_DESIGN = "design";
export const STATE_PRICING = "preoffer"; // Prisuppgift
export const STATE_SIGNING = "offer"; // Signering
export const STATE_WON = "won";
export const STATE_COLD = "cold";
export const STATE_LOST = "lost";
export const STATE_INSTALL = "install";

// state list
export const STATES = [
    STATE_NEW,
    STATE_ASSIGNED,
    STATE_ONGOING,
    STATE_BOOKED,
    STATE_PRICING,
    STATE_SIGNING,
    STATE_WON,
    STATE_COLD,
    STATE_LOST,
];

export const StateNames = {
    [STATE_NEW]: "Incoming",
    [STATE_ASSIGNED]: "Assigned",
    [STATE_BOOKED]: "Booked",
    [STATE_ONGOING]: "Ongoing",
    [STATE_PRICING]: "Pricing",
    [STATE_SIGNING]: "Signing",
    [STATE_WON]: "Won",
    [STATE_COLD]: "Cold",
    [STATE_LOST]: "Lost",

    //    [STATE_INSTALL]:  'Installation',
};

export const StateIcons = {
    [STATE_NEW]: "arrow-right",
    [STATE_ASSIGNED]: "star",
    [STATE_ONGOING]: "refresh",
    [STATE_BOOKED]: "calendar-check-o",
    [STATE_PRICING]: "tag",
    [STATE_SIGNING]: "pencil-square-o",
    [STATE_WON]: "trophy",
    [STATE_LOST]: "times",
    [STATE_COLD]: "hourglass-half",
    [STATE_INSTALL]: "truck",
};

export const StateOrder = {};

// state sort ids
const stateSort = {
    "": 0,
    [STATE_NEW]: 1,
    [STATE_ASSIGNED]: 2,
    [STATE_ONGOING]: 3,
    [STATE_DESIGN]: 4,
    [STATE_PRICING]: 5,
    [STATE_SIGNING]: 6,
    [STATE_WON]: 7,
    [STATE_COLD]: 8,
    [STATE_LOST]: 9,

    [STATE_INSTALL]: 7,
};

// returns true if a is before b
export function stateBefore(a, b) {
    if (!_.has(stateSort, a)) throw Error("Illegal state (a) " + a);
    if (!_.has(stateSort, b)) throw Error("Illegal state (b) " + a);

    let a_idx = stateSort[a];
    let b_idx = stateSort[b];
    return a_idx < b_idx;
}

// returns true if a is after b
export function stateAfter(a, b) {
    if (!_.has(stateSort, a)) throw Error("Illegal state (a) " + a);
    if (!_.has(stateSort, b)) throw Error("Illegal state (b) " + b);

    let a_idx = stateSort[a];
    let b_idx = stateSort[b];
    return a_idx > b_idx;
}

export const POST_ACTIVITY = "activity";
export const POST_OFFER = "offer";
export const POST_PERMIT = "permit";
export const POST_COMMENT = "comment";

export const PostNames = {
    [POST_ACTIVITY]: "Activity",
    [POST_OFFER]: "Offer",
    [POST_PERMIT]: "Permit",
};

export const Activities = {
    visit: {
        code: "visit",
        name: "Visit",
        icon: "car",
    },
    call: {
        code: "call",
        name: "Call",
        icon: "phone",
    },
    email: {
        code: "email",
        name: "E-mail",
        icon: "envelope",
    },
    followup: {
        code: "followup",
        name: "Follow up",
        icon: "reply",
    },
    other: {
        code: "other",
        name: "Other",
        icon: "clock-o",
    },
};

export const PostIcons = {
    [POST_COMMENT]: "comment",
    [POST_ACTIVITY]: "car",
    [POST_OFFER]: "file",
    [POST_PERMIT]: "id-card",
};

export const SourceTypes = {
    epost: "Epost",
    support: "Innesälj",
    referens: "Referens",
    service: "Service",
    social_media: "Social Media",
    telefon: "Telefon",
    chat: "Chat",
    magento: "Webformulär",
    event: "Events",
    internal: "Övriga",

    ctc: "CTC",
    bosch: "Bosch",
    nibe: "Nibe",
    comfortzone: "Comfortzone",
    qvantum: "Qvantum",
    mitsubishi: "Mitsubishi",
    toshiba: "Toshiba",
    daikin: "Daikin",
    panasonic: "Panasonic",
    vaillant: "Vaillant",
    veissmann: "Veissmann",
    varmepump_se: "Värmepump.se",
    greenmatch: "Greenmatch",
    external_partners: "Övriga partners",
    purchased_leads: "Övriga köpta",
};

export const AreaTypes = {
    south: "Syd",
    middle: "Mitt",
    west: "Väst",
};

export const PropertyTypes = {
    villa: "Villa",
    radhus: "Radhus",
    kedjehus: "Kedjehus/parhus",
    flerbostad: "Flerbostadshus",
    fritidshus: "Fritidshus",
};
