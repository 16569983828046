import React from 'react'
import styled from 'styled-components/macro'


const IconTag = styled.i`
    ${p => p.color ? `color: ${p.color};` : ''};
    ${p => p.float ? `float: ${p.float};` : ''};

    font-size: ${p => p.size ? p.size : '1'}em !important;

    ${props => props.onClick ? `
    &:hover {
        cursor: pointer;
    }` : ''}
`

export function Icon({ name, spin, fixedWidth, ...otherProps }) {
    let classes = ['fa', 'fa-' + name]
    if (fixedWidth) {
        classes.push('fa-fw')
    }
    if (spin) {
        classes.push('fa-spin')
    }

    const iconClass = classes.join(' ')
    return <IconTag className={iconClass} {...otherProps} />
}

Icon.defaultProps = {
    size: 1,
    spin: false,
    fixedWidth: true,
}

export default Icon
