import React, { useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Comment, useFetching } from '../../../store'
import { Spacer, ButtonRow, Ts, Icon, SubmitButton } from '../../ui'
import { MarkdownField, Persist } from '../../form'


function CommentForm({ sale_id, comment, formRef, onSubmit }) {
    comment.sale_id = sale_id
    const newComment = !comment || !comment.id

    const inputRef = useRef(null)
    useEffect(() => {
        inputRef.current.focus()
    }, [])

    const loading = useFetching(Comment)

    return <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={comment}
        validationSchema={Comment.schema}
        onSubmit={onSubmit}
    >
    <Form>
        {/* {newComment && <Persist name="new-comment-form" />} */}
        <Container fluid>
            <Row>
                <Col>
                    <MarkdownField name="text" label="Comment" rows={10} innerRef={inputRef} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Spacer />
                    <ButtonRow>
                        <SubmitButton submitting={loading} />
                        <Button type="reset" variant="secondary">
                            <Icon name="times" />
                            <Ts>Reset</Ts>
                        </Button>
                    </ButtonRow>
                </Col>
            </Row>
        </Container>
    </Form>
    </Formik>
}

export default CommentForm