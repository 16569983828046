import React from 'react'
import styled from 'styled-components/macro'
import { Link as ReactLink } from 'react-router-dom'

const Link = styled(({ theme, options, ...other }) => <ReactLink {...other} />)`
    cursor: pointer;
	text-decoration: none;
    color : #2C82C9;
    
    &:hover {
        color : #1F5C8D;
        text-decoration: none;
    }
`

export default Link