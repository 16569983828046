const API_VERSION = '2'

export function getHost() {
    let host = process.env.REACT_APP_BACKEND_HOST
    if (host) {
        return host
    }
    return 'internal.jsenergi.com'
}

export function getUrl(endpoint = '') {
    // if a protocol is already specified, it means this is an external url. just return it 
    if(endpoint.indexOf('://') >= 0) {
        return endpoint
    }
    // otherwise, it should be an internal url. add protocol, host and api version
    return `https://${getHost()}/api/${API_VERSION}/${endpoint}`
}

export function getWsUrl() {
    return getUrl('notifications').replace('https', 'wss')
}

export function GetStoreProductUrl(sku) {
    return `https://jsenergi.com/jsenergi/product/?js_sku=${sku}`
}

export default getUrl
