import { CreateModel } from '../../model'
import { apiGet } from '../../api'


export const Address = CreateModel({ 
    id: 'address', 
    url: 'customer/address'
}, actions => {
    return {
        getByCustomer: apiGet({
            url: (id) => `customer/${id}/address/`,
            before: actions.fetch,
            after:  actions.storeMany,
            error:  actions.abort,
        }),
    }
})

export default Address