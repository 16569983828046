import styled, { keyframes } from 'styled-components/macro'

const scaleOut = keyframes`
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
		opacity: 0;
	}
`;

const Spinner = styled.span`
	display: block;
	width: 40px;
	height: 40px;
	margin: 100px auto;
	background-color: #333;
	border-radius: 100%;
	animation: ${scaleOut} 1.0s infinite ease-in-out;
`

export default Spinner
