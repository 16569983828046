import styled from 'styled-components/macro'

const SiteHeaderLogo = styled.div`
	display: block;
	width: 2rem;
    height: 2rem;
    margin-left: 1rem;

    @media screen and (max-width: 32em) {
        display: none;
    }
`

export default SiteHeaderLogo
