import _ from 'lodash'
import React from 'react'
import ts from '../../../util/ts'

// Translated Span
function Ts({ children, multiline, ...replacements }) {
    let text = ''
    if (_.isArray(children)) {
        text = children[0]
    }
    else if (typeof(children) === 'string') {
        text = children
    }
    else if (!children) {
        text = ''
    }
    else {
        throw new Error("Expected text child")
    }

    if (multiline) {
        return <React.Fragment>
            {text.split('\n').map((item, key) => {
                return <span key={key}>{ts(item, replacements)}<br/></span>
            })}
        </React.Fragment>
    }
    return <span>{ts(text, replacements)}</span>
}

Ts.defaultProps = {
    multiline: false,
}

export default Ts
