import styled from 'styled-components/macro'

const MenuBack = styled.div`
    display: none;

	padding: 0 12px;
    user-select: none;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	line-height: 2rem;
	text-transform: uppercase;

	text-align: center;
	width: 52px;

	i {
		margin-right: 0.4em;
    }

    @media screen and (max-width: 32em) {
        display: block;
    }
`

export default MenuBack