import { CreateModel } from '../../model'
import { apiGet } from '../../api'
import * as yup from 'yup'


export const Comment = CreateModel({
    id: 'sale_comment',
    url: 'sale/comment',
    schema: yup.object().shape({
        sale_id: yup.number()
            .required(),
    }),
}, (actions, endpoint) => ({
    getBySale: apiGet({
        url:    id => `sale/${id}/comments`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
}))

export default Comment