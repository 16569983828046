import _ from 'lodash'
import Swedish from '../lang/swedish.js'

var LANGUAGE = Swedish

export default function ts(text, replacements={}) {
    // lookup translation
    let translated = text;
    if (LANGUAGE.hasOwnProperty(text)) {
        translated = LANGUAGE[text]
    }

    // replacements
    return _.reduce(replacements, (text, replacement, key) => {
        return text.replace(`:${key}:`, replacement)
    }, translated)
}
