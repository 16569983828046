import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Link } from '../ui'
import { Group, useSelector } from '../../store'

const GLink = styled(Link)`
    + a {
        &::before {
            content: ', ';
            color: black;
        }
    }
`


export const GroupLink = ({ id }) => {
    const group = useSelector(Group.select.id(id))
    const href = `/groups/${id}`
    const name = _.get(group, 'name', `Group ${id}`)
    return <GLink to={href}>{name}</GLink>
}

export default GroupLink
