import { CreateModel } from '../../model'
import { apiGet } from '../../api'
import * as yup from 'yup'


export const File = CreateModel({
    id: 'sale_file',
    url: 'sale/file',
    schema: yup.object().shape({
        type: yup.string()
            .required(),
        sale_id: yup.number()
            .required(),
    }),
}, (actions, endpoint) => ({
    getBySale: apiGet({
        url:    id => `sale/${id}/files`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),
}))

export default File