import _ from 'lodash'
import React from 'react'
import CommentPost from '../comment/CommentPost'
import ActivityPost from '../activity/ActivityPost'
import ImagePost from '../image/ImagePost'
import FilePost from '../file/FilePost'
import StateChange from './StateChange'


const componentMap = {
    'comment':  CommentPost,
    'activity': ActivityPost,
    'image':    ImagePost,
    'file':     FilePost,
    'state':    StateChange,
}

function getPostComponent(type) {
    // ensure the feed item type is defined
    if (!_.has(componentMap, type)) {
        throw new Error(`Unknown component type: ${type}`)
    }
    return componentMap[type]
}

export function Post({ ...post }) {
    const Component = getPostComponent(post.post_type)
    return <Component {...post} />
}

export default Post