import React from 'react'

import { Row, Column } from './'

function Grid({ children, padding }) {
    return <Row reset padding={padding}>
        <Column>
            {children}
        </Column>
    </Row>
}

export default Grid
