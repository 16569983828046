import _ from "lodash";
import React from "react";
import { ts } from "../ui";
import { SelectField } from "../form";

function UserSelectField({ name, label, users }) {
    return (
        <SelectField
            name={name}
            label={label}
            options={users}
            optionKey="id"
            optionValue="name"
        >
            <option value={0} key={0}>
                {ts("Nobody")}
            </option>
        </SelectField>
    );
}

export default UserSelectField;
