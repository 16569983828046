import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

// old style containers
import InvoicePaymentView from './components/invoicePayment/InvoicePaymentView'

// updated routes
import { HomeView } from './components/home'
import { MenuView } from './components/menu'
import { MapView } from './components/map'
import { NotFoundView } from './components/errors'
import { 
    DashboardView, 
    AppDashboardView, 
} from './components/dashboard'
import { CustomerListView } from './components/customers'
import { CustomerSearchView } from './components/customer_search'
import {
    CustomerView,
    CustomerEditView,
} from './components/customer'
import {
    OrderView,
} from './components/order'
import {
    GroupView,
    GroupListView,
    NewGroupView,
} from './components/group'
import {
    UserView,
    UserListView,
    NewUserView,
} from './components/user'
import {
    SaleView,
    NewLeadView,
    LeadEditorView,
    NewSalePostView,
    NewCommentView,
    EditCommentView,
    NewImageView,
    NewActivityView,
    EditActivityView,
    GalleryView,
    NewFileView,
} from './components/sale'
import {
    BoardView,
    SaleSearchView,
    ExpandedSalesView,
} from './components/sales'
import { LoginView, LogoutView } from './components/login'
import { StatsView } from './components/stats'
import { getLocalUser } from './util/jwt'

function AuthRoute({ component, ...rest }) {
    let Component = component
    let user = getLocalUser()
    let isAuthenticated = user !== false && user.id > 0
    return <Route {...rest} render={(props) => {
        if (!isAuthenticated) {
            return <Redirect to="/login" />
        }
        return <Component {...props} />
    }} />
}

function AuthedRouter() {
    return <Switch>
        <AuthRoute exact path="/" component={HomeView} />
        <AuthRoute path="/home" component={HomeView} />
        <AuthRoute path="/menu" component={MenuView} />
        <AuthRoute path="/logout" component={LogoutView} />
        <AuthRoute path="/map" component={MapView} />

        <AuthRoute path="/appdash" component={AppDashboardView} />
        <AuthRoute path="/stats" component={StatsView} />

        <AuthRoute path="/customer/:id/edit" component={CustomerEditView} />
        <AuthRoute path="/customer/:id" component={CustomerView} />
        <AuthRoute path="/customers" component={CustomerSearchView} />
        <AuthRoute path="/customerlist" component={CustomerListView} />
        <AuthRoute path="/order/:id" component={OrderView} />

        <AuthRoute path="/users/new" component={NewUserView} />
        <AuthRoute path="/users/:id" component={UserView} />
        <AuthRoute path="/users" component={UserListView} />
        <AuthRoute path="/groups/new" component={NewGroupView} />
        <AuthRoute path="/groups/:id" component={GroupView} />
        <AuthRoute path="/groups" component={GroupListView} />

        <AuthRoute path="/sales/search/:keywords" component={SaleSearchView} />
        <AuthRoute path="/sales/search" component={SaleSearchView} />
        <AuthRoute path="/sales/state/:state" component={ExpandedSalesView} />
        <AuthRoute path="/sales/new" component={NewLeadView} />
        <AuthRoute path="/sales/:sale_id/edit" component={LeadEditorView} />
        <AuthRoute path="/sales/:sale_id/post" component={NewSalePostView} />
        <AuthRoute path="/sales/:sale_id/comment/:comment_id" component={EditCommentView} />
        <AuthRoute path="/sales/:sale_id/comment" component={NewCommentView} />
        <AuthRoute path="/sales/:sale_id/image" component={NewImageView} />
        <AuthRoute path="/sales/:sale_id/activity/:activity_id" component={EditActivityView} />
        <AuthRoute path="/sales/:sale_id/activity" component={NewActivityView} />
        <AuthRoute path="/sales/:sale_id/file" component={NewFileView} />
        <AuthRoute path="/sales/:sale_id" component={SaleView} />
        <AuthRoute path="/sales" component={BoardView} />

        <Route path="*" component={NotFoundView} />
    </Switch>
}

export function AppRouter() {
    return <BrowserRouter>
        <Switch>
        <Route path="/login" component={LoginView} />
        <Route path="/dashboard" component={DashboardView} />
        <Route path="/invoice/:order" component={InvoicePaymentView} />
        <Route path="/sales/:sale_id/gallery" component={GalleryView} />
        <Route path="*" component={AuthedRouter} />
        </Switch>
    </BrowserRouter>
}
