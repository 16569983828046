import React from 'react'
import { Formik } from 'formik'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { Spacer, ButtonRow, Ts, Icon, SubmitButton } from '../../ui'
import { UploadField } from '../../form'
import { File, useFetching } from '../../../store'
import * as yup from 'yup'

const schema = yup.object({
    files: yup.string().required()
})


function ImageForm({ path, formRef, onSubmit }) {
    const loading = useFetching(File)
    return <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{}}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
    {({ handleSubmit, handleReset }) => <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Container fluid>
            <Row>
                <Col>
                    <UploadField name="files" path={path} label="Image" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Spacer />
                    <ButtonRow>
                        <SubmitButton submitting={loading} />
                        <Button type="reset" variant="secondary">
                            <Icon name="times" />
                            <Ts>Reset</Ts>
                        </Button>
                   </ButtonRow>
                </Col>
            </Row>
        </Container>
    </Form>}
    </Formik>
}

export default ImageForm