import _ from 'lodash'
import React from 'react'
import { STATE_INSTALL, STATE_WON } from '../../../const'
import Post from './Post'
import InstallPrompt from './InstallPrompt'
import InstallCard from './InstallCard'
import EmptyFeed from './EmptyFeed'


export const sortByCreatedTime = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at)
} 
export const sortByScheduledTime = (a, b) => {
    return new Date(b.scheduled_at) - new Date(a.scheduled_at)
} 

export const addFeedTypeTag = tag => {
    return item => _.set(item, 'post_type', tag)
}

export function Feed({ sale, editable, comments, activities }) {
    const todoActivities = _.filter(activities, activity => !activity.completed).sort(sortByScheduledTime)
    const completedActivitites = _.filter(activities, activity => activity.completed)
    const stateChanges = _.map(sale.history, history => ({
        ...history,
        created_at: history.time,
        post_type: 'state',
    }))

    const history = [
        ...comments,
        ...completedActivitites,
        ...stateChanges,
    ].sort(sortByCreatedTime)

    // map to components
    let feed = _.map([...todoActivities, ...history], post => <Post key={`${post.type}_${post.id}`} {...post} editable={editable} />)

    // add virtual cards
    if (sale.state === STATE_INSTALL) {
        feed = [ <InstallCard sale={sale} key="install_card" />, ...feed ]
    }
    if (sale.state === STATE_WON) {
        feed = [ <InstallPrompt sale={sale} key="install_prompt" />, ...feed ]
    }

    // empty feed message
    if (!feed.length) {
        return <EmptyFeed />
    }

    return feed
}

export default Feed