import React from "react";
import Breadcrumb from "./Breadcrumb";
import { Button } from "react-bootstrap";
import { Ts } from "..";
import Icon from "../icon/Icon";

export function BreadcrumbButton({ title, icon, loading, ...otherProps }) {
    return (
        <Breadcrumb right>
            <Button
                size="sm"
                variant="secondary"
                {...otherProps}
                disabled={loading}
            >
                {icon && (
                    <Icon name={loading ? "spinner" : icon} spin={loading} />
                )}
                <Ts>{title}</Ts>
            </Button>
        </Breadcrumb>
    );
}

export default BreadcrumbButton;
