import React from 'react'
import styled from 'styled-components/macro'
import ReactMarkdown from 'react-markdown'

const StyledMarkdown = styled.div`
    word-break: break-word;

    h1 { font-size: 2.0rem; padding-bottom: 0.5rem; }
    h2 { font-size: 1.8rem; padding-bottom: 0.4rem; }
    h3 { font-size: 1.6rem; padding-bottom: 0.3rem; }
    h4 { font-size: 1.4rem; padding-bottom: 0.2rem; }
    h5 { font-size: 1.2rem; padding-bottom: 0.1rem; }

    p {
        line-height: 1.5em;
        padding: 0;
        margin: 0;
        margin-bottom: 0.75em;
    }

    strong {
        font-weight: bold;
    }

    ul {
        list-style-type: disc;
        padding-left: 30px;
    }

    li {
        padding-bottom: 0.5em;
    }

    img {
        padding: 0.5em 0;
    }

    h1, h2, h3, h4, h5, h6,
    p, ul, li, img {
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
`

function Markdown({ source }) {
    return <StyledMarkdown>
        <ReactMarkdown source={source} />
    </StyledMarkdown>
}

export default Markdown
