import styled from 'styled-components/macro'

export const PreviewImage = styled.img`
    width: 350px;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 32em) {
        width: 100% !important;
    }
`

export default PreviewImage