import _ from 'lodash'
import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useField } from 'formik'
import { Ts, ts } from '../ui'


export function Input({ label, placeholder, prefix, suffix, onChange, skipValidate, ...props }) {
    const [field, meta] = useField(props)
    
    return <React.Fragment>
        <InputGroup>
            {prefix && <InputGroup.Prepend>
                {typeof(prefix) === 'string' && <InputGroup.Text>{prefix}</InputGroup.Text>}
                {typeof(prefix) !== 'string' && prefix}
            </InputGroup.Prepend>}
            <Form.Control 
                isValid={!skipValidate && meta.touched && !meta.error}
                isInvalid={!skipValidate && meta.error}
                placeholder={ts(placeholder || label)}
                {...field}
                {...props}
                value={field.value || ''}
                onChange={e => {
                    field.onChange(e)
                    if (_.isFunction(onChange)) {
                        onChange(e.currentTarget.value)
                    }
                }}
            />
            {suffix && <InputGroup.Append>
                {typeof(suffix) === 'string' && <InputGroup.Text>{suffix}</InputGroup.Text>}
                {typeof(suffix) !== 'string' && suffix}
            </InputGroup.Append>}
        </InputGroup>
        {meta.error && <Form.Control.Feedback type="invalid"><Ts>{''+meta.error}</Ts></Form.Control.Feedback>}
    </React.Fragment>
}

export default Input