import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useRef } from "react"
import { useFormikContext } from "formik"
import isEqual from "react-fast-compare"

export const Persist = ({ name, clearOnSubmit, ignore }) => {
    const { values, setValues, isSubmitting, isValidating } = useFormikContext()
    const prefValuesRef = useRef()

    const key = `form.${name}`
    const onSave = (values) => {
        values = _.omit(values, ignore)
        window.localStorage.setItem(key, JSON.stringify(values))
    }

    const debouncedOnSave = _.debounce(onSave, 300)

    useEffect(() => {
        const savedForm = window.localStorage.getItem(key)

        if (savedForm) {
            const parsedForm = JSON.parse(savedForm)

            prefValuesRef.current = parsedForm
            setValues(parsedForm)
        }
    }, [name, setValues])

    useEffect(() => {
        if (!isEqual(prefValuesRef.current, values)) {
            debouncedOnSave(values)
        }
    })

    useEffect(() => {
        // clear form on submit
        if (clearOnSubmit && !isValidating && isSubmitting) {
            setValues({})
            onSave({})
        }
    }, [isSubmitting, isValidating])

    useEffect(() => {
        prefValuesRef.current = values
    })

    return null
}

Persist.propTypes = {
    name: PropTypes.string.isRequired,
    clearOnSubmit: PropTypes.bool.isRequired,
    ignore: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Persist.defaultProps = {
    clearOnSubmit: true,
    ignore: [],
}

export default Persist
