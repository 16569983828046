import React from 'react'
import PropTypes from 'prop-types'

import StyledColumn from './styled/Column'
import theme from './theme'

function Column({
	theme,
	hideWhenCollapsed,
	padding,
	order,
	size,
	grow,
	shrink,
	nowrap,
	basis,
	...other
}) {
	let style = {
		order: order
	}
	let className = []

	if (!grow && !shrink) {
		style['flexBasis'] = size * (100 / theme.columns) + '%'
	}

	if(hideWhenCollapsed) {
		className.push('hide-when-collapsed')
	}

	const options = {
		padding,
		grow,
		shrink,
		nowrap,
		basis
	}

	return  <StyledColumn {...{style, className, options, ...other}} />
}

Column.defaultProps = {
	theme,
	padding: 'default',
	size: 12,
	order: null,
	grow: false,
	shrink: false,
	nowrap: false
}

Column.propTypes = {
	padding: PropTypes.string,
	order: PropTypes.number,
	size: PropTypes.number,
	grow: PropTypes.bool,
	shrink: PropTypes.bool,
	nowrap: PropTypes.bool
}

export default Column
