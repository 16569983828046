const Swedish = {
    Name: "Namn",
    Address: "Adress",
    Street: "Adress",
    City: "Ort",
    "Post Code": "Postnummer",
    Postcode: "Postnummer",
    Phone: "Telefon",
    Email: "Epost",
    Country: "Land",
    Company: "Företag",
    "Personal number": "Personnummer",
    Reference: "Referens",
    Addresses: "Addresser",
    "Address type": "Addresstyp",
    Select: "Välj",
    "Select address": "Välj adress",
    billing: "Faktureringsadress",
    shipping: "Leveransaddress",
    "Select address to edit": "Välj en adress att redigera",
    Edit: "Redigera",
    "Edit Customer": "Redigera kund",
    Count: "Antal",
    Discount: "Rabatt",
    Tax: "Moms",
    Qty: "Antal",
    Item: "Artikel",
    Currency: "Valuta",
    Time: "Tid",
    Source: "Källa",
    Shipping: "Frakt",
    Orders: "Ordrar",
    Group: "Grupp",
    "Select Group": "Välj grupp",
    Delete: "Radera",
    Password: "Lösenord",
    "Change Password": "Ändra lösenord",
    "Repeat Password": "Upprepa lösenord",
    "The passwords you entered do not match": "Du har angett olika lösenord",
    Query: "Sökord",
    Done: "Klar",
    "Calculated Permissions": "Beräknad Behörighet",

    Page: "Sida",
    of: "av",
    Search: "Sök",
    Model: "Modell",
    Brand: "Märke",

    Yes: "Ja",
    No: "Nej",
    "Are you sure?": "Är du säker?",
    Save: "Spara",
    Cancel: "Avbryt",
    Close: "Stäng",
    New: "Ny",
    "Required field": "Obligatoriskt fält",
    "New User": "Ny Användare",

    Home: "Hem",
    "Customer Map": "Kundkarta",
    Users: "Användare",
    User: "Användare",
    Group: "Grupp",
    Groups: "Grupper",
    Reports: "Rapporter",
    Report: "Rapport",
    Customer: "Kund",
    Customers: "Kunder",
    customers: "kunder",
    Logout: "Logga ut",
    Sales: "Försäljning",
    Sale: "Sälj",
    "Edit Sale": "Redigera case",
    Servers: "Servrar",
    Server: "Server",
    Menu: "Meny",
    "Not Found": "Saknas",
    "The page at :location: does not exist.":
        "Sidan :location: existerar inte.",

    Clear: "Rensa",
    Date: "Datum",
    Submit: "Spara",
    Reset: "Återställ",
    Contact: "Kontakt",
    Incoming: "Inkommande",
    Lead: "Lead",
    lead: "Lead",
    Assign: "Tilldela",
    assign: "Tilldela",
    "Not assigned": "Ej tilldelad",
    Visit: "Besök",
    visit: "Besök",
    Activity: "Aktivitet",
    activity: "aktivitet",
    Call: "Samtal",
    call: "Samtal",
    "Follow up": "Uppföljning",
    "follow up": "uppföljning",
    "scheduled at": "inbokat den",
    Offer: "Offert",
    offer: "Offert",
    "Pre-Offer": "Föroffert",
    preoffer: "Föroffert",
    Permit: "Miljöansökan",
    permit: "Miljöansökan",
    Accept: "Acceptera",
    accept: "Acceptera",
    Decline: "Neka",
    decline: "neka",
    Approve: "Godkänn",
    Approved: "Godkänd",
    Reject: "Avslå",
    Rejected: "Avslagen",
    Offered: "Offererad",
    Confirmed: "Bekräftad",
    Confirmation: "Orderbekräftelse",
    Accepted: "Accepterad",
    Declined: "Nekad",
    Won: "Vunnen",
    won: "Vunnen",
    Lost: "Förlorad",
    lost: "Förlorad",
    Price: "Pris",
    Margin: "Marginal",
    margin: "marginal",
    Revenue: "Affärsvärde",
    "Confirm Order": "Bekräfta Order",
    "Confirmation sent": "Orderbekräftelse skickad",
    "Drop files here": "Släpp filer här",
    "Take a photo": "Ta en bild",
    "Choose existing photo": "Välj en bild",
    "New Comment": "Ny Kommentar",
    "Edit Comment": "Ändra Kommentar",
    Comment: "Kommentar",
    Image: "Bild",
    File: "Fil",
    "Upload File": "Ladda upp fil",
    "New Visit": "Nytt Besök",
    "New Activity": "Ny Aktivitet",
    "New Offer": "Ny Offert",
    "New Permit": "Ny Miljöansökan",
    "New Sale": "Nytt Case",
    Assignee: "Tilldelad",
    "Upload Image": "Ladda upp bild",
    "No file uploaded": "Ingen fil uppladdad",
    "Offer PDF": "Offert PDF",
    "Offered Itemd": "Offererade Artiklar",
    "Click to enlarge": "Klicka för att förstora",
    "Click to minimize": "Klicka för att minimera",
    "Scheduled at": "Bokat den",
    Event: "Händelse",
    Cold: "Vilande",
    "Search Customer": "Sök Kund",
    "Existing Customer": "Existerande Kund",
    "New Customer": "Ny Kund",
    Assigned: "Tilldelad",
    Ongoing: "Pågående",
    Booked: "Bokad",
    Pricing: "Prisuppgift",
    Signing: "Signering",
    Create: "Skapa",
    Add: "Lägg till",
    Other: "Annat",
    Nobody: "Ingen",
    "Create Sale": "Skapa säljcase",

    Template: "Mall",
    "New Template": "Ny Mall",
    "Edit Template": "Redigera Mall",
    "Offer Template": "Offertmall",
    "New Offer Template": "Ny Offertmall",
    "Offer Templates": "Offertmallar",
    Services: "Tjänster",
    Materials: "Material",
    Addons: "Tillval",
    Upsells: "Upsells",
    Profit: "Vinst",
    Pumps: "Pumpar",
    "Add Row": "Ny Rad",
    "Edit Offer": "Redigera Offert",
    "Offered Items": "Offererade Artiklar",
    Publish: "Publicera",
    "Approve Design": "Godkänn Design",
    "Design approved by": "Design godkänd av",
    "Request Review": "Begär Design",

    "Customer Information": "Kunduppgifter",
    "Detailed Information": "Detaljer",
    "Interested in": "Intresserad av",
    Notes: "Anteckningar",
    Consumption: "Total förbrukning",
    "Built year": "Byggår",
    "Living Area": "Uppvärmd yta",
    "kWh/year": "kWh/år",
    "Ceiling Height": "Takhöjd",
    "Pipe diameter: Cold": "Rördiameter Köldbärare",
    "Pipe diameter: Hot": "Rördiameter Värmebärare",
    "Hot water radiator": "Hetvattenelement",
    Chimney: "Skorsten på panna",
    "Condensed water": "Kondensvatten längd",
    ROT: "ROT-avdrag",
    "Shared ROT": "Delat ROT-avdrag",
    Financing: "Finansiering",
    "Municipal Water": "Kommunalt vatten",
    Cellar: "Källare",
    "Fuse Amperage": "Huvudsäkring",
    "Add Fusing": "Uppsäkring",
    "Grid Owner": "Nätägare",
    "Grid ID": "Anläggnings-ID",
    "Available switchboard space": "Plats i elcentral",
    RCCB: "Jordfelsbrytare",
    Deconstruction: "Demontering",
    Removal: "Bortförsling",
    "Ground work": "Markarbete",
    "Other Information": "Övrig information",
    Outside: "Ute",
    Inside: "Inne",
    "Electrician Required": "Elektriker behövs",
    "Switchboard placement": "Elcentral placering",
    "Cable length": "Kabellängd",
    "Installation type": "Typ av installation",
    "New Installation": "Nyinstallation",
    Replacement: "Utbyte",
    Color: "Färg",
    Monitoring: "Övervakning",
    "Magnet Filter": "Magnetfilter",
    "Vent cleaning": "Ventilationsrengörning",
    "Pipe Turns": "Antal svängar",
    "Drill holes": "Antal borrhål",
    "Facade type": "Typ av fasad",
    "Extra accessories": "Extra tillbehör",
    "Extra VVB": "Extra VVB",
    Stand: "Stativ",
    "Wall stand": "Väggstativ",
    "Ground stand": "Markstativ",
    Radiators: "Radiatorer",
    Foundation: "Fundament",
    "Electrical Work": "Elarbete",
    Building: "Fastighet",
    Installation: "Installation",
    Notes: "Anteckningar",
    persons: "personer",
    "Household Size": "Antal personer i hushållet",
    "Property Type": "Typ av fastighet",
    Directions: "Vägbeskrivning",
    Overview: "Överblick",
    Pictures: "Bilder",
    Documents: "Dokument",
    "Nothing to show": "Inget att visa",
    Priority: "Prioritet",
    "Changed state from": "Ändrade status från",
    to: "till",

    Mon: "Mån",
    Monday: "Måndag",
    Tue: "Tis",
    Tuesday: "Tisdag",
    Wed: "Ons",
    Wednesday: "Onsdag",
    Thu: "Tor",
    Thursday: "Torsdag",
    Fri: "Fre",
    Friday: "Fredag",
    Sat: "Lör",
    Saturday: "Lördag",
    Sun: "Sön",
    Sunday: "Söndag",
    January: "Januari",
    Febuary: "Februari",
    March: "Mars",
    April: "April",
    May: "Maj",
    June: "Juni",
    July: "Juli",
    August: "Augusti",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "December",
    Week: "Vecka",
    week: "vecka",
    Month: "Månad",
    month: "månad",
    Sum: "Summa",
    "Select Week": "Välj vecka",

    // rapporter
    Profit: "Vinst",
    "Week Comparison": "Veckojämförelse",
    Compare: "Jämför",
    With: "Med",
    "Compared Month Average": "Genomsnitt Jämförd Månad",
    Improvement: "Förbättring",
    "Monthly Sales": "Månadsförsäljning",
    "Monthly Sales by SKU": "Månadsförsäljning per SKU",
    Pumps: "Värmepumpar",
    "Spare Parts": "Reservdelar",
    "Logarithmic Scale": "Logaritmisk skala",
    "Weekly Sales by Store": "Veckoförsäljning per site",
    year: "år",
    years: "år",
    Range: "Intervall",
    Bestsellers: "Bästsäljare",
    "Competitor Products": "Prisjakt",
    Results: "Resultat",
    "Daily Sales": "Försäljning per dag",
    "Daily Orders": "Ordrar per dag",
    "Brand Sales per Store": "Försäljning per märke per store view",
    "Brand Orders per Store": "Ordrar per märke per store view",

    // produktregister
    Registry: "Register",
    "New Product": "Ny Produkt",
    "Edit Products": "Redigera Produkter",
    Product: "Produkt",
    Products: "Produkter",
    Type: "Typ",
    Description: "Beskrivning",
    Preview: "Förhandsgranska",
    Brand: "Tillverkare",
    Brands: "Tillverkare",
    "New Brand": "Ny Tillverkare",
    "Edit Brands": "Redigera Tillverkare",
    Unknown: "Okänt",

    Jobs: "Jobb",
    Category: "Kategori",
    Subject: "Rubrik",
    Technician: "Tekniker",

    Error: "Fel",
    "You can only edit your own comments":
        "Du kan endast redigera dina egna kommentarer.",
    Edited: "Redigerad",
};

export default Swedish;
