import styled from 'styled-components/macro'

const TypeLabel = styled.label`
    font-weight: bold;
    position: absolute;
    top: 2.2rem;
    font-size: 0.8rem;
    left: 0;
    right: 0;
    text-align: center;
    color: #2E1760;
    user-select: none;
    cursor: pointer;
`

export default TypeLabel
