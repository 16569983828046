import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Drawer, Ts } from '../../ui'
import { Input, Select, Span, FieldRequires, Textarea } from '../../form'
import { useField } from 'formik'
import FieldDefs from '../../../const/crm_fields'

export const FieldTable = styled.table`
    width: 100%;

    th {
        width: 50%;
    }
    td, th {
        padding: 0.5em;
        vertical-align: middle;
        line-height: 1;
    }
    label {
        margin-bottom: 0;
    }

    // striped
    tr.field-row:nth-child(2n) {
        background-color: #f7f7f7;
    }
`

export function FieldTableRow({ label, children, valign = 'top' }) {
    return <tr className="field-row">
        <th style={{verticalAlign: valign}}>
            <label><Ts>{label}</Ts></label>
        </th>
        <td>
            {children}
        </td>
    </tr>
}

export function CrmFields({ render }) {
    return _.map(FieldDefs, (group, key) => {
        return <CrmFieldGroup
            key={key}
            name={key}
            render={render}
            {...group}
        />
    })
}

CrmFields.defaultProps = {
    render: CrmField,
}

function CrmFieldGroup({ name, title, fields, render }) {
    return <Drawer name={name} title={title}>
        <FieldTable>
            <tbody>
            {_.map(fields, (field, key) => React.createElement(render, {
                key,
                name: `${name}.${key}`,
                ...field,
            }))}
            </tbody>
        </FieldTable>
    </Drawer>
}

export function CrmField({ type, requires, ...props }) {
    const Component = (() => {
        switch(type) {
            case 'divider':
                return Divider
            case 'text':
                return InputField
            case 'textarea':
                return TextareaField
            case 'select':
                return SelectField
            default:
                throw new Error(`Unknown field type ${type}`)
        }
    })()
    return <FieldRequires {...requires}>
        <Component {...props} />
    </FieldRequires>
}

export function CrmFieldDisplay({ type, requires, ...props }) {
    const Component = (() => {
        switch(type) {
            case 'divider':
                return Divider
            case 'text':
            case 'select':
            case 'textarea':
                return DisplayField
            default:
                throw new Error(`Unknown field type ${type}`)
        }
    })()
    return <FieldRequires {...requires}>
        <Component {...props} />
    </FieldRequires>
}

const CrmDivider = styled.h4`
    font-size: 1.2rem;
    margin-top: 0.75rem;
    margin-bottom: 0rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.25em;
`

function Divider({ label }) {
    return <tr>
        <th colSpan={2}>
            <CrmDivider><Ts>{label}</Ts></CrmDivider>
        </th>
    </tr>
}

function SelectField({ label, ...props }) {
    return <FieldTableRow label={label}>
            <Select {...props} />
    </FieldTableRow>
}

function InputField({ label, ...props }) {
    return <FieldTableRow label={label}>
            <Input {...props} />
    </FieldTableRow>
}

function TextareaField({ label, ...props }) {
    return <FieldTableRow label={label} valign="top">
            <Textarea {...props} />
    </FieldTableRow>
}

function DisplayField({ label, ...props }) {
    const [field] = useField(props)
    if (!field.value) {
        return null
    }
    return <FieldTableRow label={label}>
            <Span {...props} />
    </FieldTableRow>
}


export default CrmFields
