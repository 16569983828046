import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import theme from '../theme'

const Column = styled.div`
    flex: 1;
    background: ${p => p.background};

	${props => props.options.grow && `
		flex-grow: 1;
	`}

	${props => props.options.shrink && `
		flex-shrink: 1;
		flex: 0;
	`}

	${props => props.options.nowrap && `
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	`}

	${props => props.theme.paddings[props.options.padding] && `
		padding: ${props.theme.paddings[props.options.padding] / 2}px;
	`}

	${props => props.options.basis && `
		flex-basis: ${props.options.basis};
	`}
`

Column.defaultProps = { 
    theme,
    background: 'none',
}

Column.propTypes = {
	options: PropTypes.shape({
		padding: PropTypes.string.isRequired,
		grow:    PropTypes.bool,
		shrink:  PropTypes.bool,
		nowrap:  PropTypes.bool,
	}).isRequired,
	theme: PropTypes.shape({
		paddings: PropTypes.objectOf(PropTypes.number).isRequired,
	}).isRequired
}

export default Column
