import _ from 'lodash'
import React from 'react'
import { Header, Ts } from '../ui'
import { Container, Row, Col } from 'react-bootstrap'
import { Permission } from '../../const'
import { CheckField } from '../form'



export function PermissionEditor({ name, disabled }) {
    const size = {
        xs: 12,
        sm: 6,
        md: 4,
    }
    function CapField({ name: fieldName, ...props }) {
        return <CheckField name={`${name}.${fieldName}`} {...props} disabled={disabled} />
    }
    return <Container fluid>
        <Row>
            <Col {...size}>
                <Header size="small"><Ts>Customer</Ts></Header>
                <CapField name={Permission.Customer.View} label="View Customer" />
                <CapField name={Permission.Customer.Edit} label="Edit Customer" />
                <CapField name={Permission.Customer.Delete} label="Delete Customer" />
            </Col>
            <Col {...size}>
                <Header size="small"><Ts>Order</Ts></Header>
                <CapField name={Permission.Order.New} label="New Order" />
                <CapField name={Permission.Order.View} label="View Order" />
                <CapField name={Permission.Order.Invoice} label="Create Fortnox Invoice" />
            </Col>
            <Col {...size}>
                <Header size="small"><Ts>CRM</Ts></Header>
                <CapField name={Permission.Sale.New} label="New Sale" />
                <CapField name={Permission.Sale.View} label="View Sale" />
                <CapField name={Permission.Sale.Edit} label="Edit Sale" />
                <CapField name={Permission.Sale.Delete} label="Delete Sale" />
                <CapField name={Permission.Sale.Handle} label="Handle Sale" />
                <CapField name={Permission.Sale.Overview} label="Overview" />
                <CapField name={Permission.Sale.Design} label="Design" />
            </Col>
            <Col {...size}>
                <Header size="small"><Ts>Statistik</Ts></Header>
                <CapField name={Permission.Stats.Reports} label="View Reports" />
                <CapField name={Permission.Stats.Stats} label="View Stats" />
                <CapField name={Permission.Stats.Edit} label="Update Stats" />
            </Col>
            <Col {...size}>
                <Header size="small"><Ts>Group</Ts></Header>
                <CapField name={Permission.Group.New} label="New Group" />
                <CapField name={Permission.Group.View} label="View Group" />
                <CapField name={Permission.Group.Edit} label="Edit Group" />
                <CapField name={Permission.Group.Delete} label="Delete Group" />
            </Col>
            <Col {...size}>
                <Header size="small"><Ts>Users</Ts></Header>
                <CapField name={Permission.User.Login} label="Login" />
                <CapField name={Permission.User.New} label="New User" />
                <CapField name={Permission.User.View} label="View User" />
                <CapField name={Permission.User.Edit} label="Edit User" />
                <CapField name={Permission.User.Delete} label="Delete User" />
                <CapField name={Permission.User.Upload} label="Upload Files" />
            </Col>
        </Row>
    </Container>
}

export default PermissionEditor
