import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Link } from '../../ui'
import TypeBadge from './TypeBadge'
import SourceBadge from './SourceBadge'
import PriorityBadge from './PriorityBadge'
import SaleLocation from './SaleLocation'
import SaleOwner from './SaleOwner'


export function Card({ sale }) {
    const { id, name, desired_type, priority } = sale
    const link = `/sales/${id}`
    const highlight = sale.source === 'ikea'

    return <StyledCard highlight={highlight}>
        <CardRow>
            <CardID>${id}</CardID>
            <TypeBadge code={desired_type} />
        </CardRow>

        <CardRow>
            <CardLink to={link}>{name}</CardLink>
            <PriorityBadge level={priority} />
        </CardRow>

        <CardRow>
            <SaleLocation sale={sale} />
            <SaleOwner sale={sale} />
        </CardRow>
    </StyledCard>
}

export const StyledCard = styled.li`
    padding: 0.5rem;
    border-bottom: 3px solid #eaeaea;

    &:last-child {
        border-bottom: none;
    }

    ${p => p.highlight ? `
        background-color: #fff9df;
    ` : ''}
`

export const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const CardLink = styled(Link)`
    flex-grow: 1;
    padding: 0.25em 0;
    line-height: 1.2em;
`

export const CardID = styled.span`
    flex-grow: 1;
    font-size: 0.9em;
    font-weight: bold;
`

export default Card
