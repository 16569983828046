import React from 'react'
import { RowColumn, Button } from '../../ui'
import Page from '../Page'

export function PaymentErrorPage({ error, retry }) {
    return <Page description="Payment error">
        <RowColumn>
            <p>An error occured while processing your payment.</p>
        </RowColumn>
        <RowColumn>
            <p>The messages returned was:<br/>{error}</p>
        </RowColumn>
        <RowColumn>
            <p>Try again or <a href="mailto:info@jsenergi.com">contact us</a> if the problem persists.</p>
        </RowColumn>
        <RowColumn>
            <Button onClick={retry} color="primary" icon="chevron-left">
                Try again
            </Button>
        </RowColumn>
    </Page>
}

export default PaymentErrorPage