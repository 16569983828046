import _ from 'lodash'
import React from 'react'
import { Ts, ButtonRow, Icon } from '../ui'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { InputField } from '../form'


export function AddressEditor({ address, onSubmit }) {
    return <Formik
        enableReinitialize
        initialValues={address}
        onSubmit={onSubmit}
    >
    {({ handleSubmit, handleReset }) => <Form onSubmit={handleSubmit} onReset={handleReset}>
        <InputField name="name" label="Name" />
        <InputField name="company" label="Company" />
        <InputField label="Street" name="street" />
        <InputField label="City" name="city" />
        <InputField label="Postcode" name="postcode" />
        <InputField label="Reference" name="reference" />

        <ButtonRow>
            <Button type="submit" variant="primary">
                <Icon name="check" />
                <Ts>Save</Ts>
            </Button>
        </ButtonRow>
    </Form>}
    </Formik>
}

export default AddressEditor