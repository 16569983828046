import * as yup from 'yup'
import React, { useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ButtonRow, Ts, Icon, SubmitButton } from '../../ui'
import { Textarea } from '../../form'


export function TextInputModal({ title, message, show, onClose, onSubmit }) {
    const formRef = useRef(null)
    const inputRef = useRef(null)
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return <Formik
        initialValues={{
            text: '',
        }}
        enableReinitialize
        innerRef={formRef}
        onSubmit={form => onSubmit(form.text)}
        validationSchema={yup.object().shape({
            text: yup.string().required(),
        })}
    >
    <Form>
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Ts>{title}</Ts>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
                <Container fluid>
                    <Row>
                        <Col>
                            <Textarea name="text" label="Anledning" rows={5} innerRef={inputRef} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <ButtonRow>
                    <SubmitButton onClick={() => formRef.current.submitForm()} />
                    <Button variant="secondary" onClick={onClose}>
                        <Icon name="times" />
                        <Ts>Cancel</Ts>
                    </Button>
                </ButtonRow>
            </Modal.Footer>
        </Modal>
    </Form>
    </Formik>
}

export default TextInputModal