import React from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { ButtonRow, Icon, Ts } from '../ui'
import { InputField } from '../form'
import * as yup from 'yup'

const FormWrapper = styled.form`
    width: 100%;
    max-width: 300px;
    padding: 1rem;
`

const schema = yup.object().shape({
    email: yup.string().required('You must enter an email address'),
    password: yup.string().required('You must enter a password'),
})

const DefaultValues = {
    email: '',
    password: '',
}


export function LoginForm({ error, onSubmit }) {
    return <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={DefaultValues}
        validationSchema={schema}
    >
    {({ handleSubmit }) => <Form as={FormWrapper} onSubmit={handleSubmit}>
        <InputField name="email" label="Email" />
        <InputField name="password" label="Password" type="password" />
        <LoginError error={error} />
        <ButtonRow>
            <Button type="submit" variant="primary">
                <Icon name="arrow-right" />
                <Ts>Login</Ts>
            </Button>
        </ButtonRow>
    </Form>}
    </Formik>
}

function LoginError({ error }) {
    if (!error) {
        return null
    }
    return <Form.Control.Feedback type="invalid" style={{ display: 'block', paddingBottom: '1em' }}>
        {error}
    </Form.Control.Feedback>
}

export default LoginForm