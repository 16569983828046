import React from 'react'
import { Layout, View, Breadcrumbs, Breadcrumb, Spinner, Spacer } from '../ui'
import { Customer, useAction, useSelector, useFetching } from '../../store'
import { useLocalStorage } from '../../util/localstorage'
import CustomersList from './CustomersList'
import CustomerPaging from './CustomerPaging'
import CustomerSearchField from './CustomerSearchField'

const DefaultFilter = {
    query: '',
    country: '',
}


export function CustomerSearchView() {
    const [page, setPage] = useLocalStorage('customers_page', 1)
    const [filter, setFilter] = useLocalStorage('customers_filter', DefaultFilter)

    const hasCustomers = useAction(Customer.search({ ...filter, page }), [filter, page])
    const customers = useSelector(Customer.select.ordered())
    const loading = useFetching(Customer, hasCustomers)
    const loadFromEmpty = loading && !customers.length

    const changeFilter = filter => {
        setPage(1)
        setFilter(filter)
    }

    return <Layout title="Customers">
        <Breadcrumbs>
            <Breadcrumb url='/customers'>Customers</Breadcrumb>
        </Breadcrumbs>
        <View name="customers">
            <CustomerSearchField filter={filter} setFilter={changeFilter} />
            <CustomerPaging setPage={setPage} loading={loading} />
            {loadFromEmpty && <Spinner />}
            {!loadFromEmpty && <CustomersList customers={customers} />}
            {customers.length > 10 && <CustomerPaging setPage={setPage} />}
            <Spacer />
        </View>
    </Layout>
}

export default CustomerSearchView
