import { useState } from "react";
import { useHistory } from "react-router";
import { Sale, useDispatch } from "../../store";
import { BreadcrumbButton } from "../ui";

function CreateSaleButton({ customer }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const createSaleCase = () => {
        setSubmitting(true);
        dispatch(
            Sale.create(
                {
                    source: "internal",
                    customer_id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                    email: customer.email,
                    pno: customer.pno,
                    property_id: customer.property,
                    company: customer.company,
                    street: customer.address.street,
                    postcode: customer.address.postcode,
                    city: customer.address.city,
                    current_heatpump: `${customer.pump_brand} ${customer.pump_model}`,
                },
                (sale) => {
                    history.push(`/sales/${sale.id}/edit`);
                },
            ),
        );
    };
    return (
        <BreadcrumbButton
            title="Create Sale"
            icon="plus"
            loading={submitting}
            onClick={createSaleCase}
        />
    );
}

export default CreateSaleButton;
