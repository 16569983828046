import { CreateModel } from '../../model'
import { apiGet, apiPost } from '../../api'
import * as yup from 'yup'


export const Customer = CreateModel({ 
    id: 'customer', 
    url: 'customer',
    schema: yup.object().shape({
        name: yup.string(),
        company: yup.string(),
        email: yup.string(),
        phone: yup.string(),
        fortnox: yup.string(),

    })
}, actions => {
    return {
        search: apiPost({
            url: ({ page }) => {
                return `customer/search/?page=${page || 1}`
            },
            before: actions.fetch,
            after:  actions.storeMany,
            error:  actions.abort,
            cached: false,
        }),

        filter: apiPost({
            url:    ({ page }) => `customer/filter/?page=${page || 1}`,
            before: actions.fetch,
            after:  actions.storeMany,
            error:  actions.abort,
            cached: false,
        }),
    }
})

export default Customer