import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { useField } from 'formik'
import { ts } from '../ui'

const Pre = styled.pre`
    display: inline;
    font-family: 'Lato', sans-serif;
`


export function Span({ placeholder, prefix, suffix, options, ...props }) {
    const [field] = useField(props)
    let value = field.value
    if (options && _.has(options, value)) {
        value = options[value]
    }
    return <React.Fragment>
        {prefix && <span>{ts(prefix)} </span>}
        <Pre>{ts(value)}</Pre>
        {suffix && <span> {ts(suffix)}</span>}
    </React.Fragment>
}

export default Span
