import styled from 'styled-components/macro'

export default styled.div`
    display: block;

    @media screen and (min-width: 32em) {
        a, button {
            display: inline-block;
            margin-left: 0.5rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: 32em) {
        a, button {
            margin-bottom: 0.75rem;
            width: 100%;
            display: block;
            text-align: left;
        }
    }
`
