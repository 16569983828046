const theme = {
	sizes: {
		default: 24,
		small: 16,
		medium: 20,
		large: 36
	}
}

export default theme
