import React from 'react'
import FeedItem from './FeedItem'
import { StateNames, StateIcons } from '../../../const'
import { Icon, ts } from '../../ui'

export function StateChange(post) {
    return <FeedItem {...post} icon="arrow-right">
        {ts('Changed state from')} <Icon name={StateIcons[post.from]} /> <b>{ts(StateNames[post.from])}</b> {ts('to')} <Icon name={StateIcons[post.to]} /> <b>{ts(StateNames[post.to])}</b>.
    </FeedItem>
}

export default StateChange
