
import { CreateModel } from '../../model'
import { apiGet } from '../../api'
import * as yup from 'yup'

export const User = CreateModel({
    id: 'user',
    url: 'user',
    schema: yup.object().shape({
        name: yup.string()
            .min(3, 'Too short')
            .required('Required'),
        email: yup.string()
            .email('Must be a valid email')
            .required('Required'),
    }),
}, actions => ({
    getByGroup: apiGet({
        url:    id => `group/${id}/user/`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    }),

    getWithPermission: apiGet({
        url:    permission => `user/permission/${permission}`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
    })
}))

export default User