import React from 'react'
import styled from 'styled-components/macro'
import { Link as ReactLink } from 'react-router-dom'

const BlockLink = styled(({ options, ...other }) => <ReactLink {...other} />)`
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;
    z-index: 9999;

    cursor: pointer;
`

export default BlockLink
