import _ from 'lodash'
import { getWeekdayName } from '../../util/format'

import {
    STAT_UPDATE,
    ORDER_NOTIFICATION,

    RECEIVE_WEEK_STATS,
    GET_WEEKLY_STATS,
    RECEIVE_WEEKLY_STATS,
    GET_MONTHLY_STATS,
    RECEIVE_MONTHLY_STATS,
} from '../actions/stats'

import {
    SOCKET_CONNECTING,
    SOCKET_CONNECTED,
} from '../socket'

const initialStatDay = {
    sales: 0,
    pumps: 0,
}
const initialState = {
    connected: false,
    lastActive: false,

    week: {
        number: 0,
        year: 0,
        loading: false,

        monday:    initialStatDay,
        tuesday:   initialStatDay,
        wednesday: initialStatDay,
        thursday:  initialStatDay,
        friday:    initialStatDay,
        saturday:  initialStatDay,
        sunday:    initialStatDay,
        total:     initialStatDay,
    },

    monthly: {
        loading: false,
        stats: [ ],
    },

    weekly: {
        loading: false,
        stats: [ ],
    },
}


export function stats(state, action) {
    if (typeof(state) === 'undefined') {
        return initialState
    }

    switch(action.type) {
    case SOCKET_CONNECTING:
        return {
            ...state,
            connected: false,
        }

    case SOCKET_CONNECTED:
        return {
            ...state,
            connected: true,
            lastActive: new Date(),
        }

    case ORDER_NOTIFICATION: {
        let week = state.week
        let weekly = _.get(state, ['weekly', 'stats'], [])
        let amount = action.data.amount

        // update weekly stats
        if (action.data.week === week.number) {
            // add to current day
            // todo: year check
            let date = new Date(action.data.time)
            let wd = getWeekdayName(date.getDay())

            week = {
                ...week,
                [wd]: {
                    ...week[wd],
                    sales: week[wd].sales + amount,
                },
                total: {
                    ...week.total,
                    sales: week.total.sales + amount,
                },
            }
        }

        // update yearly stats
        let weekIdx = _.findIndex(weekIdx, stat => stat.week === action.data.week)
        if (weekIdx < 0) {
            weekly = [ ...weekly, {
                week: action.data.week,
                sales: amount,
                pumps: 0,
            } ]
        } else {
            let updateWeek = weekly[weekIdx]
            weekly = [
                ...weekly.slice(0, weekIdx),
                {
                    ...updateWeek,
                    sales: updateWeek.sales + amount,
                },
                ...weekly.slice(weekIdx+1),
            ]
        }

        return {
            ...state,
            lastActive: new Date(),
            weekly: { 
                ...state.weekly,
                stats: weekly,
            },
            week,
        }
    }

    case STAT_UPDATE: {
        // get currently displayed week stats
        let week = state.week
        
        // only update if week number matches the currently displayed week
        if (action.week !== week.number) {
            return state
        }

        const date = new Date(action.time)
        const wd = getWeekdayName(date.getDay()) // weekday id

        // update daily stats
        week = {
            ...week,
            [wd]: {
                ...week[wd],
                sales: action.sales,
                pumps: action.pumps,
            },
            total: {
                sales: week.total.sales - _.get(week, [wd, 'sales'], 0) + action.sales,
                pumps: week.total.pumps - _.get(week, [wd, 'pumps'], 0) + action.pumps,
            },
        }

        // merge new state
        return {
            ...state,
            lastActive: new Date(),
            week,
        }
    }

    case RECEIVE_WEEK_STATS:
        return {
            ...state,
            week: {
                loading: false,
                ...action.stats,
            },
        }

    // year by week stats
    case GET_WEEKLY_STATS:
        return {
            ...state,
            weekly: {
                loading: true,
                ...state.weekly,
            },
        }

    case RECEIVE_WEEKLY_STATS:
        return {
            ...state,
            weekly: {
                loading: false,
                stats: action.stats,
            },
        }

    // year by month stats
    case GET_MONTHLY_STATS:
        return {
            ...state,
            monthly: {
                loading: true,
                ...state.monthly,
            },
        }

    case RECEIVE_MONTHLY_STATS:
        return {
            ...state,
            monthly: {
                loading: false,
                stats: action.stats,
            },
        }

    default:
        return state
    }
}
