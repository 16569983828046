import React, { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { Ts, Icon } from '..'
import { DrawerContext } from './Drawers'


const DrawerHeader = styled.div`
    padding: 0.75rem 0.5rem;
    background-color: #2E1760;
    color: white;
    margin-top: 1rem;

    cursor: pointer;
    user-select: none;

    span {
        margin-left: 0.25rem;
    }

    border-radius: 3px 3px 0 0;
    ${props => !props.open ? 'border-radius: 3px;' : ''}
`

const DrawerPanel = styled.div`
    border-radius: 0 0 3px 3px;
    border: 1px solid #eee;
    padding: 0.5rem;

    @media screen and (max-width: 32em) {
        font-size: 0.8em;
    }
`

export function Drawer({ title, children, name }) {
    const ctx = useContext(DrawerContext)
    const [thisOpen, setThisOpen] = useState(true)

    let toggle = () => setThisOpen(!thisOpen)
    let isOpen = thisOpen
    if (name) {
        isOpen = ctx.opened === name
        toggle = () => {
            if (isOpen) {
                ctx.close(name)
            } else {
                ctx.open(name)
            }
        }
    }

    const icon = isOpen ? 'chevron-down' : 'chevron-right'
    const display = isOpen ? 'block' : 'none'

    return <React.Fragment>
        <DrawerHeader onClick={toggle} open={isOpen}>
            <Icon name={icon} />
            <Ts>{title}</Ts>
        </DrawerHeader>
        <DrawerPanel style={{ display }}>
            {children}
        </DrawerPanel>
    </React.Fragment>
}

export default Drawer
