import _ from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { BreadcrumbDropdown, BreadcrumbDropdownButton } from "../../ui";

export function NewPostBreadcrumb({ sale, editable }) {
    const history = useHistory();
    const id = sale.id || 0;

    const PostLinkButton = ({ type, title, icon }) => {
        const onClick = () => history.push(`/sales/${id}/${type}`);
        return (
            <BreadcrumbDropdownButton
                title={title}
                icon={icon}
                onClick={onClick}
            />
        );
    };

    return (
        <BreadcrumbDropdown title="Add" icon="plus" variant="success">
            <PostLinkButton type="comment" title="Comment" icon="comment" />
            {editable && (
                <>
                    <PostLinkButton type="image" title="Image" icon="camera" />
                    <PostLinkButton type="file" title="File" icon="file" />
                    <PostLinkButton
                        type="activity"
                        title="Activity"
                        icon="car"
                    />
                </>
            )}
        </BreadcrumbDropdown>
    );
}

export default NewPostBreadcrumb;
