import _ from 'lodash'
import React from 'react'
import styled from 'styled-components/macro'
import { Map } from '../ui'

const CustomerMapZoomLevel = 7


const CustomerMapWrap = styled.div`
    position: relative;
    height: 100%;
`

export function CustomerMap({ customer, ...props }) {
    const coord = {
        lat: _.get(customer, ['address', 'lat'], 0),
        lng: _.get(customer, ['address', 'lng'], 0),
    }
    const marker = {
        position: coord,
    }
    return <CustomerMapWrap>
        <Map 
            zoom={CustomerMapZoomLevel} 
            {...props}
            center={coord} 
            markers={[ marker ]}
        />
    </CustomerMapWrap>
}

export default CustomerMap