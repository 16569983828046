import { Permission } from './permissions'

const menu = [
	{
		title: 'Customer Map',
		icon: 'map-o',
		href: '/map',
        access: Permission.Customer.View,
	},
	{
		title: 'Customers',
		icon: 'address-book',
		href: '/customers',
        access: Permission.Customer.View,
	},
	{
		title: 'Dashboard',
		icon: 'line-chart',
		href: '/stats',
        access: Permission.Stats.View,
	},
	{
		title: 'Sales',
		icon: 'address-card',
		href: '/sales',
		access: Permission.Sale.View,
    },
	{
		title: 'Users',
		icon: 'user',
		href: '/users',
		access: Permission.User.View,
	},
	{
		title: 'Groups',
		icon: 'users',
		href: '/groups',
		access: Permission.Group.View,
	},
	{
		title: 'Logout',
		icon: 'sign-out',
		href: '/logout',
	}
]

export default menu
