import _ from 'lodash'
import React, { useState } from 'react'
import { Header, Spinner, Ts, ts } from '../ui'
import { Form, Button } from 'react-bootstrap'
import AddressForm from './AddressForm'

import { Address, useAction, useSelector, useFetching, useDispatch } from '../../store'

function AddressOption({ id, type, name, street, city }, i) {
    return <option key={i} value={id}>
        [{type}] {name}, {street}, {city}
    </option>
}

function AddressSelector({ customer_id, selected = 0 }) {
    useAction(Address.getByCustomer(customer_id), [customer_id])
    const dispatch = useDispatch()
    const loading = useFetching(Address)
    const addresses = useSelector(Address.select.ordered())
    const [index, setIndex] = useState(selected)

    if (loading) {
        return <Spinner />
    }
    if (!addresses || addresses.length === 0) {
        return null
    }

    const onChangeAddress = e => {
        const id = e.target.value
        console.log('use address', id)
        setIndex(id)
    }

    const saveAddress = address => {
        dispatch(Address.update(Address.sanitize(address)))
    }

    const editAddress = _.find(addresses, address => address.id == index)
    return <div style={{ maxWidth: '600px' }}>
        <Header><Ts>Addresses</Ts></Header>

        <Form.Group>
            <Form.Label><Ts>Select address</Ts>:</Form.Label>
            <Form.Control as="select" onChange={onChangeAddress} value={index}>
                <option value={0} key={0}>{ts('Select address')}...</option>
                {_.map(addresses, AddressOption)}
            </Form.Control>
        </Form.Group>

        {editAddress && <React.Fragment>
            <AddressForm address={editAddress} onSubmit={saveAddress} />
        </React.Fragment>}
    </div>
}

export default AddressSelector