import React from 'react'
import { GlobalStyle } from '../layout/styled/Layout'


export class RootErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }
  
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // todo: logging
    }
  
    render() {
        const { children } = this.props
        const { error, errorInfo } = this.state

        if (!errorInfo) {
            return children
        }

        return <div style={{padding: '2em'}}>
            <GlobalStyle/>
            <h2>Error</h2>
            {error && error.toString()}
            <pre>
                {errorInfo.componentStack}
            </pre>
        </div>
    }
}

export default RootErrorBoundary
