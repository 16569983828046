import React from 'react'
import styled from 'styled-components/macro'
import { Link } from '../../../ui'

export const FeedAuthor = styled(props => <Link {...props} />)`
    @media screen and (max-width: 32em) {
        font-size: 0.8em;
    }
`

export default FeedAuthor
