import styled from 'styled-components/macro'

const Menu = styled.ul`
	margin: 0 -12px;

    @media screen and (max-width: 32em) {
    }
`

export default Menu
