import React from 'react'
import { formatFullDate } from '../../util/format'
import { Table, TBody, Tr, Th, Td, ts, Link } from '../ui'
import CustomerAddress from '../customer/CustomerAddress'


export function OrderDetails({ order }) {
    const { id, time, customer, name, reference, source, currency, shipping, billing } = order
    return <Table>
        <TBody>
            <Tr>
                <Th>{ts('Customer')}</Th>
                <Td><Link to={`/customer/${customer}`}>{name}</Link></Td>
            </Tr>
            <Tr>
                <Th>{ts('Reference')}</Th>
                <Td>{reference}</Td>
            </Tr>
            <Tr>
                <Th>{ts('Source')}</Th>
                <Td>{source}</Td>
            </Tr>
            <Tr>
                <Th>{ts('Currency')}</Th>
                <Td>{currency}</Td>
            </Tr>
            <Tr>
                <Th>{ts('Time')}</Th>
                <Td>{formatFullDate(time)}</Td>
            </Tr>
            <Tr>
                <Th>{ts('Billing Address')}</Th>
                <Td>
                    <CustomerAddress address={billing || {}} />
                </Td>
            </Tr>
            <Tr>
                <Th>{ts('Shipping Address')}</Th>
                <Td>
                    <CustomerAddress address={shipping || {}} />
                </Td>
            </Tr>
        </TBody>
    </Table>
}

export default OrderDetails