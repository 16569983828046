import _ from 'lodash'
import React from 'react'
import CustomersListItem from '../customer_search/CustomersListItem'
import { TBody, Tr } from '../ui'

/** A string of all valid bucket letters */
const Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ'
const Buckets = `#${Alphabet}`

/**
 * Returns the first letter of a customer name (or # if its not a valid letter)
 * @param {object} customer Customer object
 * @returns {string} First character
 */
function GetCustomerFirstLetter({ name }) {
    name = name || '#'
    const firstLetter = name.charAt(0).toUpperCase()
    if (Alphabet.indexOf(firstLetter) < 0) {
        return '#'
    }
    return firstLetter
}

/**
 * Sorts a list of customers into an object mapping the a character to 
 * a list of customers with a name beginning with that character.
 * @param {list} customers 
 * @returns {map} Letter -> List<Customer>
 */
function SortCustomersToLetterMap(customers) {
    return _.reduce(customers, (result, customer) => {
        const letter = GetCustomerFirstLetter(customer)
        if(!_.has(result, letter)) {
            result[letter] = [ ]
        }
        result[letter].push(customer)
        return result
    }, { })
}

export const CustomersList = ({ customers }) => {
    // sort customers into buckets by first letter
    const customersByLetter = SortCustomersToLetterMap(customers)

    // figure out which buckets exist on this page
    const buckets = _.filter(Buckets, bucket => _.has(customersByLetter, bucket))

    return <TBody>
        {_.map(buckets, bucket => <React.Fragment key={bucket}>
            {_.map(customersByLetter[bucket], customer => <CustomersListItem key={customer.id} customer={customer} />)}
        </React.Fragment>)}
    </TBody>
}

export default CustomersList