import React from 'react'
import { Link, Icon, ts } from '..'

import StyledBreadcrumb from './styled/Breadcrumb'

function Breadcrumb({ url, children, right, ...otherProps }) {
    // link
    if (url) {
        return <StyledBreadcrumb right={right} {...otherProps}>
            {!right && <Icon name="chevron-right" />}
            <Link to={url} className="bread-link">
                {ts(children)}
            </Link>
        </StyledBreadcrumb>
    }

    // text only
    return <StyledBreadcrumb right={right} {...otherProps}>
        {!right && <Icon name="chevron-right" />}
        {ts(children)}
    </StyledBreadcrumb>
}

export default Breadcrumb
