import React from "react";
import { Badge } from "react-bootstrap";
import { GetOfferTypeById } from "../../../const";

export const TypeBadge = ({ code, style = {} }) => {
    if (!code) {
        return null;
    }
    const type = GetOfferTypeById(code);
    return (
        <Badge variant="dark" style={{ backgroundColor: type.color, ...style }}>
            {type.title}
        </Badge>
    );
};

export default TypeBadge;
