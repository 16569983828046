import styled from 'styled-components/macro'


export const FilterBarForm = styled.form`
    @media screen and (max-width: 32em) {
        select.form-control,
        .react-datepicker__input-container input {
            font-size: 0.75em;
        }
    }
`

export const QueryDisplayBar = styled.div`
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
`

export const QueryMessage = styled.span`
    margin-left: 0.5rem;
    i { font-style: italic; }
`
