import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import ChartWrapper from './styled/ChartWrapper'
import ChartTitle from './styled/ChartTitle'
import { Spinner } from '../ui'
import { getMonthlyStats } from '../../store/actions/stats'

class MonthlyChart extends React.Component {
    static defaultProps = {
        title:       '',
        color:       'black',
        statDisplay: 'sales',
        scaleFactor: 1,
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(getMonthlyStats())
    }

    render() {
        const { loading, stats, title, statDisplay, color, scaleFactor, small } = this.props

        if (loading) {
            return <Spinner />
        }

        let options = {
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: { fontSize: small ? 10 : 20 },
                    gridLines: { color: "rgba(0, 0, 0, 0.0)" },
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                        fontSize: small ? 10 : 30,
                    },
                    gridLines: { color: "rgba(0, 0, 0, 0.085)" },
                }],
            },
            legend: { display: false },
        }

        let sales = []
        let labels = []
        let projection = []
        let dataBegun = false
        _.each(stats, stat => {
            const amount = stat[statDisplay]

            // dont displaying any leading empty columns
            if (!dataBegun) {
                if (amount > 0) {
                    dataBegun = true
                } else {
                    return
                }
            }

            labels.push(stat.month)
            sales.push(amount * scaleFactor) 
            projection.push(0);
        })

        const start = moment().startOf('month')
        const end = moment().endOf('month')
        const now = moment()

        const daysLeft = now.diff(end, 'days')
        const daysPass = start.diff(now, 'days')
        const soFarSales = sales[sales.length - 1]
        const projectSales = soFarSales / daysPass * daysLeft
        projection[projection.length - 1] = projectSales

        let data = {
            labels: labels,
            datasets: [
                {
                    stack: 1,
                    label: 'Försäljning',
                    data: sales,
                    borderColor: color,
                    backgroundColor: color,
                    borderWidth: small ? 2 : 4,
                },
                {
                    stack: 1,
                    label: 'Projektion',
                    data: projection,
                    borderColor: '#ddd',
                    backgroundColor: '#ddd',
                    borderWidth: small ? 2 : 4,
                },
            ],
        }

        const height = small ? 45 : 55
        return <ChartWrapper small={small}>
            <Bar data={data} options={options} width={100} height={height} />
            <ChartTitle small={small}>{title}</ChartTitle>
        </ChartWrapper>
    }
}

const mapStateToProps = state => ({ 
    loading: _.get(state, ['stats', 'monthly', 'loading'], true),
    stats:   _.get(state, ['stats', 'monthly', 'stats'], [ ]),
})
export default connect(mapStateToProps)(MonthlyChart)