import styled from 'styled-components/macro'


export const FeedTime = styled.div`
    flex-grow: 1;
    padding: 0 0.5em;
    color: #777;

    font-size: 0.8em;
`

export default FeedTime