import _ from 'lodash'
import React from 'react'
import { sortByCreatedTime } from './Feed'
import ImagePost from '../image/ImagePost'
import EmptyFeed from './EmptyFeed'
import { Anchor, Icon } from '../../ui'
import { ImageLinkBox } from './styled'


export function ImageFeed({ sale, editable, images }) {
    const history = [
        ...images,
    ].sort(sortByCreatedTime)

    // empty feed message
    if (!history.length) {
        return <EmptyFeed />
    }

    return <React.Fragment>
        <ImageLinkBox>
            <Anchor href={`/sales/${sale.id}/gallery`} target="_blank">
                <Icon name="link" />
                <span>Publik Bildlänk</span>
            </Anchor>
        </ImageLinkBox>

        {_.map(history, post => <ImagePost key={`${post.type}_${post.id}`} {...post} editable={editable} />)}
    </React.Fragment>
}

export default ImageFeed