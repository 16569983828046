import styled from 'styled-components/macro'

const MenuOk = styled.div`
	display: none;
	padding: 0 12px;
    user-select: none;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
    line-height: 2rem;
    order: 3;

	text-align: center;
	width: 52px;

	i {
        margin-right: 0.4em;
        display: ${p => p.visible ? 'inline-block' : 'none'};
	}

    @media screen and (max-width: 32em) {
        display: inline-block;
    }
`

export default MenuOk