import React from 'react'
import StyledMenuOk from './styled/MenuOk'
import { Icon } from '..'

export function MenuOk({ onClick, icon, visible }) {
    return <StyledMenuOk onClick={onClick} visible={visible}>
        <Icon name={icon} />
    </StyledMenuOk>
}

export default MenuOk