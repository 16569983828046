import _ from 'lodash'

// store opcodes
export const STORE_FETCH  = 'FETCH'
export const STORE_ABORT  = 'ABORT'
export const STORE_SINGLE = 'SINGLE'
export const STORE_MANY   = 'MANY'
export const STORE_DELETE = 'DELETE'
export const STORE_CLEAR  = 'CLEAR'
export const STORE_APPEND = 'APPEND'
export const STORE_UPDATE = 'UPDATE'


//
// action generator functions
// 

export function fetchStoreAction(type) {
    return params => ({
        operation: STORE_FETCH,
        type,
        item: params,
    })
}

export function abortStoreAction(type) {
    return () => ({
        operation: STORE_ABORT,
        type,
    })
}

export function singleStoreAction(type) {
    return item => ({
        operation: STORE_SINGLE,
        type,
        item,
    })
}

export function manyStoreAction(type) {
    return reply => {
        if (typeof(reply.pages) !== 'undefined') {
            // parse paged response
            return {
                type,
                operation: STORE_MANY,
                page:  reply.page,
                pages: reply.pages,
                total: reply.total,
                items: reply.list,
            }
        } else {
            // parse simple list response
            return {
                type,
                operation: STORE_MANY,
                page:  1,
                pages: 1,
                items: reply,
                total: reply.length,
            }
        }
    }
}

export function deleteStoreAction(type) {
    return id => ({
        operation: STORE_DELETE,
        type,
        id,
    })
}

export function clearStoreAction(type) {
    return () => ({
        operation: STORE_CLEAR,
        type,
    })
}

// append an item to the store
export function appendStoreAction(type) {
    return item => ({
        operation: STORE_APPEND,
        type,
        item,
    })
}

// update an existing item in the store
export function updateStoreAction(type) {
    return item => ({
        operation: STORE_UPDATE,
        type,
        item,
    })
}