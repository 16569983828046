import React from 'react'
import { Icon, Ts } from '..'
import { 
    FooterButtonLink,
    FooterButtonTitle,
} from './styled'

export function FooterButton({ icon, title, to, exact }) {
    let active = false
    if (exact) {
        active = window.location.pathname === to
    } else {
        active = window.location.pathname.indexOf(to) >= 0
    }

    return <FooterButtonLink isActive={active} to={to}>
        <Icon name={icon} />
        <FooterButtonTitle>
            <Ts>{title}</Ts>
        </FooterButtonTitle>
    </FooterButtonLink>
}

export default FooterButton