import React from 'react'
import { Link, Tr, Td } from '../ui'
import { GetCountryByCode } from '../../const/countries'


export function CustomersListItem({ customer: { id, name, email, phone, company, address } }) {
	const href = '/customer/' + id
	const country = GetCountryByCode(address.country).name

    return <Tr key={id}>
        <Td>
            <Link to={href}>{name}</Link>
        </Td>
        <Td>{company}</Td>
        <Td>
            <Link to={href}>{email}</Link>
        </Td>
        <Td>{phone}</Td>
        <Td>{address.street}</Td> 
        <Td>{address.city}</Td>
        <Td>{country}</Td>
    </Tr>
}

export default CustomersListItem