import _ from 'lodash'
import React from 'react'
import { Table, THead, Tr, Td, ts } from '../ui'
import { Countries } from '../../const'

import { Formik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { InputField, SelectField } from '../form'


export function CustomerFilter({ filter, setFilter, children }) {
    return <Formik
        enableReinitialize
        initialValues={filter}
        onSubmit={setFilter}
    >
    {({ handleSubmit }) => <Form onSubmit={handleSubmit}>
        <Table>
        <THead>
            <Tr>
                <Td>
                    <InputField name="name" label="Name" skipValidate />
                </Td>
                <Td>
                    <InputField name="email" label="Email" skipValidate />
                </Td>
                <Td>
                    <InputField name="phone" label="Phone" skipValidate />
                </Td>
                <Td>
                    <InputField name="company" label="Company" skipValidate />
                </Td>
                <Td>
                    <InputField name="address" label="Address" skipValidate />
                </Td>
                <Td>
                    <InputField name="city" label="City" skipValidate />
                </Td>
                <Td>
                    <SelectField name="country" label="Country" options={Countries} optionKey="code" optionValue="name" skipValidate>
                        <option value="">{ts('All')}</option>
                    </SelectField>
                </Td>
            </Tr>
        </THead>
        {children}
        </Table>
        <Button type="submit" style={{ display: 'none' }} />
    </Form>}
    </Formik>
}

export default CustomerFilter