import { GoodMargin, OkayMargin } from './settings'
 
export const Red = '#951D22'
export const Green = '#2ecc71'
export const Danger = '#c0392b'
export const Blue = '#2E1760'
export const LightBlue = '#1d5095'
export const Yellow = '#FBBD08'

export const Success = Green
export const Primary = Blue

export function MarginColor(percent) {
    if (percent >= GoodMargin)
        return Green
    if (percent >= OkayMargin)
        return Yellow
    return Danger
}
