import styled from 'styled-components/macro'

export const CommentImageContainer = styled.div`
    @media screen and (max-width: 32em) {
        ${props => props.zoom ? `
            z-index: 99999;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 16px;
            background-color: rgba(0,0,0, 0.7);

            display: flex;
            flex-direction: column;
            justify-content: center;
        ` : ``}
    }
`

export default CommentImageContainer