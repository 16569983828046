import { CreateModel } from '../../model'
import { apiGet } from '../../api'


export const MapCustomer = CreateModel({
    id: 'map_customer',
    url: 'customer/map',
}, actions => ({
    filter: apiGet({
        url:    q => `customer/map/${q}`,
        before: actions.fetch,
        after:  actions.storeMany,
        error:  actions.abort,
        cached: true,
        cacheDuration: 300,
    })
}))

export default MapCustomer