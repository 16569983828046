import styled from 'styled-components/macro'

const Breadcrumb = styled.li`
    user-select: none;

	display: flex;
    align-items: center;

    margin-left: 0.25em;
    margin-right: 0.25em;

    > i.fa {
        margin: 0 0.5em;
    }

    > a.bread-link {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
`

export default Breadcrumb
