import _ from 'lodash'
import React from 'react'
import { Table, THead, TBody, Tr, Th, Td, Ts, Link } from '../ui'


export function GroupList({ groups }) {
    return <Table>
        <THead>
            <Tr>
                <Th><Ts>Group</Ts></Th>
            </Tr>
        </THead>
        <TBody>
            {_.map(groups, GroupListItem)}
        </TBody>
    </Table>
}

export function GroupListItem({ id, name }) {
	return <Tr key={id}>
		<Td>
			<Link to={`/groups/${id}`}>
				{name}
			</Link>
		</Td>
	</Tr>
}

export default GroupList