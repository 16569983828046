import React from 'react'
import StyledBreadcrumbs from './styled/Breadcrumbs'

function Breadcrumbs({ children, ...props }) {
    return <StyledBreadcrumbs {...props}>
        {children}
    </StyledBreadcrumbs>
}

export default Breadcrumbs
