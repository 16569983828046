import _ from 'lodash'
import fetch from '../../util/fetch'
import { throwError } from './errors'
import { getWeekdayName } from '../../util/format'
import { getWeekNumber, getISOWeekDayDate, WeekDays } from '../../util/week'

export const GET_WEEK_STATS = 'GET_WEEK_STATS'
export const RECEIVE_WEEK_STATS = 'RECEIVE_WEEK_STATS'
export const GET_WEEKLY_STATS = 'GET_WEEKLY_STATS'
export const RECEIVE_WEEKLY_STATS = 'RECEIVE_WEEKLY_STATS'
export const GET_MONTHLY_STATS = 'GET_MONTHLY_STATS'
export const RECEIVE_MONTHLY_STATS = 'RECEIVE_MONTHLY_STATS'

export const ORDER_NOTIFICATION = 'ORDER_NOTIFICATION'
export const STAT_UPDATE = 'STAT_UPDATE'


export function OrderNotificationAction(data) {
    return {
        type: ORDER_NOTIFICATION,
        data,
    }
}

export function StatUpdateAction(data) {
    const { week } = getWeekNumber(new Date(data.time))
    return {
        week,
        ...data,
        type: STAT_UPDATE,
    }
}

// stats

export function getWeekStats(week, year) {
    return async dispatch => {
        dispatch({
            type: GET_WEEK_STATS,
            year,
            week,
        })
        try {
            let json = await fetch('stats/week/' + (week ? year + '/' + week : ''))
            dispatch(receiveWeekStats(json))
        }
        catch(error) {
            dispatch(throwError(error.message, error.status))
        }
    }
}

function receiveWeekStats(json) {
    let stats = {
        number: json.week,
        year: json.year,
        total: {
            sales: 0,
            pumps: 0,
        },
    }

    // create some empty days
    stats = _.reduce(WeekDays, (stats, day) => {
        const d = getISOWeekDayDate(day, json.week, json.year)
        return _.set(stats, day, {
            year: json.year,
            week: json.week,
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate(),
            sales: 0,
            pumps: 0,
        })
    }, stats)

    _.each(json.days, stat => {
        let wd = getWeekdayName(stat.weekday)
        stats[wd] = stat
        stats.total.sales += stat.sales
        stats.total.pumps += stat.pumps
    })
    return {
        type: RECEIVE_WEEK_STATS,
        stats: stats,
    }
}

export function getWeeklyStats(year) {
    return async dispatch => {
        dispatch({ type: GET_WEEKLY_STATS })
        try {
            let json = await fetch('stats/weekly/')
            dispatch(receiveWeeklyStats(json))
        }
        catch(error) {
            dispatch(throwError(error.message, error.status))
        }
    }
}

function receiveWeeklyStats(json) {
    // return the stats in reverse (ascending) order
    return {
        type: RECEIVE_WEEKLY_STATS,
        stats: _.reverse(json),
    }
}

export function getMonthlyStats(year) {
    return async dispatch => {
        dispatch({ type: GET_MONTHLY_STATS })
        try {
            let json = await fetch('stats/monthly/')
            dispatch(receiveMonthlyStats(json))
        }
        catch(error) {
            dispatch(throwError(error.message, error.status))
        }
    }
}

function receiveMonthlyStats(json) {
    // return the stats in reverse (ascending) order
    return {
        type: RECEIVE_MONTHLY_STATS,
        stats: _.reverse(json),
    }
}

export function updateDayStats(stats) {
    if (!_.isArray(stats)) {
        stats = [ stats ]
    }
    return async dispatch => {
        try {
            await fetch('stats/', {
                method: 'PUT',
                json: stats,
            })
        }
        catch(error) {
            dispatch(throwError(error.message, error.status))
        }
    }
}