import React from 'react'
import styled from 'styled-components/macro'


const AddressField = styled.address`
    font-family: monospace;
    line-height: 1.5em;
    margin-bottom: 0;
`

function CustomerAddress({ address }) {
    const { name, street, city, postcode, country } = address

    return <AddressField>
        {name}<br/>
        {street}<br/>
        {city} {postcode}<br/>
        {country}
    </AddressField>
}

export default CustomerAddress