import styled from 'styled-components/macro'

const Anchor = styled.a`
    cursor: pointer;
	text-decoration: none;
    color : #2C82C9;
    
    &:hover {
        color : #1F5C8D;
        text-decoration: none;
    }
`

export default Anchor
